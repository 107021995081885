import { initialFormState } from '../constants/filters'
import { UPDATE_ADVANCED_FILTER_FORM_STATE } from './types'

const advancedFilterReducer = (state = initialFormState, action) => {
    switch (action.type) {
        case UPDATE_ADVANCED_FILTER_FORM_STATE:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}

export default advancedFilterReducer
