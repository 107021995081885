export const TRANSLATIONS_HE = {
    letsmake: 'מערכת ניהול מדף',
    moreprofit: 'בזמן אמת',
    createaccount: 'צור חשבון חדש ',
    getstart: 'התחברות',
    support: 'תמיכה',
    toservice: 'תנאי שימוש',
    ppolicy: 'מדיניות פרטיות',
    siginbtn: 'התחברות',
    nextgen: 'מערכת מתקדמת',
    insaletec: 'לניהול פלנוגרמה',
    sintoacc: 'הזן את פרטי ההתחברות למערכת',
    formfield: {
        email: 'כתובת אימייל',
        pass: 'סיסמא',
        forgetpw: 'שכחתי סיסמא',
        enterpass: 'הזן את הסיסמה',
    },
    noaccyet: 'עדיין אין לך חשבון?',
    cnewacc: 'צור חשבון חדש בקלות',
    tagname: 'שם תגית',
    tagName: 'שם תגית',
    srchtag: 'חיפוש תגית',
    tagdetails: 'פרטי תגית',
    tags: 'תגיות',
    storetags: 'תגיות חנות',
    newtag: 'צור חדש',
    newtagplace: 'הקלד שם תג חדש והקש אנטר',
    addtags: 'הוסף תגית',
    addedtags: 'תגיות שנוספו',
    srchtagname: 'חיפוש תגיות',
    searchtagplace: 'הקלד שם תג חיפוש והקש אנטר',
    home: 'ראשי',
    btnnames: {
        save: 'שמור',
        back: 'חזור',
        update: 'עדכן',
        delete: 'מחק',
        search: 'חיפוש',
        addnew: 'הוספה',
        upload: 'העלאה',
        activate: 'הפעל',
        confirm: 'אשר',
        close: 'סגור',
        clearall: 'נקה הכל',
        all: 'הכל',
        undo: 'בטל',
        redo: 'שחזר',
        reset: 'איפוס',
        testai: 'Test AI',
        propose: 'המלצות',
        loadmore: 'עוד מחלקות',
        yes: 'כן',
        no: 'לא',
        selectblock: 'בחר בלוק',
        deselectblock: 'ביטול בחירה',
        excelexport: 'אקסל',
        exporttoexcel: 'ייצוא לאקסל',
        exportprodinfo: 'ייצוא דוח פלנוגרמות',
        importprodinfo: 'ייצוא מידע על המוצר',
        publish: 'פרסם',
        unpublish: 'בטל את הפרסום',
        rerequest: 'בקשה חוזרת',
        download: 'הורד',
        share: 'שליחה',
        addnewcat: '!הוסף קטגוריה חדשה',
        deletedepartment: 'מחק את המחלקה',
        addnewsubcat: 'הוסף תת קטגוריה חדשה',
        deletecategory: 'מחק קטגוריה',
        updatecategory: 'עדכן קטגוריה',
        addnewbrand: 'הוסף מותג חדש',
        updatesubcategory: 'עדכן תת קטגוריה',
        deletesubcategory: 'מחק תת קטגוריה',
        add: 'הוספה',
        change: 'שינוי',
        restore: 'שחזר',
        ConfirmedList: 'הפעלה רוחבית',
        send: 'שלח',
        delete_reference_task: 'מחק משימות עתידיות',
        delete_this_task_only: 'מחק את המשימה הזו בלבד',
        update_reference_task: 'עדכן משימות עתידיות',
        update_this_task_only: 'עדכן משימה זו בלבד',
    },
    NO_UUID: 'לא נמצא UUID',
    departmentname: 'שם מחלקה',
    department: 'מחלקה',
    depcolor: 'צבע מחלקה',
    departmentchanges: 'שינויים במחלקה',
    departments: 'מחלקות',
    selectdepartment: 'בחר מחלקה',
    selectsavedepartment: 'בחר ושמור מחלקה',
    srchdep: 'חיפוש מחלקה',
    dunitname: 'שם התצוגה',
    srchdunit: 'חיפוש סוג תצוגה',
    dunits: 'סוגי תצוגות',
    newfield: 'הוסף תצוגה',
    newfieldadd: 'הוספת יחידת תצוגה חדשה',
    editdunitfield: 'עריכת יחידת תצוגה',
    floorname: 'שם קומה',
    floor: 'קומה',
    floors: 'קומות',
    srchfloor: 'חיפוש קומה',
    excelupload: 'טען קובץ אקסל',
    uom: 'מידות',
    uomeasure: 'יחידות מידה',
    length: 'אורך',
    width: 'רוחב',
    height: 'גובה',
    depth: 'עומק',
    s_gap: 'פס מדף',
    s_height: 'גובה מדף',
    breadth: 'עומק',
    color: 'צבע',
    row: 'מדף',
    rowstructure: 'תצוגה מקדימה ',
    rowheights: 'גובה המדף',
    rows: 'מדפים',
    addnewrow: 'הוסף מדף חדש',
    newrow: 'מדף בודד',
    multiplerows: 'מדפים מרובים',
    multipleshelves: 'שכפול מדפים',
    neededshelves: 'כמות מדפים בשדה',
    type: 'סוגי תצוגות',
    shelve: 'מדף',
    details: 'פרטים',
    dndsomefileshere: 'גרור לכאן תמונה, או לחץ כאן להעלאה מהמחשב',
    addimgtorec: 'בחר תמונה שתעזור לזהות את השדה בקלות ',
    img: 'תמונה',
    cropimg: 'חתוך תמונה',
    browsimg: 'הוסף תמונה חדשה ',
    continue: 'המשך',
    planoname: 'הזן שם לגרסה',
    planograms: 'פלנוגרמות',
    planogram: 'פלנוגרמה',
    deleteplanogram: 'מחק גרסא',
    activeplanogram: 'הפעלת גרסא',
    searchplanogram: 'חיפוש פלנוגרמה',
    searchstore: 'חיפוש חנות',
    selectstore: 'בחר בחנות',
    findstore: 'שם הסניף',
    newplanogram: 'פלנוגרמה חדשה',
    existingplanogram: 'הפלנוגרמה הנוכחית ',
    version: 'גרסא',
    PENDING_STATUS: 'ממתין לביצוע',
    DRAFT: 'טיוטא',
    status: 'סטטוס',
    date: 'תאריך',
    activationdate: 'תאריך הפעלה',
    fieldtypes: 'סוגי תצוגות',
    fieldtype: 'סוג תצוגה',
    allfields: 'כל התצוגות',
    editfdetails: 'ערוך פרטי תצוגה',
    filteredfields: 'תצוגות שנמצאו',
    fieldid: 'מספר שדה',
    leftfield: 'שדה שמאלי',
    rightfield: 'שדה ימני',
    fieldno: 'מספרי שדות',
    selctfieldno: 'בחר מספר שדה',
    notes: 'הערות',
    viewfield: 'הצג שדה',
    selectafloor: 'בחר קומה',
    entervdlteplano: 'הזן את מספר הגרסא שברצונך למחוק',
    newproducts: 'חסר נתונים',
    products: 'מוצרים',
    product: 'מוצר',
    productdetails: 'נתוני המוצר',
    productbrand: 'מותג',
    brand: 'מותג',
    productname: 'שם המוצר',
    searchproduct: 'חיפוש מוצר',
    existingproducts: 'רשימת מוצרים וכמות',
    productslist: 'רשימת מוצרים',
    recentlist: 'בשימוש לאחרונה',
    srchproduct: 'חיפוש מוצר',
    deleteproduct: ' מחיקת שורה בודדת',
    deleteallproducts: 'מחיקת כלל המוצרים',
    dlteallprodmessage: '?לחיצה על כן תמחק את כל המוצרים בשדה, האם אתה בטוח',
    improvement: 'שיפור צפוי',
    expand: 'נוספים',
    suretodelete: 'האם ברצונך למחוק את ',
    confirmdlteproblock: 'מחיקת בלוק מוצר שלם',
    suretoremoveprodshelve: '?פעולה זו תסיר את כל בלוק המוצר , האם לאשר',
    changeplanogramstatus: 'שינוי סטטוס פלנוגרמה',
    wanttochangelayoutstate: '-האם ברצונך לשנות את מצב הפריסה ל',
    planostatusupdatesuccess: 'הסטטוס שונה בהצלחה',
    erroroccurred: 'אופס! משהו השתבש',
    aitest: 'AI Test',
    preview: 'ויזואלי',
    usezoominout: 'השתמש בעכבר כדי להגדיל/להתרחק או להזיז',
    tree: 'מבנה',
    convertjson: ' JSON להמיר',
    json: 'JSON',
    invalidjson: 'לא חוקי JSON',
    revenue: 'פדיון',
    revp: 'מהרווח %',
    revface: 'מדד יומי',
    fieldp: 'מהשדה %',
    profit: 'רווח',
    totalrevperface: 'הכנסות לפייסינג',
    pr: 'מכר',
    overview: 'מבט על',
    chainprofit: 'רווח רשתי',
    storeprofit: 'רווח חנות',
    convrate: 'יחס המרה',
    clearfilters: 'נקה מסננים',
    draftfielddetails: 'טיוטת פרטי שדה',
    saved: 'נשמר',
    updated: 'עודכן',
    succussfuly: '!בהצלחה',
    newdraftlayoutcreated: 'טיוטת פריסה חדשה נשמרה בהצלחה',
    backtomngmt: 'חזור לניהול',
    changes: 'שינוים',
    generaldata: 'נתונים מרכזיים',
    netaverage: 'Net.Avarage',
    leadingsales: 'מכירות מובילות',
    convdept: 'Conv.Dept',
    barcode: 'ברקוד',
    source: 'מקור',
    sourcedetails: 'מקור הנתונים',
    scales: 'מידות',
    focuson: 'ריכוז נתונים',
    stores: 'סניפים',
    storeview: 'תצוגת חנות',
    filter: 'סנן',
    totalshelfrevenue: 'פדיון ',
    layout: 'מבנה חנות',
    profitpersqrmeters: 'רווח למטר',
    sale: 'פדיון',
    saleper: 'S%',
    salepercentage: 'אחוז מכר',
    saleface: 'מדד יומי',
    saleface_day: 'מדד יומי',
    profitper: 'P%',
    psqft: 'רווח למטר רבוע',
    accessdenied: 'גישה נדחתה',
    dashboard: 'לוח בקרה',
    newdashboard: 'לוח מחוונים חדש',
    masterdata: 'הגדרות',
    storemanager: 'מנהל חנות',
    inbox: 'הודעות',
    logout: 'התנתק',
    darkmode: 'מצב חשוך',
    profilesettings: 'הגדרות משתמש',
    showing: 'מוצג',
    to: 'עד',
    results: 'תוצאות',
    of: 'מתוך',
    DATA_LOADING_PLEASE_WAIT: '...אנא המתן בזמן שאנחנו טוענים נתונים',
    MORE_MESSAGES: 'עוד הודעות',
    GO_BACK: 'חזור',
    SUCCESSFULLY_DEPARTMENT: 'מחלקה בהצלחה ',
    ERROR_OCCURRED: 'אירעה שגיאה',
    ERROR_OCCURRED_IN: '-אירעה שגיאה ב',
    SUCCESSFULLY_DEPARTMENT_DELETED: 'המחלקה נמחקה בהצלחה',
    ALREADY_ADDED: '.כבר קיים',
    FILL_MAIN_DETAILS_CORRECTLY_TO_CONTINUE_EX_WHU:
        '...מלא את הפרטים העיקריים בכדי להמשיך - גובה, רוחב וכן הלאה',
    DISPLAY_UNIT_NAME_REQUIRED: 'חסר שם תצוגה',
    DISPLAY_UNIT_WIDTH_REQUIRED: 'חסר רוחב  תצוגה',
    DISPLAY_UNIT_HEIGHT_REQUIRED: 'חסר גובה  תצוגה',
    DISPLAY_UNIT_DEPTH_REQUIRED: 'עומק  תצוגה חסר',
    SHELVE_HEIGHT_REQUIRED: 'חסר גובה מדף ',
    SHELVE_GAP_REQUIRED: 'חסר גובה פס מדף',
    DISPLAY_UNIT_SHELVECOUNT_REQUIRED: ' חובה להוסיף לפחות מדף אחד',
    DISPLAY_UNIT_UNIT_OF_MESURE_REQUIRED: 'חובה לבחור יחידת מידה',
    DISPLAY_UNIT_SHELVES: 'מדפי יחידות תצוגה',
    HEIGHT_OF_SHELVES_DOESNT_MATCH_DISPLAY_UNIT_HEIGHT:
        '.גובה המדפים אינו ממלא את גובה התצוגה שהגדרת',
    DISPLAY_UNIT_SUCCESSFULLY: 'בוצע',
    PREVIEW_LOADING: '...טוען תצוגה מקדימה',
    EXCEL_DATA_UPLOADED_SUCCESSFULLY: '!נתוני אקסל הועלו בהצלחה',
    ADD_EXCEL_FILE_TO_CONTINUE: 'הוסף קבצי אקסל כדי להמשיך',
    ADD_FLOOR_TO_CONTINUE: 'הוסף קומה להמשך',
    FLOOR_NAME_REQUIRED: 'נדרש שם קומה',
    FLOOR_WIDTH_REQUIRED: 'נדרש רוחב סניף',
    FLOOR_HEIGHT_REQUIRED: 'נדרש גובה סניף',
    UNIT_OF_MESSURE_REQUIRED: 'יחידת מידה חסרה',
    SUCCESSFULLY_FLOOR_DETAILS: ' פרטי הסניף עודכנו',
    FLOOR_WIDTH: 'רוחב ',
    FLOOR_HEIGHT: 'אורך ',
    SELECT: 'בחר',
    JANUARY: 'ינואר',
    FEBRUARY: 'פברואר',
    MARCH: 'מרץ',
    APRIL: 'אפריל',
    MAY: 'מאי',
    JUNE: 'יוני',
    JULY: 'יולי',
    AUGUST: 'אוגוסט',
    SEPTEMBER: 'ספטמבר',
    OCTOMBER: 'אוקטובר',
    NOVEMBER: 'נובמבר',
    DECEMBER: 'דצמבר',
    JAN: 'ינואר',
    FEB: 'פבר׳',
    MAR: 'מרץ',
    APR: 'אפריל',
    JUN: 'יוני',
    JUL: 'יולי',
    AUG: "אוג׳'",
    SEP: 'ספט׳',
    OCT: 'אוק׳',
    NOV: 'נוב׳',
    DEC: 'דצמ׳',
    CONFIRM_DELETE_PLANOGRAM: 'אשר למחוק את הפלנוגרמה',
    ARE_YOU_SURE_DELETE_THIS_PLANOGRAM:
        '?האם אתה בטוח שברצונך להסיר פלנוגרמה זו',
    VERSION_NO_DIDNT_MATCH: 'מספר הגרסה לא תואם',
    PLANOGRAM_SUCCESSFULLY: ' טופל בהצלחה ',
    CONFIRM_TO_DELETE_FIELD: 'אשר למחוק שדה',
    ARE_YOU_SURE_REMOVE_THIS_FIELD_FROM_FLOOR: '?האם בטוח להסיר שדה זה',
    NO_TAGS_FOUND: '!לא נמצאו תגיות',
    SUCCESSFULLY_QR_DETAILS_EMAILD: 'שלחו בדוא״ל QR פרטי',
    ENTER_VALID_EMAIL: 'הכנס אימייל תקף',
    CHANGE_PLANOGRAM_STATUS: 'הפעלה מאולצת',
    DO_YOU_WANT_TO_CHANGE_LAYOUT_STATE_TO:
        '-האם אתה בטוח שברצונך לשנות את הסטאטוס ל ',
    SHARE: 'שתף',
    SHARE_QR: 'שתף',
    EMAIL: 'אימייל',
    ALL: 'הכל',
    BEST: 'ביצועים גבוהים',
    WORST: 'ביצועים נמוכים',
    BESTNWORST: 'ביצועים ממוצעים',
    WIDTH_HEIGHT_OF_FIELD_CANNOT_LOWER_ZERO:
        'רוחב/גובה השדה לא יכול להיות נמוך מאפס.',
    HEIGHT_GAPS_OF_SHELVES_DOESNT_MATCH_DUINT_HEIGHT:
        'גבהים/פערים של מדפים אינם תואמים את גובה יחידת התצוגה.',
    HEIGHT_GAP_NOT_ADDED_ROWS_FOUND_ADD_H_G_REMOVE_UNWANTED_ROWS:
        'גובה ומרווח לא נוספו שורות, הוסף גובה/פער או הסר שורות לא רצויות',
    CLOSE_FIELD_DETAILS_EDIT_VIEW_FIRST: 'סגור פרטי שדה עריכה ראשית',
    NO_RESULT_FOUND: 'לא נמצאו תוצאות',
    PRODUCT_NOT_SELECTED: '!המוצר לא נבחר',
    PROPERLY_EDIT_AND_CLOSE_FIELD_DETAILS_EDIT_VIEW_TO_CONTINUE:
        'ערוך כראוי וסגור פרטי שדה עריכת המשך כדי להמשיך',
    CLOSE_PRODUCT_VIEW_TO_CONTINUE: 'סגור את תצוגת המוצר להמשך',
    RESET_NEW_CHANGES: 'אפס שינויים חדשים',
    ARE_YOU_SURE_TO_RESET_ALL_NEW_CHANGES:
        '?האם אתה בטוח שברצונך לאפס את כל השינויים החדשים',
    RESET: 'איפוס',
    RESETTED: 'התאפס',
    ARE_YOU_SURE_YOU_WANT_TO_RESET: ' ?האם אתה בטוח שברצונך לאפס',
    DISCONNECT: 'שמירה | ניתוק ',
    ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT: ' ?האם אתה בטוח שברצונך להתנתק',
    YOU_HAVE_UNSAVED_CHANGES_SURE_TO_LEAVE:
        'יש לך שינויים שלא נשמרו, האם אתה בטוח שברצונך לעזוב?',
    WIDTH: 'רוחב: ',
    HEIGHT: 'גובה: ',
    DEPTH: 'עומק: ',
    PRODUCT_ALREADY_ADDED_REMOVE_ADDED_PRODUCTS_TO_CHANGE:
        'המוצר כבר נוסף. הסר מוצרים שנוספו לשינוי',
    UPDATE_PRODUCT_DETAILS: 'שמירת שינויים',
    ARE_YOU_SURE_TO_UPDTAE_PRODUCT_DETAILS: 'האם לשמור את השינויים שבוצעו?',
    PRODUCT_DETAILS_SUCCESSFULLY_UPDATED: 'פרטי המוצר עודכנו בהצלחה',
    THIS_NOT_EFFECT_ELIER_PLANO_ONLY_FOR_THIS_AND_FUTURE_PLANO:
        'שינוי זה לא ישפיע על פלנוגרמות קודמות. הגשת מועמדות לפלנוגרמות אלו ולעתיד בלבד.',
    VIEW_USED_PLANOGRAMS: 'הצג את הפלנוגרמים המשומשים',
    ENTER_VALID_JSON_O_TURN_ON_CONVERT_JSON_OPTION:
        'הזן JSON תקף או הפעל את אפשרות המרת JSON',
    DRAW_ERRORS: 'שרטוט שגיאות',
    SHELVE: 'מדף',
    BARCODE: 'ברקוד: ',
    ADD_A_PRODUCT_NAME: 'הוסף שם מוצר',
    ADD_PRODUCT_W_H_B_DETAILS: 'הוסף פרטי רוחב, גובה ועומק המוצר',
    ADD_PRODUCT_SENSITIVITY: 'הוסף רגישות למוצר',
    ADD_PRODUCT_BARCODE: 'הוסף את ברקוד המוצר',
    SELECT_A_PRODUCT_SOURCE: 'בחר מקור מוצר',
    ADD_IMAGE_OF_PRODUCT: 'הוסף תמונה של המוצר',
    PRODUCT_DETAILS_SUCCESSFULLY: 'פרטי המוצר בהצלחה',
    SUCCESSFULLY_PRODUCT_DETAILS_DELETED: 'פרטי המוצר נמחקו בהצלחה',
    SUCCESSFULLY_PRODUCT_DETAILS_SAVED: 'פרטי המוצר נשמרו בהצלחה',
    EMAIL_CONFIRMATION_DETAILS_SENT: 'פרטי אישור הדוא"ל נשלחו',
    CHECK_YOUR_EMAIL_FOR_CONFIRMATION_MESSAGE:
        'בדוק את הודעת האישור בדוא"ל שלך',
    FORGOT_ACCOUNT_PW: 'שכחתי את סיסמת החשבון',
    ENTER_EMAIL_USED_TO_REGISTER_WITH_PLANIGO:
        'הזן כתובת דוא"ל המשמשת לרישום ב- Planigo.',
    RESET_ACCOUNT: 'אפס חשבון',
    STORE_NAME: 'שם חנות',
    STORE_DETAILS: 'פרטי החנות',
    SUCCESSFULLY_TAG_DETAILS: 'תייג פרטים בהצלחה ',
    SUCCESSFULLY_TAG_DETAILS_DELETED: 'פרטי התיוג נמחקו בהצלחה',
    ERROR_TAG_DETAILS_DELETED: 'לא יכול למחוק! שם התג ריק',
    DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK:
        'גרור ושחרר כמה קבצים לכאן, או לחץ כדי לבחור קבצים',
    IMAGE_IS_LARGER_THAN_5MB: 'התמונה גדולה מ -5 MB',
    CONFIRM_TO_SUBMIT: 'האם אתה בטוח?',
    ARE_YOU_SURE_TO_CONTINUE_THIS_TASK: 'האם אתה בטוח להמשיך במשימה זו?',
    FILTER_BY: 'סנן לפי',
    COMPANY_NAME: 'שם החברה',
    CONNOT_FIND_REQUESTED_CALL: 'לא הצלחתי למצוא את השיחה המבוקשת',
    REQUIRED_TO_FILL: 'חובה למלא',
    REQUIRED_AN_EMAIL: 'דרוש מייל',
    REQUIRED_NUMBERS_ONLY: 'הזן מספרים בלבד',
    CHOOSE: 'בחר',
    DIRECT: 'Aristo',
    ADDED: 'נוסף',
    REMOVED: 'הוסר',
    RESET_EDIT_FIELD_DETAILS: 'אפס את פרטי שדה עריכה',
    DO_YOU_WANTS_TO_RESET_ADDED_CHANGES: 'האם ברצונך לאפס שינויים נוספים?',
    NOT_AVAILABLE_ANY_PROPOSAL_ITEMS: 'לא זמין פריטי הצעה',
    COPIED_TO_CLIP_BOARD: 'הועתק ללוח',
    PLEASE_WAIT: 'עובדים על זה...',
    SHELVEM: 'Shelve:',
    COL: ', COL:',
    NOT_FOUND: 'לא נמצא.',
    BARCODEM: ', ברקוד:',
    BLOCKM: ' בלוק,:',
    LOCATIONM: ', מיקום,:',
    CANNOT_ADD_OVERFLOW_Y: ' לא יכול להוסיף. (הצפה y)',
    CANNOT_ADD_OVERFLOW_X: ' לא יכול להוסיף. (הצפה x)',
    CANNOT_ADD_PVERFLOW_PRODUCT: 'לא יכול להוסיף. (מוצר הצפה)',
    IMAGE_LOADING: 'טוען תמונות ...',
    IMAGE_CANNOT_LOAD: 'התמונה לא יכולה לטעון',
    shelveD: ' מדף  ',
    LOADING: 'מנתח נתונים',
    language: 'שפה',
    notifications: 'התראות',
    refreshnotifications: 'רענן את ההודעות',
    morenotifications: 'הודעות נוספות',
    branch: 'סניף',
    editbranch: 'ערוך סניף',
    addbranch: 'הוסף סניף חדש',
    proposals: 'המלצות',
    branchcode: 'קוד סניף',
    branchname: 'שם הסניף',
    branchaddress: 'כתובת הסניף',
    suggestprodviewlevel: 'הוסף רמת תצוגת מוצר',
    salesimportbcode: 'הוסף קוד סניף ייבוא ​​מכירות',
    addremoveqty: 'הוסף כמות הסרה',
    selectbranchmanager: 'בחר במנהל סניף',
    regiondetails: 'פרטי האזור',
    editregion: 'ערוך אזור',
    addnewregion: 'הוסף אזור חדש',
    country: 'מדינה',
    name: 'שם',
    code: 'קוד',
    city: 'עיר',
    regionmanager: 'מנהל איזור',
    location: 'מיקום',
    address: 'כתובת',
    suggestionprodlevel: 'המלץ על רמת מוצר ',
    salesimportbranchcode: 'מייבא את מספר הסניף מנתוני הקופה',
    telephone: 'טלפון',
    removeqty: 'הסר כמות',
    branchmanager: 'מנהל סניף',
    branches: 'סניפים',
    regions: 'אזורים',
    region: 'אזור',
    general: 'כללי',
    hierarchy: 'הררכיה',
    selectcountry: 'בחר מדינה',
    addcode: 'הוסף קוד',
    addname: 'הוסף שם',
    selectarea: 'בחר אזור',
    fieldisrequired: 'שדה חובה',
    entervalidaname: 'הזן שם חוקי',
    entervalidacode: 'הזן קוד חוקי',
    entervalidaddress: 'הזן כתובת חוקית',
    entervalidlocation: 'הזן מיקום חוקי',
    entervalidcity: 'הזן עיר חוקית',
    entervalidtelephone: 'הזן מספר טלפון בתוקף',
    entervalidprodlevel: 'הזן רמת מוצר חוקית',
    entervalidbranchcode: 'הזן קוד סניף חוקי',
    entervalidquantity: 'הזן כמות חוקית',
    entervalidemail: 'הזן אימייל חוקי',
    USERS: 'משתמשים',
    FILTER_USERS: 'סינון משתמשים',
    ADDED_USERS: 'סינון משתמשים',
    SEARCH_USER: 'חיפוש משתמש',
    USER_NAME: 'שם משתמש',
    ROLE: 'תפקיד',
    ROLES: 'תפקידים',
    BRANCH: 'ענף',
    FIRST_NAME: 'שם פרטי',
    LAST_NAME: 'שם משפחה',
    allregions: 'כל האזורים',
    allbranches: 'כל הסניפים',
    select: 'בחר',
    firstnamerequired: 'שם פרטי חובה',
    lastNamerequired: 'שם משפחה חובה',
    phonerequired: 'מספר טלפון נדרש',
    emailrequired: 'כתובת דוא"ל חסרה',
    addressrequired: 'כתובת חסרה',
    rolerequ: 'נדרש תפקיד משתמש',
    regionIdrequ: 'אזור משתמש נדרש',
    branchreqrequ: 'סניף נדרש',
    resetpassword: 'איפוס סיסמא',
    passrequired: 'חסרה סיסמת משתמש',
    personaldet: 'הגדרות חשבון',
    changepw: 'שנה סיסמא',
    newpw: 'סיסמה חדשה',
    confirmpw: 'אשר סיסמה',
    oldpw: 'סיסמה ישנה',
    userheirachy: 'מבנה ארגוני',
    roletype: 'תפקיד',
    COPY: 'העתקה',
    currentdimentions: 'מידות נוכחיות',
    selectrotation: 'בחר כיוון',
    rotation: 'סובב',
    front: 'חזית',
    front_rotate: 'סיבוב לחזית',
    side: 'צד',
    side_rotate: 'סיבוב צד',
    top: 'חלק עליון',
    top_rotate: 'סיבוב למעלה',
    default: 'ברירת מחדל',
    overlap_safty: 'שולי בטיחות ',
    // task Files
    TASK_FEED: 'עדכון משימות',
    CONFIRM_DELETE_TASK: 'אשר את מחיקת המשימה',
    ARE_YOU_SURE_DELETE_THIS_TASK: 'האם אתה בטוח שברצונך למחוק משימה זו?',
    TASK_IS_DELETED: 'המשימה נמחקה בהצלחה!',
    SAVE_TASK: 'שמור משימה',
    SELECT_LOCATION: 'בחר מיקום',
    MORE_FILTERS: 'סינון חכם',
    TASK_NAME: 'שם המשימה',
    ASSIGED_TO: 'עבור',
    PROGRESS: 'התקדמות',
    STARTDATE_AND_TIME: 'מועד תחילת המשימה',
    ENDDATE_AND_TIME: 'לביצוע עד',
    FREE_SEARCH: 'חיפוש',
    ADD_NEW_TASK: 'הוסף משימה חדשה',
    UPDATE_TASK: 'משימה',
    CANCEL: 'ביטול',
    NEW_TASK: 'משימה חדשה',
    TASK_DETAILS: 'פרטי משימה',
    DESCRIPTION: 'תיאור',
    CATEGORY_OPTIONAL: 'קטגוריה (אופציונלי)',
    SELECT_CATEGORY: 'בחר קטגוריה',
    DESCRIBE_THE_TASK_SHORTLY: 'תיאור קצר של המשימה ',
    ANY_ADDITIONAL_INFO_ABOUT_TASK: ' מידע נוסף אודות המשימה',
    WHO_IS_THE_TASK_IS_FOR: 'למי מיועדת המשימה?',
    TASK_APPROVER: 'מאשר המשימה',
    TASK_SHEDULING: 'תזמון המשימה',
    WHEN_THE_TASK_OCCUR: 'כאשר המשימה מתרחשת',
    THIS_TASK_HAVE_TIMEFRAME: 'משימה מתוחמת בזמן',
    ONCE_DATE: 'תאריך לביצוע:',
    YEAR_DATE: 'תאריך שנתי:',
    UNTIL_DATE: 'עד התאריך:',
    START_TIME: 'שעת התחלה:',
    END_TIME: 'שעת סיום:',
    FEED_BACK: 'סטטוס ביצוע',
    SELECT_FEEDBACK_MEDIA_OPTIONAL: 'בקש מהמשתמש לצרף תמונה או סרטון',
    SELECT_FEEDBACK_TYPE: 'איך תרצה שהמשתמש ישיב למשימה?',
    SELECT_FROM_A_LIST: 'רשימה עם בחירה בודדת',
    CHECK_LIST: 'רשימה עם בחירה מרובה',
    COMMENT: 'טקסט חופשי',
    NUMBER: 'מספר בלבד',
    URGENT_TASK: 'משימה דחופה',
    START_FILLING_OUT_THE_CHECKLIST: "הוסף פריטים לצ'ק ליסט",
    START_FILLING_PUT_THE_LIST: 'הוסף אפשרויות בחירה',
    START_FILLING_OUT_THE_BRANCH: 'הוסף אפשרויות מענה',
    ADD_ANOTHER_ITEM: 'הוסף פריט נוסף',
    WHO_WILL_APPROVE_THE_TASK: 'מי יאשר את המשימה?',
    SELECT_ALL_REGIONS: 'בחר את כל האזורים',
    SELECT_ALL_STORES: 'בחר את כל החנויות',
    SELECT_ALL_WORKERS: 'בחר את כל העובדים',
    SELECT_NONE: 'אל תבחר באף אחת מהאפשרויות',
    WORKERS: 'עובדים',
    WHO_WILL_ALLOCATE_THE_TASK: 'מי יקצה את המשימה?',
    ASSIGN: 'הקצאה',
    SELECT_ASSIGNEE_FOR: 'בחר את הנמען ',
    SEARCH_EMPLOYEE: 'חיפוש לפי עובד',
    APPLY: 'אשר',
    MY_TASKS: 'המשימות שלי',
    TEAM_TASKS: 'משימות צוות',
    //task summery
    TOTAL_COUNT: 'סך הכל:',
    IN_AREA: 'באיזור :',
    DONE: 'בוצע',
    Issue: 'לא בוצע',
    IN_PROGRESS: 'בביצוע',
    ALL_REGIONS: 'כל האזורים',
    NO_CHAT_RESULTS: "לא קיימת התכתבות הוסף תגובה  כדי להתחיל צ'אט עם המשתמש",
    COUNTED: 'נספר',
    SELECTED_CHOICE: 'בחירה',
    ANSWER: 'תשובה',
    OPEN_CHAT: "פתח צ'אט",
    CLOSE_CHAT: "סגור צ'אט",
    ASKTO_REDO: 'בקש לבצע מחדש',
    SENDREDO_REQ: 'שלח בקשה לביצוע מחדש',
    ADD_RES_TO_REDO: 'הוסף סיבה לביצוע מחדש',
    //new llll
    FILTER: 'סנן',
    URGENT: 'דחוף',
    ASSIGNEE: 'שייך',
    PROGRESS_IN_PERCENT: 'התקדמות באחוזים',
    DATE_ANMND_TIME_RANGE: 'טווח תאריכים ושעות',
    APPROVE: 'אשר',
    EDIT: 'עריכה',
    REDO_REQUIRED: 'חובה לבצע מחדש',
    PLEASE_SELECT_AT_LEAST_ON_ASSIGNEE: 'אנא בחר נציג אחד לפחות',
    TASK_ASSIGN_DETAILS_SAVED: 'פרטי הקצאת המשימות נשמרו',
    APPLY_FILTER: 'אישור',
    RESET_FILTERS_SHOWALL: 'איפוס',
    FROM: 'מ',
    TO: 'עד',
    PLEASE_SE_DATE_RANGE: 'אנא הגדר טווח תאריכים!',
    PLEASE_SET_A_FROM_DATE: 'אנא הגדר תאריך התחלה!',
    PLEASE_SET_TO_DATE: 'אנא הגדר תאריך סיום!',
    COMMENT_ADDED: 'הערה נוספה!',
    PLEASE_ADD_COMMENT: 'אנא הוסף הערה!',
    EXPLAIN_WHY_REDO_NEED: 'הסבר מדוע יש צורך לבצע מחדש!',
    REDO_ADDED: 'הוסף מחדש!',
    REDONE: 'בוצע מחדש',
    RESON_FOR_REDO: 'הסיבה לביצוע מחדש:',
    NO_IMG_PLACED_YET: 'עדיין לא נבחרה תמונה',
    ADD_COMMENT: 'הוסף תגובה',
    MUST_USE_CAM: 'שימוש במצלמה בלבד',
    CONFIRM_OVELAP_ERROR: 'כמה שדות של פלנוגרמה חופפים לפלנוגרמה פעילה!',
    REARRANGE: 'לסדר מחדש',
    TASK_CAN_BE_APPROVE: 'ניתן לאשר את המשימה',
    TASK_CANNOT_BE_APPROVED: 'לא ניתן לאשר את המשימה',
    SUBTASK_COMPLETED_SURE_TO_APPROVE_THIS_TASK:
        'כל משימות המשנה הושלמו. האם אתה בטוח שברצונך לאשר את המשימה הזו?',
    SEE_TASK_DETAILS: 'ראה פרטי משימות',
    CANNOT_APPROVE_UNCOMPLETED_SUB_TASKS:
        'לא ניתן לאשר משימה זו מכיוון שישנן משימות משנה שלא הושלמו',
    SOME_OF_USERS_HVNT_COMPTASKS_WANT_TO_APPROVE:
        'חלק מהמשתמשים לא הצליחו לבצע את המשימה. האם אתה בטוח שברצונך לאשר את המשימה הזו?',
    APPROVE_ANYWAY: 'אשר בכל זאת',
    ALLOW_OVERLAP: 'אפשר חפיפה',
    DISABLE_OVERLAP: 'השבת חפיפה',
    MORE_VERSIONS: 'עוד גרסאות',
    OPEN: 'פתח',
    left_field: 'שדה שמאלי',
    right_field: 'שדה ימין',
    no_dept: 'אין מחלקה',
    only_field: 'שדה בלבד',
    CANNODELETE_HAS_OVELAP_PRODUCT:
        'לא ניתן למחוק שדה זה מכיוון שיש לו מוצר חופף!',
    CANNOTDRAG_HAS_OVERLAP_PRODUCT:
        'לא ניתן לגרור את השדה מכיוון שיש לו מוצר חופף!',
    HAVE_UNSAVE_CHANGES_UPPDATE_BEFORE_CONFIRM:
        'יש  שינויים שלא נשמרו, אנא שמור לפני אישור!',
    UNSAVE_CHANGES: 'שינויים שלא נשמרו',
    OVELAP: 'חופף!',
    CONFIRM_TO_CHANGE_USER_REGION: 'אשר כדי לשנות אזור משתמש',
    THIS_HAVE_REGION_MANAGER_SURE_TO_SET:
        'לאזור זה כבר יש מנהל אזור. האם אתה בטוח להגדיר משתמש זה כמנהל אזור?',
    CONFIRM_TO_CHANGE_USER_BRANCH: 'אשר כדי לשנות את ענף המשתמש',
    CONFIRM_TO_CHANGE_USER_BRANCH_MESSAGE:
        'לסניף זה כבר יש מנהל סניף. האם אתה בטוח להגדיר משתמש זה כמנהל סניף?',
    CONFIRM_TO_DELETE_USER: 'אשר למחיקת משתמש',
    CONFIRM_TO_DELETE_USER_MESSGAE: 'האם אתה בטוח שברצונך להסיר משתמש זה?',
    SUCCESSFULL_UPDATE_PASSWORD: 'הסיסמה עודכנה בהצלחה',
    CONFIRM_RESET_USERPASSWORD: 'אשר לאיפוס סיסמת המשתמש',
    CONFIRM_RESET_USERPASSWORD_MESSAGE:
        'האם אתה בטוח שברצונך לאפס את סיסמת המשתמש הזו?',
    FEED: 'משימות',
    OLDPASSWORD_REQ: 'יש צורך בסיסמה ישנה',
    NEWPW_REQ_MIN4LENGTH: 'נדרשת סיסמה חדשה. (אורך מינימלי - 4 תווים)',
    CONFIRM_PW_REQ: 'אשר את הסיסמה הנדרשת',
    NEWPW_CANNOT_SAME_OLD: 'סיסמה חדשה לא יכולה להיות זהה לסיסמה ישנה',
    CONFIRM_PW_DOESNT_MATCH_NEWPW: 'אשר הסיסמה אינה תואמת את הסיסמה החדשה',
    HAVE_UNSAVE_CHANGES_UPPDATE_BEFORE_THIS_TASK:
        'יש לך שינויים שלא נשמרו, אנא עדכן לפני ביצוע משימה זו!',
    WHAT_YOU_WANT_EXPLORE: 'מה אתה רוצה לחקור היום?',
    SALE_PER_FACE: 'מדד יומי',
    GENERAL_SALES: 'מכירות כלליות',
    SEL_TIME_RANGE: 'בחר טווח זמן',
    SELTED_TIME_RANGE: 'טווח זמן שנבחר',
    SHOW: 'הצג',
    TIME_RANGE: 'טווח זמן',
    VERSIONS: 'גרסאות',
    FROM_DATETIME: 'החל מתאריך',
    TO_DATETIME: 'תאריך ביטול המשימה',
    SELDEPT_AVAILABLE: 'נבחרה מחלקה שאינה תואמת את מחלקת המוצרים הנוכחית',
    persalesfromrevenue: 'שינוי באחוז ממוצע המכר',
    changeFromAvg: 'השוואה לממוצע',
    perfromsimilarstore: 'אחוז שינוי מסניפים דומים',
    changefromavgspf: 'שינוי מדד יומי',
    perofavgfacingqty: 'אחוז שינוי בשטח',
    NEW: 'חדש',
    RESETALL: 'אפס סינון',
    NEWGOAL: 'מסנן חדש',
    ADDNEWGOAL: 'הוסף מסנן חדש',
    EDITNEWGOAL: 'ערוך מסנן חדש',
    SAVEGOAL: 'שמור מסנן',
    UPDATEGOAL: 'עדכן מסנן',
    goalmeasurename: 'שם מסנן',
    searchmeasure: 'מה למדוד?',
    condition: 'תנאי',
    optional: 'אופציונאלי',
    equal: 'שווה',
    notequal: 'לא שווה',
    greaterthan: ' גדול מ',
    lesserthan: 'קטן מ',
    goalsuccesssaved: 'פרטי הסינון נשמרו בהצלחה',
    goalsuccessdeleted: 'פרטי הסינון נמחקו בהצלחה',
    showresults: 'בחר מגמה ',
    resultscount: 'כמות סניפים',
    afilterselected: 'מסנן נבחר',
    NOCHARTDATAAVAILABLE: 'אין נתוני תרשים זמינים',
    HEADQUATERS: 'הנהלה',
    NOSTORE: 'אין חנות',
    chartview: 'תרשים',
    tableview: 'תצוגת טבלה',
    TASK_NAME_REQ: 'נדרש שם משימה!',
    DECRIPTION_REQ: 'תיאור נדרש!',
    SELECT_WHO_THE_TASK_IS_FOR: 'בחר למי מיועדת המשימה!',
    SELECT_WHO_WILL_APPROVE_THE_TASK: 'בחר מי יאשר את המשימה!',
    SELECT_REPEAT_TYPE: 'בחר סוג חזרה!',
    ONCE_DETAILS_REQ: 'ברגע שנדרשים פרטים!',
    START_TIME_REQ: 'זמן התחלה נדרש!',
    WEEKLYDETAILS_REQ: 'פרטים שבועיים חובה!',
    UNTIL_DATE_REQ: 'עד שנדרש התאריך!',
    YEARLY_DATE_REQ: 'תאריך שנתי חובה!',
    END_TIME_REQ: 'שעת סיום חסרה!',
    WEEK_REQ: 'שבוע חובה!',
    DAY_REQ: 'יום נדרש!',
    PLEASE_FILL_FEEDBACK: 'אנא מלא משוב!',
    PLEASE_FILL_ALL_FIELDS_FEEDBACK: 'אנא מלא את כל שדות המשוב!',
    PLEASE_FILL_FEEDBACK_TYPE_OPTIONS: 'אנא מלא סוג המשוב הנדרש!',
    IN_WICH_WEEK_OFTHE_MONTH: 'באיזה שבוע בחודש?',
    IN_WICH_DAY_OF_THE_WEEK: 'באיזה יום בשבוע?',
    ONCE: 'חד פעמי',
    WEEKLY: 'כל שבוע',
    MONTHLY: 'כל חודש',
    YEARLY: 'כל שנה',
    FIRST: 'ראשון',
    SECOND: 'שני',
    THIRD: 'שלישי',
    FOURTH: 'רביעי',
    SUN: 'ראשון',
    MON: 'שני',
    TUE: 'שלישי',
    WED: 'רביעי',
    THU: 'חמישי',
    FRI: 'שישי',
    SAT: 'שבת',
    CHANGE_CURRENT_STORE: 'שנה את החנות הנוכחית',
    THISWILL_REDIRECT_LANDING_PAGE_R_U_SURE:
        'זה ינתב מחדש לדף נחיתה, אתה בטוח?',
    PLEASE_ADD_AT_LEASTONE_FIELD_TOSAVE: 'אנא הוסף שדות לשמירה',
    LATE: 'באיחור',
    FUTURE: 'עתידיות',
    TODO: 'בתהליך',
    For_my_Approval: 'ממתין לאישור',
    COMPLETE: 'הושלם',
    NOW: 'חדשה',
    FILL_ALL_REQ_FIELDS: 'מלא את כל השדות הנדרשים!',
    PLANOGRAMIS_LOCKED: 'הפלנוגרמה נעולה!',
    REMOVELOCK_PLANO: 'ביטול נעילה',
    SURE_REMOVE_PLANO:
        'שמירת שינויים  אפשרית בגרסת טיוטא בלבד, האם אתה בטוח שברצונך לשחרר את הנעילה?',
    VIDEO: 'סרטון',
    PICTURE: 'תמונה',
    notavailable: 'לא זמין',
    NOTDONE: 'לא הסתיים',
    I_CANNOT_DO: 'לא יכול לבצע',
    ADD_VALID_APPROVERS: 'מאשר המשימה שבחרת אינו חוקי!',
    TYPE_TO_SEARCH: 'הקלד לחיפוש',
    CREATED_DATE: 'תאריך יצירה',
    ERROR_OCCORED_IN_PROCESS: 'אירעה שגיאה בתהליך',
    EMP_HEIRACHY_SAVED: 'היררכיה של העובדים נשמרה בהצלחה',
    ADD_EMP_ROLES_CORRECTLY: 'הוסף בצורה נכונה סוגי תפקידי עובדים',
    ADD_EPM_NAMES_CORRECT: 'הוסף שמות עובדים בצורה נכונה',
    USER_LEVEL: 'רמת משתמש',
    PLEASE_SET_CREATED_DATE_RANGE: 'תאריך יצירה צריך להיות טווח תאריכים!',
    usergroups: 'קבוצות משתמש',
    usercounts: 'ספירת משתמשים',
    editusergroup: 'ערוך קבוצת משתמשים',
    addnewusergroup: 'הוסף קבוצת משתמשים חדשה',
    groupname: 'שם קבוצה',
    addedgroups: 'נוספו קבוצות משתמשים',
    confirmdeletegroup: 'אשר כדי למחוק קבוצת משתמשים',
    confirmdeletegroupsub: 'האם אתה בטוח שתמחק את קבוצת המשתמשים הזו?',
    typegroupname: 'הקלד את שם הקבוצה',
    selectusers: 'בחר משתמשים',
    selarole: 'בחר תפקיד',
    selaregion: 'בחר אזור',
    selastore: 'בחר סניפים',
    selagroup: 'בחר קבוצה',
    DATA_LOADING_FAIL: 'טעינת הנתונים נכשלה!',
    STORE: 'סניפים',
    GROUP: 'קבוצות',
    SEARCH: 'לחפש',
    questionnaire: 'סקרים',
    addnewquestionear: 'צור סקר חדש',
    addnewquestion: 'הוסף שאלה',
    activitylog: 'יומן פעילות',
    addtonewtask: 'עבור ליצירת משימה מסקר',
    save_question: 'שמור שאלה',
    dismiss_changes: 'בטל שינויים',
    NOSELECTEDQUEST: 'לא נבחר סקר',
    USEDINTASKS: 'כמות משימות משוייכות',
    CREATEDBY: 'פותח הסקר',
    NUMBEROFQUESTIONS: 'מספר שאלות',
    QUESTNAME: 'שם הסקר',
    NOSELECTEDQUESTION: 'אין שאלה נבחרת',
    QUESTION: 'שאלה',
    STAGEQUESTION: 'שאלת במה',
    FEEDBACK_TYPE: 'סוג משוב',
    REQUIRED_MEDIA: 'מדיה חסרה',
    ACTIONON_COMPLETE: 'פעולה על סיום',
    AFTER_COMPLETE_ACTION: 'לאחר פעולה מלאה',
    TYPE_ANSWER: 'הקלד תשובה',
    TYPE_ACTIONHERE: 'הקלד פעולה כאן',
    SELECT_ACTION: 'בחר פעולה',
    SELECT_QUESTION_TO_GOTO: 'או בחר שאלה ללכת אליה',
    TARGET_QUESTION: 'שאלת יעד',
    ALREADYLOADING: 'כבר טוען, אנא המתן...',
    EDIT_TASK: 'ערוך משימה',
    TASK_USE_QUESTIONIER: 'שימוש במערכת הסקרים',
    NEWQUESTIONER_CAN_CREATE_IN: 'ניתן לייצר סקר חדש על ידי ',
    QUESTIONNAIRE: 'סקר',
    TYPEQUESTNAIRENAME: 'חיפוש סקר על פי שם',
    TYPEQUESTIONNAME: 'הקלד את שם השאלה',
    NEXT: 'הבא',
    NONE: 'אף אחד',
    GOTO: 'לך ל',
    published: 'פורסם',
    draft: 'טיוטה',
    replaced: 'הוחלף',
    deleteaquestionear: 'מחק סקר',
    areyousuretodeletethisquestionear: 'האם למחוק את הסקר הזה?',
    succesfullyquestioneardeleted: 'הסקר נמחק בהצלחה',
    questionnamereq: 'נדרש שם השאלה',
    questcheckoption: 'אפשרות בדיקת שאלות',
    questselectitem: 'פריט בחירת שאלה',
    questbranchitem: 'פריט ענף שאלה',
    gotoquestion: 'עבור לשאלה הנדרשת',
    namerequired: 'שם (חובה',
    questinearnamereq: 'נדרש שם הסקר',
    addatleastonequest: 'הוסף לפחות שאלה אחת',
    successquestsaved: 'הסקר נשמר בהצלחה',
    somequestcompletenotadded: 'חלק מהשאלות השלימו פעולה לא נוספה',
    doneavailmorethanone: 'פעולה בוצעה זמינה יותר מאחת',
    somequestlistnotavail: 'חלק מהשאלות רשימת פריטים לא נוספו',
    somebranchitemsactype: 'חלק מהשאלות בפריטי סניף לא הגדירו סוגי פעולה',
    lastquestnotavaildone: 'שאלה אחרונה לא זמינה פעולה בוצעה',
    successquestpublished: 'הסקר פורסם בהצלחה',
    successquestionsaved: 'השאלה נשמרה בהצלחה',
    saveorclosenewquest: 'יש לשמור לפני ביצוע שינויים',
    savenewquestarrange: 'שמור תחילה את סידורי רשימת שאלות החדשות',
    saveorclosequestion: 'תחילה שמור או סגור שאלה שנפתחה',
    otherquestionsavail: 'קישורי שאלות נוספים זמינים מתוך שאלה זו,',
    deleteaquestion: 'מחק שאלה',
    areyousuretodeletequestion: 'האם אתה בטוח שברצונך למחוק את השאלה הזו?',
    publishaquestinear: 'פרסם סקר',
    areyousuretopublish: 'האם אתה מעוניין לפרסם את הסקר הזה?',
    somequestionextgotolinks: 'חלק מהשאלות הבא/עבור לקישורים - שגויות',
    questlinkswillbereplaced: 'חלק משאלות ״עבור לקישורים״ יוחלף ל״ללא״',
    changingquestview: 'שינוי תצוגת השאלה',
    areyousuretochangequestion:
        'שינויים בשאלות זמינים, האם אתה בטוח שברצונך לשנות שאלה?',
    skippedquestavlble: 'שאלות שניתן לדלג עליהן זמינות, קשר אותן לפני הפרסום',
    unpublishaquestinear: 'בטל הפצת סקר',
    areyousuretounpublish: 'האם אתה בטוח שברצונך לבטל הפצת סקר??',
    successquestunpublished: 'ביטול הפצת הסקר בוצע בהצלחה',
    APPLY_FILTERS: 'החל מסננים',
    HEADQUARTERS: 'מטה',
    SELECTQ_OR_CREATE_NEW: 'בחר סקר או צור חדש',
    QR_SUCCESS: 'נסרק',
    QR_FAIL: 'ממתין ל',
    MARK_AS_CANNOTDO_SUCCESS: 'קיבלנו, מעדכנים הלאה',
    SELECT_QUESTION: 'סקר זה כבר משתתף! אנא בחר שאלה!',
    CANNOT_Do: 'לא יכול לעשות',
    CONFIRM_ALL_QUESTIONS: 'אשר שלא ניתן לבצע את כל הסקר',
    CONFIRM_ALL_QUESTIONS_MESSAGE:
        'סקר זה לא משתתף. האם אתה בטוח שברצונך לסמן את כל הסקר כ׳לא ניתן לבצע׳?',
    SURETO_CONTINUE: 'האם ברצונך לאשר?',
    CONFIRM_BRANCH_THE_ALL_CANNOTDO:
        'השאלות שנבחרו כוללות שאלה מסוג ׳ענף׳. לכן כל השאלות הממתינות יסומנו כ"לא יכול לעשות"',
    PENDING: 'ממתין',
    SEEMORE: 'ראה עוד +',
    NORESULTFOUNDSEARCH: 'לא נמצאו תוצאות לחיפוש',

    connection_offline: 'חיבור לא מקוון',
    slow_connection: 'חיבור איטי',
    MARKAS_CANNOT_DO: 'משימה זו תסומן כ׳לא ניתן לעשות׳. האם אתה רוצה להמשיך?',
    STORE_TASK_PROGRESS: 'סטטוס משימות - מבט על',

    uomlist: {
        meters: 'מטרים',
        cm: 'סנטימטרים',
        inches: 'אינץ',
        feet: 'רגל',
        CM_SHORT:'ס"מ'
    },
    SET_ISLE: 'הגדר ׳מעבר׳',
    ISLECHANGES_NOT_SET: 'הגדר את ה׳מעבר׳ להמשך',
    CHANGES_OF_ISLE_NOTSET_PLEASE_SETISLE_BEFORE:
        'אנא הגדר את ה׳מעבר׳ לפני תהליך זה',
    FILE_UPLOADING: 'מעלה קובץ...',

    SELECT_MEASURE: 'בחר מידה',
    SELECT_CONDTION: 'בחר תנאי',
    SELECT_DEPARTMENT: 'בחר מחלקות',
    SELECT_STORETAG: 'בחר תגיות חנות',
    SELECT_PRODUCT: 'בחר מוצרים',
    ADD_ALL: 'הוסף הכל',

    WEBSITE_IS: 'אתר אינטרנט הוא',
    UNDER_CONST: 'מתבצעות עבודות תחזוקה',
    RELOAD_APP: 'טען מחדש את האפליקציה',

    ISRIGHTCHANGE: 'שדה ימני חופף',
    ISLEFTCHANGE: 'שדה שמאלי חופף',

    qty_add: 'כמות הוסף',
    add_new_block: 'הוסף בלוק חדש',
    qty_remove: 'כמות הסר',
    remove_block: 'הסר חסימה',
    remove_product: 'הסר את המוצר',

    remove_dashboard_filter: 'הסר מסננים מהדשבורד',
    areyousure_remove_filter: 'האם אתה בטוח להסיר את המסנן הזה?',
    redirect_from_dashboard: 'הפנה מחדש מהדשבורד',
    areyousure_redirect_tolayout:
        'האם אתה בטוח שברצונך לחזור לתצוגת פריסת פלנוגרמה?',
    storeactive_planogram_notfound: 'חפלנוגרמה פעילה לחנות זו לא נמצאה',
    filter_namerequired: 'חסר שם למסנן',
    filter_measurerequired: 'חסר סוג מדידה ',
    filter_conditionrequired: 'בחר תנאי',
    filter_valuerequired: 'נא להזין סכום',
    REMOVE_ALL: 'להסיר את כל',
    SALES_LOG: 'בקרת נתונים',
    SALES_DATE_N_TIME: 'תאריך ושעת מכירה',
    FILE_NAME: 'שם קובץ',
    SYNC_DATE_N_TIME: 'מועד סנכרון',
    REMARK: 'הערה',
    SYNC_LOG: 'סנכרון קבצים',
    PRODUCT_LOG: 'סנכרון מוצרים',
    PRODUCT_NAME: 'שם מוצר',
    JOB_NUMBER: 'מספר עבודה',
    SYNC_TYPE: 'סוג סנכרון',
    SALES_PLUS_TAX: 'מכר כולל מס',
    QUANTITY: 'כמות',
    SALES_DATE: 'תאריך מכירה',
    BACK_TO_SYNCLOG: 'חזרה לכל הקבצים',
    PLEASE_SET_VALID_DATE_RANGE: 'נא הגדר טווח תאריכים חוקי!',
    PLEASE_SET_A_VALID_TIME: 'זמן לא חוקי או שעבר',
    PLEASE_SET_A_VALID_TIME_FOR_THE_DATE: 'נא לקבוע שעה לאותו תאריך!',
    PLEASE_SET_VALID_CREATED_DATE: 'נא לקבוע תאריך יצירה חוקי!',
    PLEASE_SET_A_VALID_DATE: 'נא לקבוע תאריך תקף!',
    PLEASE_SET_A_UNTIL_DATE: 'נא לקבוע תאריך עד!',
    PLEASE_SET_A_VALID_DATE_FOR_TIME:
        'נא להגדיר תאריך תקף לשעה שכן הוא כבר עובר את שעת ההתחלה!',
    PLEASE_SET_TASK_OCCURANCE: 'אנא הגדר את אירוע המשימה!',
    PLEASE_SET_A_ONCE_DATE: 'נא לקבוע תאריך חד פעמי!',
    PRODUCTOBARCODE: 'מוצר, ברקוד',
    SUCCESS: 'טופל',
    FAIL: 'נכשל',
    SALE_TO: 'מכירות עד היום',
    SALE_FROM: 'מכירות מתאריך',
    SYNC_TO_DATE: 'סנכרון לתאריך',
    SYNC_FROM_DATE: 'תאריך',
    PLANIGO_TASK_ONLY: 'משימות פלניגו בלבד',
    DETAILED_VIEW: 'תצוגה מפורטת',
    MANUAL_TRIGGER: 'סנכרן עכשיו',
    SYNC_FAIL: 'סנכרון נכשל!',
    SYNC_SUCCESS: 'הסנכרון הצליח!',
    FORCE_ISSUE_SYNC: 'כפה סנכרון בעיה',

    manual_compliance: ' בדיקת תקינות ידנית',
    chain: 'רשת',
    jobno: 'בדיקה מספר',
    jobdetails: 'פרטי עבודה',
    request_by: 'התבקש על ידי',
    requested_datetime: 'תאריך מבוקש ושעה',
    active_view: 'תצוגה פעילה',
    review: 'סקירה',
    markthisascomp: 'סמן את זה כתקין',
    rectchanges: 'שינויים',
    CONFIRM_TO_UPDATE: 'אשר לעדכון',
    PLEASE_TAKE_ACTION_BEFORE_UPDATE: 'נא לפעול לפני עדכון!',
    MANUAL_COMPLANCE_SUBMITTED: 'בדיקת תקינות ידנית נשלחה!',
    MANUAL_COMPLANCE_FAIL: 'בדיקת תקינות ידנית שליחה נכשלה!',
    PLEASE_ADD_REMARK: 'נא להוסיף הערה!',
    SUCCESSFULLY_REDO_REQUESTED: 'בוצעה בקשה מחדש בהצלחה!',
    REDO_REQUESTED_FAIL: 'הבקשה מחדש נכשלה!',
    ADD_REMARK_REDO: 'הוסף הערה לביצוע מחדש',
    ADD: 'הוסף',
    REMOVE: 'הסרה',
    DRAW_INSIDE_IMAGE: 'נא לצייר בתוך התמונה!',

    disable_pan: 'השבת תחילה את כלי העריכה',

    chaindepartments: 'היררכיות',
    addNewDepartment: 'הוסף מחלקה חדשה',
    categories: 'קטגוריות',
    categoryName: 'שם קטגוריה',
    addnewcategory: 'הוסף קטגוריה חדשה',
    addnewsubcategory: 'הוסף תת קטגוריה חדשה',
    addnewbrand: 'הוסף מותג חדש',
    subCategory: 'שם תת קטגוריה',
    enter_catgory_name_placeholder: 'הזן את שם הקטגוריה',
    enter_subcat_name: 'הזן את שם תת קטגוריה',
    select_brand_placeholder: 'בחר שם מותג',
    select_departement_warn: 'בחר מחלקה',
    category_name_empty_warn: 'הזן את שם הקטגוריה',
    add_categories_warn: 'הוסף קטגוריות',
    subcategory_name_empty_warn: 'לא הוזן שם תת הקטגוריה',
    brand_empty_warn: 'בחר מותג',
    category_name_duplicate_warn: 'שם הקטגוריה משוכפל',
    subcategory_name_duplicate_warn: 'שם תת הקטגוריה משוכפל',
    brand_duplicate_warn: 'המותג משוכפל',
    addcategory: 'הוסף קטגוריה',
    removecatgory: 'הסר קטגוריה',
    addsubcategory: 'הוסף תת קטגוריה',
    removesubcategory: 'הסר תת קטגוריה',
    addbrand: 'הוסף מותג',
    removebrand: 'הסר מותג',
    searchdep: 'חפש מחלקה',
    searchcategory: 'חפש קטגוריה',
    searchsubcat: 'חיפוש תת קטגוריה',
    searchbrand: 'חפש מותג',
    category: 'קטגוריה',
    subcategory: 'תת קטגוריה',
    selectcategory: '(בחר קטגוריה)',
    selectsubcategory: '(בחר תת קטגוריה)',
    already_added: 'כבר נוסף',
    SUCCESSFULLY_UPDATED: 'עודכן בהצלחה',
    SUCCESSFULLY_DELETED: 'נמחק בהצלחה',
    SUCCESSFULLY_ADDED: 'נוסף בהצלחה',
    SELECT_BRAND: 'בחר מותג',
    ENTER_SUB_CATEGORY_NAME: 'הזן את שם קטגוריית המשנה',

    brands: 'מותגים',
    search_brands: 'חיפוש מותגים',
    brandname: 'שם מותג',
    brandName: 'שם מותג',
    branddetails: 'פרטי מותג',
    BRAND_NAME_DUPLICATE: 'כפול שם מותג',
    suplable: 'ספק',
    supselectplceholder: 'בחר ספק',
    SUCCESSFULLY_BRND_DETAILS_DELETED: 'המותג נמחק בהצלחה',
    SUCCESSFULLY_BRND_DETAILS_SAVED: 'המותג נשמר בהצלחה',
    SUCCESSFULLY_BRND_DETAILS_UPDATED: 'המותג עודכן בהצלחה',

    suppliers: 'ספקים',
    search_suppliers: 'חפש ספקים',
    supname: 'שם ספק',
    supplierName: 'שם ספק',
    supcode: 'קוד ספק',
    supplierCode: 'קוד ספק',
    supdetails: 'פרטי ספק',
    SUPPLIER_CODE_DUPLICATE: 'שכפול קוד ספק',
    SUPPLIER_NAME_DUPLICATE: 'כפול שם הספק',
    ENTER_SUPPLIER_NAME: 'הזן את שם הספק',
    ENTER_SUPPLIER_CODE: 'הזן קוד ספק',
    SUCCESSFULLY_SUP_DETAILS_DELETED: 'הספק נמחק בהצלחה',
    SUCCESSFULLY_SUP_DETAILS_SAVED: 'הספק נשמר בהצלחה',
    SUCCESSFULLY_SUP_DETAILS_UPDATED: 'הספק עודכן בהצלחה',

    CANT_DELETE_REFERENCE: 'אתה לא יכול למחוק את זה כאשר הפניות זמינות',
    DEP_WITH_CAT_EXISTS: 'מחלקה עם קטגוריה כבר קיימת.',
    ARE_YOU_SURE_TO_CONTINUE_DELETE_WILL_DELETE_DEPARTMENT:
        'זהו הקטגוריה היחידה של המחלקה. אם תמחק את זה, המחלקה תימחק גם כן. האם אתה בטוח שברצונך להמשיך?',
    NO_CATS_AVL_ARE_YOU_SURE_CONTINUE:
        'אין קטגוריות זמינות, המחלקה תוסר אם אין קטגוריות. האם אתה בטוח שברצונך להמשיך?',

    icon: 'אייקון',
    selecticon: 'בחר אייקון',
    changeicon: 'שנה אייקון',
    productoptions: 'אפשרויות מוצר',
    ismvp: 'האם זה מוצר מוביל',
    isontop: 'זה מוצר נמוך במכירות',
    isnoos: 'בקרת חוסרים',
    isfav: 'מועדף',
    ispremium: 'פרימיום',
    minqty: 'כמות פייסינג מינימלית ',
    maxqty: 'כמות פייסינג מקסימלית ',
    minrev: 'מכר מינימלי',
    maxrev: 'מכר מקסימלי',
    shelvelife: 'חיי מדף',
    paceOfSalesInQty: 'קצב המכירה',
    product_info: 'מידע על המוצר',
    product_options: 'אפשרויות מוצר',
    CONFIRM_SYNC: 'אשר סנכרון',
    ARE_YOU_RUN_iMMEDIATE_SYNC: 'האם אתה בטוח שברצונך להפעיל סנכרון מיידי?',
    ARE_YOU_SURE_YOU_WANT_TO_FORCE_SYNC_SALE_DATE_FILES:
        'האם אתה בטוח שברצונך לכפות סנכרון של קובצי תאריך המכירה',
    THOSE_FILES_CAN_CONTAINE_DATA_WITH_MISSMATCH_WITH_LAST_SYNC_DATE:
        'קבצים אלה יכולים להכיל נתונים עם חוסר התאמה בין תאריך לתאריך הסנכרון האחרון?',
    ARE_YOU_SURE_THIS_CANNOT_BE_REVERSE:
        'אתה מבקש לבצע שינוי בלתי הפיך ולא מומלץ. האם אתה בטוח?',
    THIS_PROCESS_CANNOT_BE_REVERSE_ARU_SURE:
        'שינוי זה הוא בלתי הפיך. האם אתה בטוח?',
    Simulate: 'צור פלנוגרמה ',
    CLOSE_SIMULATION: 'האם אתה בטוח שברצונך לסגור את הסימולטור?',
    Select_tags: 'בחר תגים',
    PLEASE_SELECT_TAGS: 'אנא בחר תג!',
    PLASE_SADD_FIELD_COUNT: 'על מנת לייצר סימולציה יש להגדיר כמות שדות',
    YOU_HAVE_UNSAVED_CHANGES: 'יש שינויים שלא נשמרו',

    store_tags: 'תגיות חנות',
    planogram_tags: 'תגיות פלנוגרמה',
    srchstoretag: 'תג חיפוש בחנות',
    srchpltag: 'חפש תגיות לפלנוגרמה',
    selecttag: 'בחר תגיות',
    addtag: 'הוסף תגית',
    select_tag: 'בחר תגיות',
    tag_already_added: 'כבר נוסף',
    SURE_TO_REMOVE_TAG: 'האם אתה בטוח שברצונך להסיר את התג הזה?',
    sub_categories: 'תתי קטגוריה',
    PRODUCT_DETAILS: 'פרטי מוצר',
    isontopitem: 'הוא מוצר בעל מכירות נמוך',
    ismvpitem: 'הוא מוצר מוביל',
    on_top_products: 'מוצרים במכירות נמוך',
    mvp_products: 'המוצרים הנמכרים ביותר',
    ENTER_BRAND_NAME_WARN: 'הזן שם מותג',
    SELECT_BRAND_SUPPLIER_WARN: 'בחר ספק',
    manualOverride: 'מוצרים מובילים/מוכר נמוך \nעקוף',
    ARE_YOU_SURE_TO_DELETE_Block: 'האם אתה בטוח שברצונך למחוק חסימה?',
    THIS_WILL_DELETE_ALL_SELECTED_PRODS:
        'פעולה זו תמחק את כל המוצרים שנבחרו בבלוק',
    NO_ADDED_PRODUCTS_MSG: 'לא נמצאו מוצרים להוספה',
    NO_REMOVED_PRODUCTS_MSG: 'לא נמצאו מוצרים להסרה',
    CLIP_BOARD: 'לוח',
    CUT: 'גזור',
    ZOOM_IN: 'התקרב',
    ZOOM_OUT: 'התרחק',
    RESET_ZOOM: 'אפס זום',
    PAN: 'ניווט',

    master_planograms: 'פלנוגרמה ראשית',
    master_planogram: 'Master Planogram',
    newmasterplanogram: 'New Master Planogram',
    letsstart: 'בואו נתחיל',
    department_meta_data: 'הגדרות גלובליות של המחלקה',
    continue_btn: 'המשך',
    skip_btn: 'דלג',
    no_added_categories: 'הגדר חוקים ומקם קטגורייות',
    required_minqty: 'ספירת ה׳פייס׳ המינימלית לא יכולה להיות ריקה או קטנה מאפס',
    required_maxqty:
        'ספירת ה׳פייס׳ המקסימלית לא יכולה להיות ריקה או קטנה מאפס או קטנה מספירת הפנים המינימלית',
    required_minrev: 'ערך ההכנסה המינימלי לא יכול להיות ריק או קטן מאפס',
    required_maxrev:
        'ערך ההכנסה המקסימלי לא יכול להיות ריק או קטן מאפס או קטן מערך ההכנסה המינימלי',
    required_shelflife: 'ערך חיי המדף המינימלי אינו יכול להיות ריק או קטן מאפס',
    required_pacesale: 'קצב המכירה המינימלי לא יכול להיות ריק או קטן מאפס',
    all_categories: 'כל הקטגוריות',
    default_category: 'קטגוריית ברירת מחדל',
    defaultsub_category: 'תת קטגוריה ברירת מחדל',
    sub_category: 'תת קטגוריה',
    dept_rules: 'כללי המחלקה',
    cat_rules: 'מסע לקוח',
    subcat_rules: 'חוקי תת קטגוריה',
    REC: '%מכר',
    PROD_QTY: 'כמות מוצר',
    SELECT_SUPPLIER: 'בחר ספק',
    SELECT_RECT: 'בחר תיבה',
    TOOLS: 'כלים',
    FIELD_COUNT: 'הזן כמות שדות',
    SUMMARY: 'נתונים',
    NO_SIMULATION_PREVIEW: ' לחץ על תרחיש או סניף על מנת להתחיל לערוך',
    ALL_CATEGORIES: 'כל הקטגוריות',
    CLIPBOARD: 'לוח',
    removeproducts: 'הסר מוצרים',
    save_masterplanogram: 'שמור פלנוגרמה נוכחית',

    selectfield: 'בחר שדה',
    catalreadyadded: 'הקטגוריה כבר נוספה',
    catsavedsuccess: 'הקטגוריות נשמרו בהצלחה',
    addcatsfirst: 'הוסף תחילה קטגוריות',
    addcatfirst: 'הוסף קטגוריה אחת לפחות',
    catnotfound: 'הקטגוריה לא נמצאה',
    selectasubcategory: 'בחר תת קטגוריה',
    activatedrawtool: 'הפעל תחילה את כלי הציור',
    subcatsavesuccess: 'קטגוריות המשנה נשמרו בהצלחה',
    drawboxesfirst: 'תחילה צייר תיבות',
    subcatnotfound: 'קטגוריית המשנה לא נמצאה',
    addsubcatfirst: 'הוסף תתי קטגוריה',
    brandssavedsuccess: 'מותגים נשמרו בהצלחה',
    brandnotfound: 'המותג לא נמצא',
    addbrandfirst: 'הוסף מותג תחילה',
    notselectedsubcat: 'לא נבחרה תת קטגוריה',
    notselectedbrand: 'לא נבחר מותג',
    oveflowinglayout: 'אזור פריסה על גדותיו',
    overflowlayoutornoselected: 'אזור פריסה חופף או שאין תת קטגוריה זמינה',

    days: 'ימים',
    weeks: 'שבועות',
    months: 'חודשים',
    years: 'שנים',

    per_day: 'ליום',
    per_week: 'בשבוע',
    per_month: 'לחודש',
    per_year: 'לשנה',

    EDIT_SIMULATE_CATEGORY: 'עריכת  סימולציית קטגוריה',
    EDITALLCATEGORY:
        'סימולטור- בחר תרחיש רצוי ולחץ על כפתור הסימולטור על מנת לצפות בפלנוגרמה',
    PRODUCT_BLOCK_NOT_FOUND: 'בלוק המוצר לא נמצא',
    CANNOT_ADD_TO_BOTTOM: 'לא ניתן להוסיף לתחתית',
    DRAW_BLOCK: 'צייר בלוק',

    edit_categories: 'ערוך קטגוריות',
    edit_subcategories: 'ערוך קטגוריות משנה',
    edit_brands: 'ערוך מותגים',
    dep_rules: 'הגדרת סינון',
    SELECT_BRANCH: 'בחר סניף',
    PUSH: 'הפעלה',
    EDIT_PORTION: 'רשימת פריטים',

    DEPARTMENT_WARN: 'שים לב',
    WARN: 'כפילויות',
    USED_DEPTS: 'נמצא גם ב',
    WARN_PROD_BEEN_USED: 'אזהרה!, נעשה שימוש במוצר זה בתחומים או מחלקות אחרות',
    OKAY_NOTED: 'סגור',
    NEWPRODUCTS: 'מוצרים חדשים',
    EXTENDED_EDITING: 'עריכה מורחבת',
    MASTER_PRODUCTS: 'מוצרי מאסטר',
    NEW_PRODUCTS: 'מוצרים חדשים',
    DATES_OUT_OF_ODER: 'תאריכים ללא מכר',
    DATES_ORDER: 'הזמנת תאריך',
    IN_ORDER: 'בסדר',
    NO_ISSUE: 'אין בעיה',
    CURRENT_BRANCH_LAST_SYNC: 'תאריך סנכרון אחרון - סניף',
    ALL_BRANCH_LASET_SYNC: 'תאריך סנכרון אחרון - רשת',
    ISSUES: 'תקלות',
    SALEDATA_MISSINGIN: 'חסרים נתוני מכירה',
    REVERSE_SALE_FILE: 'שחזור קובץ מכירה',
    SURE_REVERSE_THIS_SALE: 'האם אתה בטוח?',
    THIS_WILL_REVERSE_SYNC_LOG_FILE:
        'פעולה זו תהפוך את קובץ המכירה לגרסה הקודמת',
    sale_log_warnings: 'התראות נתוני מכר',
    REALY_SURE_REVERSE_THIS_SALE: 'האם אתה בטוח שברצונך להפוך?',

    ARCHIVE: 'ארכיון',
    DATA: 'תקופה',
    SEND_TO_DEP: 'שלח למחלקה',
    NO_DATA: 'אין מידע',

    MP_DEPSEL_TITLE: 'זו הפלנוגרמה הראשית שלך',
    sale_start_date: 'תאריך תחילת המכירה',
    sale_end_date: 'תאריך סיום המכירה',
    open_subcategory: 'ערוך תתי קטגוריה',
    enable_draw: 'הקצאת שטח',
    open_brand: 'ערוך מותגים',
    open_products: 'צפה ברשימת המוצרים',
    cannot_resize_minimum_brands:
        'לא ניתן לשנות גודל מינימלי מאשר מותגים שנוספו',
    MANUALY_ENTER_FIELD_COUNT: 'הזן ידנית את ספירת השדות',
    MANUALY_ENTER_FIELD_COUNT_description:
        'לסניף שנבחר אין שדות לדמות. אנא הזן את כמות ומבנה השדות הנדרש',
    OKAY: 'בסדר',
    percentage: 'אחוזים',
    fields: 'שדות',
    shelves: 'מדפים',
    dynamic_search: 'בחר מרשימה או הקלד',
    data_from_cashiers: 'נתוני קופה ',

    SUCCESSFULLY_PUSHED_MP_TO_BRANCH: 'הפלנוגרמה ירדה בהצלחה',
    MP_PUSH_FAIL: 'שגיאה בהורדת הפלנוגרמה',
    CONFIRM_TO_PUSH: 'להוריד את הפלנוגרמה לסניף?',
    THIS_SIMULATION_IS_GOING_TO_PUSH_TO_BRANCH: 'הורדת הסימולציה לסניף',

    CUT_ITEMS_AVAILABLE: 'פריטים חתוכים זמינים',
    NO_CUT_ITEMS: 'אין פריטים חתוכים זמינים',
    CUT_NO: 'חתך מס',
    CANNOT_CHANGE_BRANDS_AVAILABLE:
        'לא ניתן לשנות, קופסאות מותג זמינות בתיבה של תתי קטגוריה',
    CANNOT_CUT_BRANDS_AVAILABLE:
        'לא ניתן לחתוך, קופסאות מותג זמינות בתוך קופסת תת קטגוריה',
    CUT_LIST_WILL_BE_DELETED: 'קופסאות חתוכות יוסרו, האם אתה בטוח שתמשיך?',

    archived_type: 'סוג בארכיון',
    ARCHIVEDD: 'בארכיון',
    NOT_ARCHIVED: 'לא בארכיון',

    MP_CHART_TITLES: { space: 'שטח', sales: 'פדיון', profit: 'רווח' },
    FILTER_BY_DATE: 'טווח תאריכים לניתוח',
    FILTER_ITEMS: {
        title1: 'בחר טווח תאריכים',
        startdate: 'תאריך התחלה',
        enddate: 'תאריך סיום',
        buttontitle: 'Filter',
    },
    CHART_MSG: {
        no_cat: 'אין נתוני קטגוריה זמינים',
        no_subcat: 'אין נתוני תת קטגוריה זמינים',
        no_brand: 'אין נתוני מותג זמינים',
        no_rule: 'אין נתוני כללים זמינים',
    },
    data: 'נתונים',
    rules: 'כללים',

    close_shelvedrawview_first: 'סגור מדפים בחר תצוגה תחילה',
    close_rulesview_first: 'סגור תחילה את תצוגת עדכון הכללים',
    unallocated: 'לא מוקצה',
    select_eye_level_to_continue: 'בחר ב׳גובה העיניים׳ כדי להמשיך',
    no_field_rules_available: 'שדה לא נוספו כללים זמינים',
    no_shelves_rules_available: 'מדפים לא זמינים כללים נבחרים',
    shelf_is_in_othercategory: 'המדף נמצא בקטגוריה אחרת',
    cannot_change_morethan_100: 'לא ניתן לשנות יותר מ-100%',
    updatefield_details: 'עדכן שדה קטגוריה',
    NO_CONTENT_FOUND: 'לא נמצא תוכן',
    EYE_LEVEL: 'גובה העין',
    ON_TOP: 'דל מכר',
    cannot_redirect_without_boxes:
        'לא ניתן להפנות מחדש תת קטגוריה ללא תיבות תת קטגוריות',
    SELECT_RULE_FIELD: 'בחר שדה כלל',
    REMOVE_FIELD_FROM_CATEGORY: 'הסר את יחידת התצוגה מהקטגוריה',
    SURE_TO_REMOVE_FIELD_FROM_CATEGORY:
        'האם אתה בטוח להסיר את יחידת התצוגה הזו מקטגוריה זו?',
    shelf_is_already_assign_to_otherrect: 'המדף כבר מוקצה לחלק אחר',
    shelf_is_contain_subcategories: 'המדף מכיל קטגוריות משנה',
    NO_PRODUCTS_AVAILABLE: 'אין מוצרים זמינים',
    no_selected_contain_shelfs_available: 'לא נבחרו מדפים זמינים',
    FACING: 'שטח',
    IS_MANDATORY: ' חובה',
    PRODUCT_NEEDS_TOBE_MVP:
        'המוצר חייב להיות מוצרים מובילים בכדי להיות מוגדר ׳מוצר חובה׳',
    BRAND_PRODS_UPDATED: 'פרטי מוצרי המותג עודכנו בהצלחה',
    PRODUCT_NOT_FOUND: 'פרטי המוצר לא נמצאו',
    cannot_change_mandatory_to_normal:
        'לא ניתן לשנות את דירוג ׳מוצר חובה׳ לדירוג ׳מוצר רגיל׳',
    cannot_change_normal_to_mandatory:
        'לא ניתן לשנות את דירוג ׳מוצר רגיל׳ לדירוג ׳מוצר חובה׳',
    cannot_change_hastags_to_normal:
        'לא ניתן לשנות את דירוג ׳מוצר תג׳ לדירוג ׳מוצר רגיל׳',
    cannot_change_normal_to_hastags:
        'לא ניתן לשנות את דירוג ׳מוצר רגיל׳ לתיוג ׳דירוג המוצר׳',
    multiple_category_boxes_available: 'תיבות קטגוריות מרובות זמינות',
    subcategories_available_in_rects: 'תת קטגוריות זמינות בתיבות קטגוריות',
    NO_CHANGES_AVAILABLE: 'אין שינויים זמינים',
    NO_ARCHIVE_ITEMS: 'אין פריטי ארכיון זמינים',
    ARCHIVE_SELECTED_PRODS: 'ארכיון מוצרים נבחרים',
    ARE_YOU_SURE_TO_ARCHIVE: 'האם אתה בטוח שהעברת את המוצרים הנבחרים לארכיון?',
    SAVE_PRODUCT_BEFORE_EDIT:
        'שמור שינויים נוכחיים במוצר לפני עריכת פרטי המוצר',
    CHANGE_NOTBE_SAVED: 'ייתכן ששינויים שביצעת לא יישמרו',
    USED_AS_RULE_IN: 'משמש ככלל ב',
    LEVEL: '',
    THIS_BRAND_IS_ALREADY_ADDED_RULE: 'מותג זה כבר נוסף כחוק',
    THIS_SCAT_IS_ALREADY_ADDED_RULE: 'תת קטגוריה זו כבר נוספה ככלל ב',
    PLACES: 'מקומות',

    NEW_PROD_ARCHIVE_CONFIRM: 'האם אתה בטוח שהעברת מוצר זה לארכיון?',
    NEW_PROD_SENDTODEP_CONFIRM:
        'האם אתה בטוח שאתה שולח את המוצר הזה לפלנוגרמת ׳מאסטר׳?',
    NEW_PROD_RESTORE_CONFIRM: 'האם אתה בטוח שברצונך לשחזר את המוצר הזה?',
    CATELOG_IMP_NAME: 'שם מקור',

    PROD_SAVE_CONFIRM_SENTTODEP:
        'מוצר זה יישלח אל הפלנוגרמות הראשית. האם אתה בטוח שתמשיך?',
    PROD_SAVE_CONFIRM_NOT_SENTTODEP:
        'מוצר זה לא יישלח לפלנוגרמות הראשית. האם אתה בטוח שתמשיך?',
    isarchived: 'נמצא בארכיון',
    productedit_warning: 'אזהרות עריכת מוצר',
    export_to_vmp: 'עדכן פלנוגרמה ראשית',
    SIMULATE_CONVERT_VMP_CONFIRM: 'עדכון פלנוגרמה ראשית',
    SIMULATE_CONVERT_VMP_CONFIRM_MSG:
        'הפעולה תשמור את השינויים שביצעת לכלל הרשת, אתה בטוח?',
    SIMULATE_SAVE_CONFIRM: 'שמירת שינויים לפלנוגרה בודדת',
    SIMULATE_SAVE_CONFIRM_MSG:
        'השינויים שביצעת ישמרו רק לסימולציה הנוכחית , האם אתה בטוח?',
    NOT_SIM_PRODUCTS: 'נשארו בחוץ',
    CATEGORY_DETAILS: 'פרטי קטגוריה',
    SUB_CATEGORY_DETAILS: 'פרטי תת קטגוריה',
    NO_RULES_AVAILABLE: 'אין חוקים זמינים',
    RULE_PER: 'כלל%',
    SIMULATED_PER: 'בפועל%',
    SIMULATE_SAVE_SUCCESS: 'סימולציית קטגוריית נשמרה בהצלחה',
    NO_CLIPBOARD_ITEMS: 'לא נמצאו פריטים בלוח',

    NO_REASON: 'בלי סיבה',
    HEIGHT_NOT_ENOUGH: 'מוצר גבוה מדי',
    MIN_QTY: 'לא עומד בכמות מינמלית',
    NO_TAG_MATCHING: 'אין התאמת תגים',
    NOT_ENOGH_SPACE: 'לא נשאר מקום',

    PLS_ENTER_VALID_PERCENTAGE: 'נא להזין ערך אחוז חוקי',

    CATELOGUE_IMPORT: 'בקרת קטלוג',
    CATELOGUE_FILTERS: {
        type: 'רמה',
        issueType: 'סוג ',
        status: 'סטטוס',
        from: 'מתאריך',
        end: 'עד תאריך',
        todate: 'עד היום',
        search: 'חיפוש',
        reset: 'איפוס',
        colDetails: 'תיאור',
        resolve: 'טופל',
        approve: 'אישור',
        unresolve: 'לא פתור',
        dateReported: 'תאריך דיווח',
        issue: 'נושא',
        filename: 'שם קובץ',
        created: 'תאריך יצירה',
        errortype: 'סוג שגיאה',
        resolved: 'פתור',
    },
    FILE_ERROR_LOG: 'שגיאות בסנכרון קטלוג',
    CATELOGUE_DASHBOARD: {
        allCount: 'סך הכל',
        confirmPending: 'ממתין לאישור',
        needUserInput: 'דורש התייחסות',
        planigoCompleted: 'הושלם',
        allCompleted: 'הכל שלם',
        fileCurruptedCount: 'קבצים פגומים',
        dataErrorCount: 'שגיאות נתונים',
        saveErrorCount: 'שמור שגיאות',
    },
    DEPARTMENT_IMPLEMENT_DASHBOARD: {
        failed: 'נִכשָׁל',
        inProcess: 'בתהליך',
        inQueue: 'בתור',
        planigoCompleted: 'הושלם',
    },
    DepartmentImplementLogStatus: {
        all: 'את כל',
        failed: 'נִכשָׁל',
        ongoing: 'בתהליך',
        in_queue: 'בתור',
        completed: 'הושלם',
    },

    DEPARTMENT_IMPLEMENT_FILTERS: {
        status: 'סטטוס',
        colDetails: 'תיאור',
        storeName: 'סניפים',
        department: 'מחלקה',
        masterPlanogram: 'פלנוגרמה ראשית',
        createdDate: 'תאריך נוצר'
    },
    CATELOGUE_ERR_MARK_ITEMS: 'אנא בחר פריט אחד או יותר',
    CATELOGUE_ARE_YOU_SURE_CONT_APPROVE_MSG:
        'האם אתה בטוח שברצונך להמשיך לאשר את הפריטים המסומנים?',
    CATELOGUE_ARE_YOU_SURE_CONT_RESOLVE_MSG:
        'האם אתה בטוח שברצונך להמשיך לפתור את הפריטים המסומנים?',
    CATELOGUE_SYNC_MSG: 'האם אתה בטוח שתסנכרן?',
    LOG_DETAILS: 'פרטים נוספים',

    CatelogImportLogTypes: {
        All: 'את כל',
        Department: 'מחלקה',
        Category: 'קטגוריה',
        SubCategory: 'תת קטגוריה',
        Brand: 'מותג',
        Supplier: 'ספק',
        Product: 'מוצר',
        Other: 'אַחֵר',
    },
    CatelogImportIssueTypes: {
        PlanigoMapping: 'המלצות',
        ArigoMapping: ' מיפוי אריגו',
        NotEnoughData: 'אין  נתונים',
        NoIssue: 'תקין',
        Other: 'אחר',
        MappingIssue: 'ללא שיוך',
    },
    CatelogImportLogStatus: {
        All: 'הכל',
        ConfirmPending: 'ממתין לאישור',
        NeedUserInput: 'ממתין לטיפול',
        PlanigoCompleted: 'הושלם',
        AllCompleted: 'הכל הושלם',
        Other: 'אחר',
    },

    FileImportErrorLogType: {
        None: 'אף אחד',
        All: 'הכל',
        FileCurrupted: 'הקובץ פגום',
        DataError: 'שגיאת מידע',
        SaveError: 'שגיאת שמירה',
        Other: 'מיפוי',
    },

    FileImportErrorLogFilterTypes: {
        All: 'הכל',
        Department: 'מחלקה',
        Category: 'קטגוריה',
        SubCategory: 'תת קטגוריה',
        Brand: 'מותג',
        Supplier: 'ספק',
        Product: 'מוצר',
        Other: 'אחר',
    },
    RESOLVE_NOTE: 'פתור הערה',
    NEW_SUB_CAT: 'תת קטגוריה חדשה ',
    NEW_BRAND: 'מותג חדש',
    NEW_SUPPLIER: 'ספק חדש',
    SUGGESTED_NAME: 'שם מוצע',
    SUGGESTED_DEPARTMENT: 'מחלקה מוצעת',
    SUGGESTED_CATEGORY: 'קטגוריה מוצעת',
    SUGGESTED_SUPPLIER: 'ספק מומלץ',
    PROD_SUGGESTED_BRAND: 'מותג מוצע',
    PROD_SUGGESTED_SUBCAT: 'תת קטגוריה מוצעת',
    SUGGESTION_NOTE: {
        first: 'נא לאשר או להתעלם בזהירות.',
        second: 'אם תאשר, המידע המוצע יישמר ברשומה הרלוונטית.',
        third: 'אם תתעלם מההמלצה, המידע לא יעודכן.',
    },
    APPROVE_CHANGES_NOTAVL_MSG:
        'לא יתקבלו שינויים, אם תמשיך, הרשומה תישמר כפי שהיא, האם אתה בטוח ?',
    APPROVE_CHANGES_AVL_MSG:
        'ישנם שינויים מקובלים, אם תמשיך, זה יעדכן את נתוני הרשומה, האם אתה בטוח  ?',
    CATELOG_LOG_EX_LABLES: {
        department: 'המחלקה הנוכחית',
        category: 'קטגוריה נוכחית',
        supplier: 'ספק נוכחי',
        brand: 'מותג נוכחי',
        subcategory: 'תת קטגוריה נוכחית',
        name: 'שם נוכחי',
    },
    CONFLICT_TITLE: 'קונפליקטים זמינים',

    CATELOG_NEW_INFO_LABELS: {
        new_scat_title: 'תת קטגוריה חדשה',
        subcat: 'יוצר תת-קטגוריה חדשה',
        subcat_already_exist_msg:
            'אם כבר קיים, אנא בחר תת-קטגוריה קיימת מהרשימה.',

        new_brand_title: 'מותג חדש',
        brand: 'יוצר מותג חדש',
        brand_already_exist_msg: 'אם כבר קיים, אנא בחר מותג קיים מהרשימה.',

        new_sup_title: 'ספק חדש',
        supplier: 'יוצר ספק חדש',
        sup_already_exist_msg: 'אם כבר קיים, אנא בחר ספק קיים מהרשימה.',
    },
    STORE_PRODUCTS: 'מניעת חוסרים במדף',
    sensitiveValue: 'רגישות',
    updateSensitiveValue: 'עדכון רגישות',
    actualStoreState: ' מצב אמת',
    Add_Store_Product: 'הוסף מוצר',
    store_products_currently_view_txt: ' מוצרים למעקב בסניף ',

    CONFIRM_TO_CHANGE_BRANCH_MANAGER: 'לאשר להחליף מנהל סניף',
    CONFIRM_TO_CHANGE_BRANCH_MANAGER_DETAILS:
        'ניתן להשתמש במשתמש זה בסניף אחר. האם אתה בטוח שתשנה את המשתמש הזה כמנהל סניף?',
    CONFIRM_TO_DELETE_BRANCH: 'אשר למחיקת סניף',
    CONFIRM_TO_DELETE_BRANCH_DETAILS: 'האם אתה בטוח שתמחק את הסניף הזה?',

    SNAPSHOT_AVL_MODAL_TITLE: 'זמינות צילומי מצב',
    SNAPSHOT_AVL_MODAL_CHECKBOX_LABEL: 'עדכן תמונות',

    SOME_SUBCAT_NOBOXES:
        'לחלק מקטגוריות המשנה לא הוקצה שטח למרות שנבחרו - יש למחוק אותם או להקצות להם מקום',
    SOME_BRAND_NOBOXES:
        'לחלק מהמותגים  לא הוקצה שטח למרות שנבחרו - יש למחוק אותם או להקצות להם מקום',
    NO_NAVIGATION_ITEMS_FOUND:
        'החל במיקום הפריטים מרשימת הפרטים בכדי להתחיל לראות נתונים',
    CLEAR_SNAPSHOTS_MSG:
        ' הפלנוגרמה שמופיעה שונה מהמקור לחץ על כפתור זה כדי למחוק את השינויים ולאפס אותה',
    CLEAR_SNAPSHOTS: ' פלנוגרמה שנערכה',
    CONFIRM_TO_CLEAR_SNAPSHOT: 'איפוס בלתי הפיך של הפלנוגרמה',
    CONFIRM_TO_CLEAR_SNAPSHOT_MSG: 'האם אתה בטוח שברצונך לאפס את הפלנוגרמה?',
    DROP_PROD_FROM_ONTOPNEW: 'זרוק מוצרים בסעיף זה ממוכר נמוך או חדש',
    DROP_PROD_FROM_MVPNEW: 'שחרר מוצרים למדור זה מ-מוצרים מובילים או חדש',
    SNAPSHOT_AFFECT_MSG: 'משימה זו תשפיע על תמונת המצב שנשמרה.',
    SNAPSHOT_EXTRA_MSG: '(זה רק יאפס את דרגות המוצר.)',

    CLICK_TO_EDIT: 'לחץ כדי לערוך שטח',
    Full_Screen: 'מסך מלא',
    REQUIRED: 'נדרש',
    DRAWED: 'צייר',
    SAVE_FIELD_INRULE: 'שמור שדה בכלל',
    cannot_remove_middle_shelf: 'אין ליצור רווח בין היררכיות דומות',
    cannot_select_between_othershelfs: 'אין ליצור רווח בין היררכיות דומות',
    contains_shelfs_between_othershelfs: 'מכיל מדפים בין מדפים אחרים זמינים',

    rect_draw: 'הקצאת שטח',
    youre_nowediting: 'אתה עורך כעת -',
    items: 'פריטים',

    unallocated_space: 'שטח נותר',
    imageSource: 'מקור תמונה',
    iscombinefilter: 'האם סינון שילוב',
    range: 'טווח',
    missingtypes: {
        dimension_missing: 'ללא מידות ',
        image_missing: 'ללא תמונה',
        brand_missing: 'חסר מותג',
        subcategory_missing: 'תת קטגוריה חסרה',
        category_missing: 'קטגוריה חסרה',
        department_missing: 'מחלקה חסרה',
        entire_hierarchy_missing: 'ללא הררכיה',
    },
    // MVP:"עדיפות ראשונה",
    MVP: 'מוצרים מובילים',
    MVPnOPNTOP: 'עדיפות ראשונה ורשימת המתנה',
    ONTOP: 'דל מכר',
    MM: 'mm',
    MAX: 'מקסימום',
    //MIN:"מינימום",
    MIN: 'מִינִימוּם',
    NotMVPONTOP: 'לא מוצר מוביל ולא מוכר נמוך',
    SHOW_RESULTS: 'תוצאות',

    DATA_MISSING: 'חסרים ',
    FULL_DATA: 'מלאים',
    completeStatus: 'נתוני מוצר',
    custom: 'כללי',
    current_version: 'גרסה נוכחית',
    required_validmvp:
        'אחוז מוצרים מובילים לא יכול להיות פחות מ-0 או יותר מ-100',
    per_morethan_fiveper: 'אחוז יותר מ-5% קטגוריות לא נמצאו',
    fields_notadded_categories: 'שדות לא נוספו קטגוריות זמינות',

    editDepartment: 'עריכת המחלקה',
    is_hidden: 'מוסתר',
    parent_department: 'מחלקת הורים',
    parent_category: 'קטגורית הורים',

    RESOLVE_HIERACHY: 'השלמת נתונים חסרים',
    IMPORT_NAME: 'שם ייבוא: ',
    RESOLVED_DATE: 'תאריך שיוך מחדש',
    ARE_YOU_SURE_TO_RESET: 'לאפס את השינויים שביצעת?',
    ARE_YOU_SURE_TO_RESOLVE: 'לאשר את התיקונים שביצעת?',

    is_hiddendepartment: 'ללא מחלקות מוסתרות',
    hidden_department: 'האם מחלקה נסתרת',
    hidden: 'מוסתר',
    filter_datetype: {
        range: 'תאריך הקמה',
        count: 'נמכר לאחרונה',
        backdays: 'בחר כמות ימים',
    },
    PLEASE_SET_VALID_DATE_COUNT: 'נא להגדיר ספירת תאריכים תקפה!',
    multiselect: {
        all: 'בחר הכל',
        unselect: 'בטל את הבחירה בהכל',
        allaction: 'כל פעולה',
        sendtodep: 'שלח למחלקה הכל',
        archive: 'ארכיון הכל',
        restore: 'שחזר הכל',
        sendall: 'שלח הכל',
        sendselected: 'שלח נבחרים',
        archiveall: 'ארכיון הכל',
        archiveselected: 'ארכיון נבחר',
        restoreAll: 'שחזר הכל',
        restoreSelected: 'שחזר שנבחר',
        onlythisprod: 'רק מוצר זה',
    },
    product_type: 'סינון מהיר',
    product_source: 'מקור המוצר',
    Other: 'אחר',

    PRODS_SENDTODEP_CONFIRM:
        'האם אתה בטוח שאתה שולח את המוצר הזה לפלנוגרמת המאסטר?',
    PRODS_RESTORE_CONFIRM: 'האם אתה בטוח שתשחזר את המוצרים האלה?',
    SENT_DEPPRODS: 'נשלח למוצרי המחלקה',
    ARCHIVE_PRODS: 'מוצרים בארכיון',
    RESTORED_PRODS: 'מוצרים משוחזרים',
    RESPONSE_STAT: { updated: 'מעודכן', failed: 'נכשל' },

    THIS_ACTION_WILL_DELETE_THIS_DEPARTMENT_SURE:
        'פעולה זו תמחק את המחלקה הזו ואת הקטגוריות ותתי הקטגוריות שלה. האם אתה בטוח?',
    THIS_ACTION_WILL_DELETE_THIS_CATEGORY_SURE:
        'פעולה זו תמחק את הקטגוריה הזו ואת תת הקטגוריות שלה. האם אתה בטוח?',
    SELECTTO_CHANGE_DEP: 'בחר כדי לשנות מחלקה',
    SELECT_TO_CHANGE_CAT: 'בחר כדי לשנות קטגוריה',
    suretochangeparent: 'האם אתה בטוח שתחליף הורה?',
    THIS_ACTION_WILL_CHANGE_PARENT_CURRENT_TO_NEW:
        'פעולה זו תשנה את ההורה הנוכחי לחדש',
    THIS_ACTION_WILL_DELETE_THIS_SUBCATEGORY_SURE:
        'פעולה זו תמחק את קטגוריית המשנה הזו. האם אתה בטוח?',

    NO_SELECTED_PRODUCTS: 'אין מוצרים נבחרים זמינים',

    WARNING_S: 'אזהרה!',
    THIS_CHANGES_MAYBE_CANNOT_BACKTO_VMP:
        'ייתכן שלא ניתן להמיר שינויים בסימולציה שאתה הולך לעשות כאן ל-VMP.',

    MANUALLY_FIXED: 'תוקן באופן ידני',
    AUTO_PROCESSED: 'עיבוד אוטומטי',
    SELECT_PRODUCTS: 'בחר מוצרים',
    resolvelist: 'פתור רשימה',
    allissues_noprods: 'אנא הוסף מוצרים לרשימה כדי להמשיך',
    noprods_issuesavalable:
        'אין מוצרים שנוספו פותרים פריטים זמינים, אלה לא יישלחו לעדכון',

    CURRENTLY_OPEN_CONFLICTS: 'קונפליקט פתוח',
    SELECTED_PRODUCT_COUNT_TXT: ' מוצרים שנבחרו',
    COLLAPSE_ALL: 'למוטט הכל',
    HIDE_ALL: 'הסתר הכל',

    ARCHIVED: 'בארכיון',
    SENDTODEP: 'נשלח למחלקה',
    RESTORED: 'משוחזר',

    TAKESOMETIMETO_COMPLETE:
        'זה ייקח קצת כדי להשלים את המשימה הזו. אנו נודיע לך כשהיא תושלם',
    SELECTED_PRODS_AVAILABLE:
        'רק מוצר זה יחיל את השינוי הזה, שאר המוצרים לא יושפעו',

    PRINT_SIMULATION: 'ייצוא פלנוגרמה',

    WATCH: 'מוצרים',
    MAPPING: 'מיפוי',
    GOT_IT: 'מצוין!',
    no_sales: 'אין מכירות',
    below_min_rev: 'מתחת להכנסה המינימלית',
    no_sales_in_selected_date_range: 'אין מכירות לתאריך',

    COLORS_NOT_AVAILABLE: 'אין צבעים זמינים',
    PICK_CUSTOM_COLOR_TXT: 'בחר צבע מותאם אישית',
    USED_COLORS: 'צבעים בשימוש',
    REASON: 'סיבה',
    CHANGE_PARENT: 'שנה היררכיית אם',
    PAGE: 'עמוד',
    Destributer: 'ספק',
    Shelf_unit_no: 'מספר יחידת מדף',
    Field_unit_no: 'מספר יחידת שדה',
    NO_LONGER_VALID: 'לא תקף יותר',
    AFFETCED_CHANGES: 'שינויים שהושפעו',
    isPrioratizeUserPercentage: 'אחוז קבוע',
    depcatcannot_be_empty: 'אנא בחר קטגוריה כדי להחליף!',
    please_select_a_dep_to_switch: 'אנא בחר מחלקה להחליף!',

    PROD_DIMENTIONS_NOTAVAILABLE: 'מידות המוצר לא נוספו כהלכה',
    NONUSED_PROD_PRINT: 'ייצוא מוצרים שאינם בשימוש',
    BULKQR_PRINT: 'הדפסת QR בתפזורת',
    BULKQRPRINT_MODAL: 'הדפסת QR בשדה בתפזורת',
    ALL_LAYOUT: 'פריסה שלמה',
    DEPARTMENT_WISE: 'רמת המחלקה',
    EXPORT: 'ייצוא',
    FIELDS_AVAILABLE: 'שדות זמינים',
    NOFIELDS_AVAILABLE: 'אין שדות זמינים',

    IS_stackable: 'ניתן לערום',
    maxstackablecount: 'ספירת ערימה מקסימלית',
    Change_Stackable_state_of_all_products: 'שנה מצב הערימה של כל המוצרים',
    STACKABLE: 'ניתן לערום',
    NONSTACKABLE: 'לא ניתן לערום',
    MARK_PRODUCTS: 'סמן מוצרים',
    CHANGE_ALL_PRODUCTS_TO_NEW_MARKED_STATE: 'שנה את כל המוצרים למצב סימן חדש',
    THIS_WILL_AFFECT: 'זה ישפיע ',
    PRODUCTS_ARE_YOU_SURE: ' מוצרים. האם אתה בטוח?',

    ShowStackable: 'הצג ׳ניתן לערום׳',
    HideStackable: 'הסתר ׳ניתן לערום׳',
    Mark_stackable_products: 'אין שינויים במוצרים הניתנים לערום של סימון!',
    Max_Stack_count_zero_or_empty_means_no_stack_count:
        'השאר שדה זה ריק או 0 אם אינך רוצה להגביל את ספירת הערימה',
    There_are_unsaved_changes: 'יש שינויים שלא נשמרו',
    Your_last_changes_not_saved_How_do_you_want_to_continue_print:
        'השינויים האחרונים שלך לא נשמרו. האם ברצונך להמשיך להדפיס?',
    SavenPrint: 'שמור והדפס',
    Print_Without_save: 'הדפס ללא שמירה',
    Last_changes_not_saved_how_do_you_want_to_continue_print:
        'השינויים האחרונים שלך לא נשמרו. האם ברצונך להמשיך להדפיס?',
    DISPLAY_UNIT: 'יחידת תצוגה',
    NO_FIELDS_FOUND_TOPRINT: 'אין שדות זמינים להדפסה',
    NO_SELECTED_FILES: 'אין קבצים נבחרים זמינים',
    PROD_SOURCETYPES: {
        Manual: 'מדריך ל',
        POS: 'קופה',
        Arigo: 'Arigo',
        GS1: 'GS1',
        Other: 'אַחֵר',
    },
    IS_BLOCK: 'חסום',
    BLOCK_UNTIL_DATE: 'חסום עד תאריך',
    SELECT_DATE: 'בחר תאריך',
    UPLOAD_PRODUCT_ACTION: 'העלה נתוני מוצר',
    Select_import_Product_excel_files: 'בחר וייבא קבצי Excel של מוצר',
    FILE_SELECTED: 'הקובץ שנבחר',
    Click_and_select_excel_file: 'לחץ ובחר בקובץ אקסל',
    OR: 'או',
    Drag_and_drop_excel_file_here: 'גרור ושחרר כאן קובץ אקסל',
    Excel_product_list: 'רשימת מוצרים של אקסל',
    Block_Products: 'מוצרי בלוק',
    Archive_Products: 'מוצרי ארכיון',
    Previews_selected_file_barcodes: 'תצוגה מקדימה של ברקודים של קבצים נבחרים',
    No_file_selected_yet: 'עדיין לא נבחר קובץ',
    Activate_Confirmed_Planograms: 'הפעל פלנוגרמות מאושרות',
    Select_from_available_confirmed_stores_list_and_activate:
        'בחר מרשימת החנויות להפעלה מהירה ',
    No_Data_Available: 'אין נתונים זמינים',
    Search_stores: 'חיפוש חנות',
    Successfully_planograms_activated: 'הפלנוגרמות הופעלו בהצלחה',
    click_at_least_one_store: 'לחץ על חנות אחת לפחות',

    GS1_Override: 'חיפוש מידע חדש',
    DIMENSION: 'מידות',
    Image_Available: 'תמונה זמינה',
    New_Image_Available: 'תמונה חדשה זמינה',
    No_Image_Available: 'אין תמונה זמינה',
    Current: 'נוכחי',
    THIS_PROD_HAVENT_GS1_UPDATE_AVAILABLE: 'למוצר זה אין פרטי GS1 זמינים.',
    UPDATE_ALL: 'עדכן הכל',
    UPDATE_ALL_DIMENSION: 'עדכן את כל המימדים',

    ACTIVE: 'פעיל',
    CONNECTED: 'מחוברים',
    DISCONNECTED: 'מנותקים',
    IMPLEMENT: 'הפעלה',
    ACTIVATE_OVERRIDE_TOCONTINUE: 'תחילה הפעל את אפשרות העקיפה',
    Create_new_AUI_version: 'נעילת גרסא והעברה להטמעה',
    You_have_unsaved_changes_please_save_before_continue:
        'יש לך שינויים שלא נשמרו. אנא שמור לפני שתמשיך',
    you_sure: 'האם אתה בטוח?',
    this_will_create_new_aui_version:
        'בחרת להעביר את הגרסא להטמעה בסניפי הרשת -פעולה זו תנעל את הגרסא הזו לשינויים בעתיד',
    You_are_already_have_planogram_version: 'כבר יש לך גרסת פלנוגרמה',
    Please_select_what_to_do: 'אנא בחר מה לעשות',
    Add_this_one_also: 'הוסף גם את זה',
    Keep_as_a_draft: 'שמור כטיוטה',

    TOCONNECT_AND_OVERIDE: 'כדי להתחבר ולעקוף את הגרסה הנוכחית',
    CONTINUE_WITHOUT_THEM: 'המשך בלעדיהם',
    BACK_TO_EDIT: 'חזרה לעריכה',
    YOURE_ABOUT_TO_IMPLEMENT: 'אתה עומד ליישם פלנוגרמה.',
    THIS: 'זה',
    NOT_CONNECTED:
        'אלו מחוברים לגרסא שונה מזו שאתה מוריד לחץ על הלינק כדי לחברם לפלנוגרמה החדשה',
    LETS_GO: 'הבא',
    NO_AUI_VERSIONS: 'לא קיימות גרסאות להטמעה',
    MORE_TAGS: 'תגיות נוספות',
    THIS_SELECTION_CONTAINS_TAGED: 'בחירה זו מכילה סניפים מתויגים',
    DO_YOU_WANT_TO_INGNORE: 'אתה רוצה להתעלם מזה?',
    SEVERAL_DISCONNECTED_STORES: 'יש כמה חנויות מנותקות בתרחיש הזה',
    IMPLEMENT_WILL_OVERRIDE: 'יישום זה ידרוס את הפלנוגרמה של החנות שנבחרה',
    IMPLEMENT_CANNOT_CONTINUE:
        ' באחד או יותר מהסניפים יש פיצול של הקטגורייה לשני אזורים שונים ',
    PLEASE_ASSIGN_CAT_LOCATION: 'אצטרך את עזרתך במיקומם',
    ITS_OKAY: 'פרטים נוספים',
    BACK_TO_SIM: 'חזרה לסימולציה',
    ALL_BRANCHES_CONNECTED: 'כל הסניפים מחוברים',
    FIXED_PER: 'שטח מדף',
    SALE_CYCLE: 'נקודת מבט לוגיסטית',
    NO_TAG: 'ללא תג',
    FIELD: 'שדה',
    DUPLICATE_VMP: 'שכפול VMP',
    VIEW_VMP: 'צפה ב-VMP',
    VIEW_AUI: 'צפה ב-AUI',
    NO_ACTIVE_PLANOGRAMS: 'לא נמצאו פלנוגרמות פעילות',
    VMP_AUI_LOCKED: 'זה רק במצב תצוגה. (גרסת VMP זו מומרת ל-AUI)',
    NO_VERSIONS_AVAILABLE: 'אין גרסאות זמינות',
    STARTEND_DATES_REQUIRED: 'נדרשים תאריכי התחלה וסיום',
    ENDDATE_LOWERTHAN_STARTDATE: 'תאריך ההתחלה צריך להיות לפני תאריך הסיום',
    ENTER_DEPT_RULES_UPDATE: 'הזן את כללי המחלקה ועדכן',
    IMPLEMENT_PROCESS_STARTED:
        ', יש כמה דברים שאני צריך לארגן לפני בכדי לעגכן את הפלנוגרמה סניפים הורדת הפלנוגרמות החדשות יצאה לדרך',
    NOTIFY_AFTER_COMPLETE: 'אני אדאג לעדכן אותך בהתראה ברגע שאסיים',

    FRESH_MODAL: 'הוקמה גרסת פלנוגרמה חדשה',
    NEW_VERSION_EDIT: 'גרסא חדשה נוצרה- יש לתת שם ולהגדיר כללים מחדש ',
    Edit_cat: 'ערוך קטגוריה',
    MINIMIZE_Screen: 'מזעור מסך',
    PLEASE_SAVE_BEFORE_FIRST: 'נא לשמור קודם',

    Reset_Changes: 'לאפס שינויים?',
    this_will_reset_your_unsaved_actions_are_you_sure_to_continue:
        'פעולה זו תאפס את השינויים שלא נשמרו. האם אתה בטוח להמשיך?',
    MP_IMPLEMENTATION: 'יישום פלנוגרמת מאסטר',
    NO_NOTIFICATIONS_FOUND: 'לא נמצאו התראות',
    ENTER_PASSWORD_PLS: 'הזן סיסמה כדי למחוק',

    THIS_IS_YOUR: 'רשימת מחלקות',
    TYPE_HERE_TO_SEARCH: 'הקלד כאן כדי לחפש',
    NO_FIELDS: 'לא הוגדרו שדות',
    WE_NOTICE_THAT_NO_FILLED:
        'סניפים אלו ללא כמות שדות למחלקה הנוכחית,הזן כמות שדות על מנת לעדכן ',
    EDIT_FIELDS_NO: 'ערוך את מספר השדות',
    FOLLOW_DUNIT_WARN:
        'לסניפים הבאים בבחירתך יש יחידות תצוגה חריגות,אנא בחר בפתרונות הרצויים.',
    FIELD_ARRANGEMENT_NOTMATCH: 'בחר הכל או התאם בחירה לפי סניף',
    NO_COUNT_ADDED_FIELDS:
        'ספירת השדות אינה מתעדכנת באף אחת מהחנויות. עדכן את ספירת השדות כדי להמשיך',
    UPDATE_CHANGES: 'הורדת שינויים',
    ARE_YOU_SURE_NOFIELD:
        'האם אתה בטוח שברצונך לעדכן את ספירות השדות של החנות הזו?',
    UNIQUEFIX_BTNS: {
        SAVE: 'שמור עדכונים',
        IGNORE: 'התעלם מהכל',
        FORCE: 'כפה',
        FORCEALL: 'כפה על הכל',
        EXCLUDE: 'דלג',
        EXCLUDEALL: 'דלג על הכל',
    },

    ARE_YOU_SURE_DIFFFIELD: 'האם אתה בטוח שברצונך לעדכן את החנות הזו?',
    ZERO_FIELD_NUMBER_STORES:
        'ניתן להשאיר ב0 סניפים שתרצה להתעלם,ניתן לעדכן זאת בכל שלב ',
    DIFFFIELD: {
        WIDTH: 'מבנה שדה חריג (רוחב, עומק)',
        SHELF: ' שדה חריג (מספר המדפים)',
    },

    LESS_PRD:
        'פחות מ-4 מוצרים מדומים / אין כמות מכירה זמינה לפחות עבור 4 מוצרים',
    TAKE_BACK: 'העתקה לתרחיש אב',
    CONT_TAKE_BACK: 'המשך סימולציה קח בחזרה',
    TACKBACK_SUCCESS: 'העתקה הושלמה בהצלחה',
    DISCONNECTED_SUCCESS: 'התנתק בהצלחה',
    TACKBACK_BLOCKED: 'החזר להיררכיית אם חסומה',

    posCompleteStatus: 'פרטי היררכיה',
    posStatusList: {
        NO_DATA: 'אין מידע',
        HAVING_DATA: 'בעל נתונים',
        INVALID_DATA: 'נתונים לא חוקיים',
        OTHER: 'אחר',
        DATA_ISSUES: 'נתונים עם בעיות',
    },
    Total: 'סה"כ',
    Successfully_products_updated: 'המוצרים עודכנו בהצלחה',
    Excel_file_examples: 'דוגמאות לקבצי אקסל',
    Import_xlsx_files_with_these_formats: 'ייבא קבצי.xlsx עם הפורמטים האלה',
    Template: 'תבנית',
    TakeTime: 'ייקח קצת זמן להשלים את המשימה הזו. אנו נודיע לך כשהיא תושלם.',
    ACTION: 'פעולה',
    product_doesnt_exist: 'המוצר לא קיים',
    Excel_Import_Bulk_Update_Details: 'ייבוא Excel פרטי עדכון בכמות גדולה',

    AISLE: 'מעבר',
    ROTATE_OVERLAPPING_MESSAGE: 'חופפים',

    STORE_WITH_ISSUES: 'חנויות עם בעיות',
    PLEASE_FIX_BELOWITEM:
        'אנא תקן את רשימת החנויות החסרות בקומה ובעיות במנהל החנות כדי להמשיך',
    ISSUESTORE_BTNS: {
        available: 'זמין',
        notavailable: 'לא זמין',
        ignoreall: 'התעלם מהכל',
        retry: 'נסה שוב',
    },
    CAN_BE_CONTINUE_WITHOUT: 'ניתן להמשיך ללא בעיות',
    NO_REPLACED_PRODS_AVAILABLE: 'אין מוצרים מוחלפים זמינים',

    SORT_BY: 'מיין לפי',
    APPLY_TYPES: { ALL: 'הכל', USED: 'בשימוש', SELECTED: 'נבחר' },
    GROUP_PRODS: 'צור קבוצה',
    GROUP_CLOSE: 'ביטול',
    MAKE_GROUP: 'צור קבוצה',
    NO_PRODS_TO_GROUP: 'אין מוצרים זמינים לקבוצה',
    PROD_NOTIFICATIONS: 'הודעות על מוצר',
    any_brand: 'כל מותג',
    any_supplier: 'כל ספק',
    any_heirarchy: 'כל היררכיה',
    any_category: 'כל קטגוריה',
    any_subcategory: 'כל תת קטגוריה',
    any_department: 'כל מחלקה',
    SELECT_MORETHAN_ONEPRODS: 'בחר יותר ממוצר אחד כדי להמשיך',
    SELECT_PRODSTO_CONTINUE: 'בחר מוצרים כדי להמשיך',
    TEST_DAYS: 'ימי מבחן',
    STRGY_TYPES: {
        linear: 'ליניארי',
        replace: 'מוצר ספציפי',
        maxfaceprod: 'פיסינג גבוה',
        minsaleprod: 'מכר נמוך',
        maxfaceminsale: 'שטח מול מכר לא מוצדק',
        attached: 'מצורף',
        block: 'חסום למכר',
    },
    NEWTESTINGPRODS: 'מוצרים חדשים',
    NEWPENDINGPRODS: 'מוצרים חדשים בהמתנה',
    IS_APPLIED: 'מוצרים שטופלו',
    IS_ONGOING: 'מוצרים בתהליך',
    SELECTED_PRODUCTS: 'מוצרים נבחרים',
    SEARCHED_PRODUCTS: 'חיפוש מוצרים',
    NO_NEW_PRODS: 'יש לבחור מוצר להחלפה',
    CHOOSE_FROM_LIST: 'חיפוש מוצר להחלפה',
    UNUSED: 'לא בשימוש',
    SELECT_SELECTOPTION: 'יש לבחור גרסאות לטיפול בכל אחד מהפריטים',
    SELECT_REPLACE_PRODS:
        'לחלק מהפריטים שוגרו בשיטת החלפה לא הוגדר מוצר מחליף ',
    SELECT_FACINGVALUES: 'יש להזין שטח נדרש בכלל המוצרים שסומנו',
    SEARCH_PROD_PLACEHOLDER: 'חיפוש מוצר (שם/ברקוד)',
    GROUPDETAILS_NOT_MATCHING: 'פרטי המוצר אינם תואמים למוצרים אחרים',
    SAVING: 'שומר...',
    CANCEL_SELPRODS: 'בטל מוצרים מיושמים',
    PLEASE_SELECT_CANCELOPTION: 'אנא בחר אפשרות ביטול כדי להמשיך',
    singleprodcancel: {
        old: 'בטל חזרה ל׳מצב המוצר הישן׳',
        newpending: 'בטל חזרה ל׳מצב חדש׳ בהמתנה',
    },
    SURETO_CANCEL_OLD: 'האם אתה בטוח שאתה רוצה לבטל את המוצר ל׳מצב ישן׳?',
    SURETO_CANCEL_NEWPENDING:
        'האם אתה בטוח שאתה מבטל את המוצר למצב חדש בהמתנה?',
    SUCCESSFULLY_CANCELLED: 'בוטל בהצלחה',
    REMOVE_PRODGROUP: 'הסר את קבוצת המוצרים',
    ARE_YOU_SURE_TO_CONTINUE_REMOVEGROUP:
        'האם אתה בטוח להסיר את קבוצת המוצרים הזו?',
    ALREADY_REPLACEPROD_ADDED: 'החלף מוצר שכבר נבחר',
    REMOVE_SELECTED: 'הסר פריטים שנבחרו',
    ENTER_VALIDTESTPERIOD_VALUE: 'הזן ערך חוקי של תקופת בדיקה',
    ENTER_VALIDTES_Min_Facing_VALUE: 'הזן ערך גבול מינימלי חוקי',
    SEPERATE_GROUP: 'קבוצה נפרדת',
    DISCONNECTED_GROUP: 'נתק קבוצה',
    GROUP_SORT: 'מיון קבוצתי',
    Planogram_Store_Copy: 'העתקת פלנוגרמה לסניף אחר',
    Select_Region: 'בחר אזור',
    Select_A_Region: 'בחר אזור',
    select_Store: 'בחר חנות',
    select_a_Store: 'לבחור חנות',
    successfully_copied: 'הועתק בהצלחה',
    Add_Department: 'הוסף מחלקה',
    Add_least_one_department: 'הוסף לפחות מחלקה אחת',

    REPLACE_IMPACT: 'השפעה כספית',
    LETS_TRYIT_COUPLEMORE_DAYS: 'בוא ננסה עוד כמה ימים ונחליט ביחד מה לעשות',
    GIVE_IT: 'תן את זה',
    MORE_TIME: 'יותר זמן!',
    KEEP: 'השאר',
    ARISTO_NOTIFICATIONS: 'ביצועי פריטים',
    NEWPROD_NOTTYPES: {
        TestPeriodOver: 'תקופת המבחן הסתיימה',
        RisingStar: 'התחלה עולה',
        NewStarIsBorn: 'כוכב חדש נולד',
        InOut: 'בפנים בחוץ',
        FOutOfShelf: 'F-מחוץ למדף',
        DeadWalker: 'כוכב נופל',
    },
    PROD_IMAGE_NOTFOUND: 'תמונת המוצר לא נמצאה',

    ARISTO_MAPS: 'המפות של אריסטו',
    VERSIONANALYSIS: 'ניתוח גרסאות',
    FAST: 'מהיר',
    SLOW: 'איטי',
    OPEN_SEARCH: 'פתח את החיפוש',
    MAP_FILTERS: {
        ENTIRE_WORLD: 'כל העולם',
        All_REGIONS: 'כל האזורים',
        ALL_CITIES: 'כל הערים',
        ALL_STORES: 'כל החנויות',
    },
    SALES_PER_METER: 'מכירות למטר ליום',
    SALE_PER_METER: 'מכירות למטר',
    TRENDSDDISCOVERED: 'מגמות שהתגלו',
    NO_RESULTS: 'אין תוצאות',
    Execute: 'לבצע',
    Ignore: 'התעלם',
    SOME_GPS_NOTAVAILABLE: 'חלק ממיקומי ה-GPS בחנות אינם זמינים',
    Create_AUI_Version: 'צור גרסת AUI',
    PLEASE_SELECT_ITEMS_FROM_SEARCH: 'אנא בחר פריטים מחיפוש חופשי כדי להמשיך',
    Expand_Message: 'תן לזה יותר זמן!',
    Keep_Message: 'למוצר הזה היו ביצועים טובים, בואו נשמור עליו!',
    Remove_Message: 'למוצר הזה היו ביצועים גרועים, בואו נחליף אותו!',
    MC: {
        BUILD: 'בנייה',
        IMPLEMENT: 'הטמעה',
        ANALYSIS: 'ניתוח',
        RECOMMEND: 'המלצות',
        SETTINGS: 'הגדרות',
    },
    AMG: {
        TestPeriodOver: 'המוצר הזה מסתדר. בוא נחכה ונראה.',
        NewStarIsBorn: 'וואו יש לך כוכב חדש.',
        InOut: 'העונה מסתיימת בקרוב.',
        FOutOfShelf: 'שימו לב: צריך למלא בהקדם.',
        DeadWalker: 'אופס, בוא ננסה משהו אחר.',
    },
    select_different: 'בחר שונה',
    NewProductStrategyLevel: {
        Brand: 'מותג',
        Cat: 'קטגוריה',
        Sub: 'תת',
        Dep: 'מחלקה',
    },
    STRGY_TYPES_Content: {
        linear: 'ישאיר את שטח המותג,ויצמצם בצורה חיניארים מכלל מוצרי המותג בתת הקטגוריה של המוצר החדש',
        replace: 'בחירת מוצר ספציפי כדי להחליף בו את המוצר חדש',
        maxfaceprod:
            'זה יבחר מוצר זהה או שונה  מכל חנות (מפלנוגרמה של סניף פעיל) עם  הכי הרבה שטח ויחליף בו את המוצר החדש ',
        minsaleprod:
            ' פעולה זו תבחר להחליף במוצר עם הביצועים הכספיים  החלשים ביותר ',
        maxfaceminsale: 'מוצר עם הכי הרבה שטח והכי פחות מכירות ',
        attached: 'מצורף',
        block: 'מוצרים חסומים ברמת המותג בכל חנות',
    },

    NEWPROD_LOGS: 'לוג מוצרים חדשים',
    NEWPROD_LOG_START: 'התחל יומן',
    NEWPROD_LOG_CHANGES: 'לוג שינויים',
    NEWPROD_START: {
        changeOption: 'שנה אפשרות',
        testPeriod: 'תקופת מבחן',
        appliedDate: 'תאריך החלה',
        storeCount: 'ספירת חנויות',
        shouldStoreCount: 'מספור חנות קיימת',
        salesStoreCount: 'מכירות זמינות לפי חנויות',
        testStartDate: 'תאריך תחילת הבדיקה',
        testEndDate: 'תאריך סיום הבדיקה',
        doneDate: 'תאריך סיום המשימה',
        mode: 'מצב',
    },
    NEWPROD_CHANGES: {
        mode: 'מצב',
        action: 'פעולה',
        changeDate: 'שנה תאריך',
        expandCount: 'הוספת ימי בדיקה למוצר',
    },
    any_mode: 'כל מצב',
    any_action: 'כל פעולה',
    any_changeoption: 'כל אפשרות שינוי',
    NP_MODELIST: {
        auto: 'אוטומטי',
        manual: 'מדריך ל',
    },
    NP_ACTIONLIST: {
        remove: 'הסר',
        keep: 'שמירה',
        expand: 'הרחב',
    },
    NP_CHANGEOPTIONS: {
        opt_a: 'אפשרות A',
        opt_b: 'אפשרות B',
        opt_c: 'אפשרות C',
        opt_d: 'אפשרות D',
        opt_e: 'אפשרות E',
    },
    CONFIRM_TO_AUTOSYNC: 'אשר סנכרון אוטומטי',
    SALES: 'מכירות',
    TESTTYPES: {
        ANY: 'הכל',
        ONGOING: 'במבחן',
        ENDED: 'הסתיים',
        PENDING: 'ממתין להחלטה',
    },
    TESTPERIOD_SUCCESSFULLY_UPDATED: 'תקופת הבדיקה עודכנה בהצלחה',
    ITEMS_SELECTED: 'פריטים שנבחרו',
    CLOSE_GROUPVIEW_TOCONTINUE: 'סגור את תצוגת הקבוצה כדי להמשיך',
    GROUP_ITEM: 'פריט קבוצתי',
    ITEMS_AVAILABLE: 'פריטים זמינים',
    SURE_TO_REMOVE: 'בטוח שברצונך להסיר?',
    SURE_TO_KEEP: 'בטוח שברצונך לשמור?',
    ARE_YOU_SURE_TO_KEEP_THIS_PRODUCT:
        'האם אתה בטוח שברצונך לשמור את המוצר הזה',
    ARE_YOU_SURE_TO_REMOVE_THIS_PRODUCT:
        'האם אתה בטוח שברצונך להסיר את המוצר הזה',
    PRODUCT_SUCCESSFULLY_REMOVED: 'המוצר הוסר בהצלחה.',
    PRODUCT_SUCCESSFULLY_KEEP: 'המוצר נשמר בהצלחה.',
    LETS_START_TESTPERIOD: 'בואו נתחיל את תקופת המבחן',
    TEST_PERIOD_COMPLETED: ' ימי תקופת הבדיקה שהושלמו',
    TEST_START_OPTION: 'אפשרות התחלת בדיקה:',

    Mandotoryoption: {
        newmandatory: 'שינוי מוצר / קבוצה חדש כחובה',
        oldremovemandatory: 'הסר כללי חובה של מוצרים שהוחלפו',
        replaceoldmandatory: 'תשמור כמו שזה',
    },
    this_mandotory_please_select_option_to_continue:
        'מוצר זה הוא מוצר חובה. אנא בחר אפשרות כדי להמשיך',
    MandatoryOptions: 'אפשרויות חובה',
    NP_STARTLOG_SYNC_SUCCESS: 'סנכרון יומן מוצר חדש הצליח',
    showhidenewproducts: 'הצג/הסתר מוצרים חדשים',
    have_right_field: 'יש שדה נכון',
    have_left_field: 'עזבו את השדה',
    EDITED_USER: 'משתמש ערוך',
    SEARCH_TO_DATE: 'חפש עד היום',
    SEARCH_FROM_DATE: 'חפש מתאריך',
    entermanualsyncpw: 'הזן סיסמת סנכרון ידנית כדי להמשיך',
    invalid_pw: 'סיסמה לא חוקית, אנא נסה שוב',
    theme: 'סוג תצוגה',

    selectedfield_only: 'שדה נבחר בלבד',
    EXISTING: 'קיים',
    HISTORY: 'הִיסטוֹרִיָה',
    DATE_PERIOD: 'תאריך תקופה',

    Normal: 'נוֹרמָלִי',
    SnapShot: 'צילום צילום',
    RELOGRAM: 'רלוגרמה',
    DISABLED_OTHER_FIELDS: 'יש לדמות שדה זה כדי להפעיל תכונה זו',
    BY_BRANCH: 'לפי סניף',
    Zoom_Info: 'זום ופנה עם העכבר<',
    Drag_Drop_Info: 'גרור לכאן קטגוריה',
    DISABLED_CHECK_RESET:
        'המחאות מושבתות במצב רלוגרמה. עבור לגרסה נוכחית כדי לבדוק',
    resetZoom: 'איפוס זום',
    mark_stackable: 'מארק Stackables',
    mark_stackable_all: 'ניתן לערום כבוי/מופעל הכל',
    SAME_OR_EXISTING_NAME: 'שם זהה או קיים',
    SIMULATE_FIRST: 'אנא בצע סימולציה עבור שדה זה תחילה',

    SOME_PRoD_HEIGHT_EXCEED_SELECTED_SHELF_HEIGHT:
        'גובה של חלק מהמוצרים עולה על גובה המדף שנבחר',
    successfilly_applied: 'הוחל בהצלחה בהצלחה!',
    successfullyapplied_apply_to_next_store:
        'הוחל בהצלחה. עכשיו הגש בקשה לחנות הבאה',
    MISSING_NEW_PRODUCTS_AVAILABLE_IN: 'חסרים מוצרים חדשים הזמינים ב',
    WHEN_PUSHING_TO_NEW_VERSION_SHOWING_BELOW:
        'כאשר דוחפים לגרסה חדשה המוצגת למטה',
    CURRENT_VERSION: 'גרסה נוכחית',
    UPDTING_VERSION: 'מעדכן גרסה',
    SELECT_ALL: 'בחר הכל',
    COMMAN: 'פקודה',
    Product_update: 'עדכון מוצר',
    SELECT_EXCEL_FILE: 'בחר את קובץ האקסל',
    PLEASE_SELECT_EXCEL_CORRECT_FILE: 'אנא בחר קובץ אקסל נכון',
    File_Size_To_Large: 'אתה לא יכול לעבד יותר מ-200000 מוצרים',
    original_value: 'ערך מקורי',
    expected_value: 'ערך צפוי',
    uom_format_details: 'פרטי פורמט uom',
    PLEASE_SELECT_EXCEL_Block_FILE: 'אנא בחר חסום קובץ אקסל',
    PLEASE_SELECT_EXCEL_Archive_FILE: 'אנא בחר ארכיון קובץ אקסל',
    PLEASE_SELECT_EXCEL_Product_FILE: 'אנא בחר קובץ עדכון מוצר אקסל',
    No_Barcode: 'ברקוד המוצר אינו קיים',
    column_have_issue: 'יש בעיה בעמודה',
    duplicate_barcode: 'ברקוד משוכפל',

    UPDATE_STRATEGIES: 'הגדרת אסטרטגיית ספירה',
    SELECT_STRATEGY_OF_SELECTEDITEM:
        'במסך זה נגדיר למערכת ממתי להתחיל לספור את ימי המבחן',
    ITEMS_PENDING: 'פריטים בהמתנה',
    STRATEGY_DESC: 'תיאור אסטרטגיה',
    APPLY_TOALL: 'החל על כולם',
    STRATEGY: 'אסטרטגיה',

    Apply_Products_Details: 'החל את פרטי המוצרים',
    Apply_Result: 'החל תוצאה',
    Applied: 'הוחל',
    Replace_Product_Not_Selected: 'החלף מוצר לא נבחר',
    Replace_Product_Not_Available: 'החלף מוצר לא זמין',
    Full: 'מלא',
    Half: 'חצי',
    CHAINOPTIONS: {
        A: 'התחל ספירה כאשר המוצר נמכר ב 80% מהסניפים המחוברים',
        B: 'התחל ספירה רק ברגע שתזהה מכירה בכל הסניפים המחוברים ',
        C: 'התחל כאשר המכירה הראשונה ברשת מתבצעת',
        D: 'התחל ספירה באופן ידני במסך ההתראה של אריסטו',
        E: 'התחל ספירה לאחר השלמת כל המשימות',
    },
    SAVE_AND_APPLY: 'שמור והחל',

    TESTPERIOD_START: 'תחילת תקופת מבחן',
    ARE_YOU_SURETO_CONTINUE_TESTPERIOD:
        'האם אתה בטוח שתתחיל את תקופת הבדיקה של המוצר הזה?',
    DateFilter: 'מסנן תאריכים',
    TESTEDSCOUNT_HASBEECHANGED_AFTERTHE_TEST_PERIOD:
        'ספירת החנויות שנבדקו השתנתה לאחר תחילת תקופת הבדיקה.',

    SOME_STORE_NEEDS_TORESET: 'יש חנויות שצריך לאפס כדי לבדוק',
    CANNOT_CONTINUE_WITHOUT_UPDATE_DIFFSTORES:
        'לא ניתן להמשיך מבלי לאלץ חנויות יחידות תצוגה ייחודיות',
    CANNOT_IGNORE_WITHOUT_NONDIFFSTORES:
        'לא ניתן להתעלם מהכל ולהמשיך ללא חנויות יחידות תצוגה שאינן ייחודיות',
    ARE_YOU_SURE_IGNOREALL_DIFFIELDS:
        'האם אתה בטוח שאתה מתעלם מכל השינויים הייחודיים בתחום ולהמשיך?',
    SURE_TO_REMOVE_THIS: 'האם אתה בטוח להסיר?',
    THIS_WILL_REMOVE_FROM_CLIPBOARD_AFTER_SAVE_PLANOGRAM_SURETO_CONTINUE:
        'פעולה זו תוסר מלוח הקליפ לאחר שמירת הפנוגרם. האם אתה בטוח שתמשיך?',
    DROP_SHELF_NOT_AVAILABLE: 'מדף לא זמין להורדה',
    AMG_MORE: {
        TestPeriodOver: 'בואו נרחיב את התקופה וננסה עוד כמה ימים ונחליט.',
        NewStarIsBorn:
            'זיהינו פה כוכב חדש , מומלץ להשאיר את המוצר על מנת להגדיל את המכר',
        InOut: 'החלפה זמנית לתקופה מוגדרת, הסרה תחזיר את הפריט  שהוחלף .',
        FOutOfShelf:
            'המלאי של המוצר הזה הולך ואוזל, הצטייד לפני שיהיה מאוחר מדי!',
        DeadWalker: 'הגיע הזמן לשינוי, הסר את זה והחלף אותו במשהו רווחי יותר.',
    },
    DEPT_VIEW: 'תצוגת מחלקה',
    TOTAL_ITEMS: 'סך הכל פריטים',
    PRINT_PGDEPARTMENTS: 'הדפס מחלקות פלנוגרמה',
    PLEASE_SELECT_A_DEPTTO_CONTINUE: 'אנא בחר רשת רשת כדי להמשיך',
    ACTIVE_PG_DETAILS_NOT_FOUND: 'פרטי הפלנוגרמה הפעילים לא נמצאו',
    SHARE_PGDEPARTMENTS: 'דוא"ל למחלקות פלנוגרם',
    RESETUNSAVED: 'אפס שינויים שלא נשמרו',
    SURETORESET_UNSAVED: 'האם אתה בטוח שאיפס שינויים שלא נשמרו?',
    NOPROD_SALE_AVAILABLE: 'אין פרטי מכירת מוצר זמינים',
    SNAPTABLE_LABELS: {
        MARK: 'הדגשה',
        PORTION: 'שם',
        AVG: 'רשתי',
        SIMSPACE: 'שטח מתוכנן',
    },
    DEPT_WARN_PRODUCT: 'מוצר אזהרה מחלקה',
    RECTPER_WARN_PRODUCT: 'מוצר אזהרה מומלץ',
    WARNING_TYPES: 'סוגי אזהרה',
    DEPTWARNING_HELP: 'זמין בתחומים אחרים',
    RECWARNING_HELP: 'לא מומלץ',
    EMAIL_PRINTSHARE: 'פרטי Planogram שותפו בהצלחה עם כתובת הדוא"ל הנתונה',
    SC_FOR_CURRENT: 'מחזור המכירה מחושב רק עבור הגרסה הנוכחית',

    VMP_NOT_SAVED_SAVE_BEFORE_CONTINIUE:
        'שינויים ב-VMP לא נשמרו. נא שמור לפני שתמשיך',
    Name: 'שם',
    DEPT_NOTAVAILABLE_IN_AISLE:
        'פרטי המחלקה אינם זמינים בחלק מהמחלקות של המעבר הנבחר',
    FIELD_NO_REQUIRED: 'נדרש מספר שדה עם פרטי המחלקה הנדרשים להמשך',
    CHART_GENERATED_BY_SPACE: 'תרשים שנוצר על ידי ערכי שטח פנוי',
    CONTINUE_CREATE_DRAFT: 'המשך צור טיוטה',
    CONTINUE_CREATE_DRAFT_MSG:
        'האם אתה בטוח שתמשיך ליצור טיוטה מהפלנוגרמה הפעילה הזו?',
    DATE_PICKER: 'בוחר תאריכים',

    DEPARTMENT_LEVEL: 'רמת מחלקה',
    CATEGORY_LEVEL: 'רמת קטגוריה',
    SUBCAT_LEVEL: 'רמת תת קטגוריה',

    IMPORTED_PRODLIST: 'רשימת מוצרים מיובאים',
    IMPORT_AVAILABLE_LIST: 'ייבוא ​​רשימה זמינה',
    DELETE_IMPORTPRODS: 'מחק מוצרים מיובאים',
    SURETO_DELETE_IMPORTED: 'האם אתה בטוח ברצונך למחוק את המוצרים המיובאים?',
    NO_AVAILABLE_PRODS: 'לא זמין ברשימת המוצרים המיובאים',
    NO_EXCELDATA_AVAILABLE: 'זמינים נתוני אקסל ריקים או לא תואמים',
    NO_PRODS_AVAILABLE_TO_DELETE: 'לא ניתן למחוק מוצרים',
    IMPORTED: 'מיובא',
    DATEFILTER_DATE_RANGE_WARN: 'נדרש טווח תאריכים עבור מסנן התאריכים שנבחר',
    CHECK_MVPONTOP_WHEN_OVERRIDE:
        'בדוק את המוצר הנמכר ביותר או הנמכר ביותר כאשר העקיפה מסומנת',
    CUSTOM_BRANCH_INFO:
        'במצב מותאם אישית, בחירת ענף זו משמשת למטרות דחיפה של סימולציה בלבד',
    Simulation_Affected_List: 'רשימת סימולציות מושפעות',
    Tags_Available: 'תגים זמינים',
    Simulations_have_issues: 'סימולציות מושפעות על ידי עריכות מימד',
    Sure_to_aknowladge_warnining: 'האם אתה בטוח להסיר?',
    this_action__will_remove_this_warning_only_for_this_snapshot:
        'פעולה זו תסיר את אזהרת המוצר מתמונת המצב הזו',
    Hierarchy_Issue: 'סוגיית היררכיה',
    Sale_Total: 'סך המכירות',
    Mapping_Products: 'מוצרי מיפוי',
    PLEASE_ENTER_DEPARTMENT_NAME: 'נא להזין את שם המחלקה',
    New_User_Password: 'סיסמת משתמש חדשה',
    Character: {
        min_qty: 'הכמות המינימלית חורגת מהתו המקסימלי 15',
        min_rev: 'ההכנסה המינימלית חורגת מהתו המקסימלי 15',
        MVP: 'MVP חורג מהתו המקסימלי של 15',
        results_count: 'המספר חורג מהתו המרבי 15',
        sensitivity: 'הרגישות עולה על התו המרבי 15',
        width: 'הרוחב חורג מהתו המרבי 15',
        height: 'הגובה חורג מהתו המרבי 15',
        depth: 'העומק חורג מהתו המרבי 15',
        shelve_height: 'גובה המדף חורג מהתו המרבי 15',
        shelve_gap: 'פער המדפים חורג מהתו המקסימלי של 15',
        shelve_count: 'המדף הדרוש חורג מהתו המרבי 15',
        length: 'האורך חורג מהתו המרבי 15',
        Breadth: 'הרוחב חורג מהתו המרבי 15',
        max_qty: 'הכמות המקסימלית חורגת מהתו המקסימלי 15',
        max_rev: 'ההכנסה המקסימלית חורגת מהתו המקסימלי 15',
        search_text: 'טקסט החיפוש חורג מהתו המקסימלי של 255',
        results: 'ספירת התוצאות חורגת מהתו המקסימלי של 15',
        code: 'הקוד חורג מהתו המקסימלי של 255 ',
        name: 'השם חורג מהתו המקסימלי של 255',
        address: 'הכתובת חורגת מהתו המקסימלי של 255',
        location: 'המיקום חורג מהתו המרבי 255',
        city: 'העיר חורגת מהתו המרבי 255',
        telephone: 'הטלפון חורג מהתו המרבי 255',
        email: 'האימייל חורג מהתו המקסימלי של 320',
        suggestprodviewlevel:
            'רמת תצוגת המוצר המוצעת חורגת מהתו המקסימלי של 15',
        salesimportbcode: 'קוד סניף ייבוא מכירות חורג מהתו המרבי 255',
        removeQuantity: 'הסר הכמות חורגת מהתו המרבי 15',
        first_name: 'השם הפרטי חורג מהתו המרבי 255',
        lastName: 'שם המשפחה חורג מהתו המקסימלי של 255',
        password: 'הסיסמה חורגת מהתו המקסימלי של 255',
        groupname: 'שם הקבוצה חורג מהתו המקסימלי של 255',
        barcode: 'ברקוד המוצר חורג מהתו המרבי 255',
        filename: 'שם הקובץ חורג מהתו המרבי של 255',
        depname: 'שם המחלקה חורג מהתו המרבי 255',
        StackableCount: 'ספירת הערימה חורגת מהתו המקסימלי של 15',
        sub_Cat: 'שם קטגוריית המשנה חורג מהתו המרבי של 255',
        Cat: 'שם הקטגוריה חורג מהתו המקסימלי של 255',
        tagName: "שם התג חורג מהתו המרבי 255 אינץ'",
        brandName: 'שם המותג חורג מהתו המקסימלי של 255',
        supplierName: 'שם הספק חורג מהתו המקסימלי של 255',
        supplierCode: 'קוד הספק חורג מהתו המרבי 255',
        unitName: 'שם היחידה חורג מהתו המקסימלי של 255',
        floorName: 'שם הקומה חורג מהתו המקסימלי של 255',
        TaskName: 'שם המשימה חורג מהתו המקסימלי של 255',
        description: 'התיאור חורג מהתו המקסימלי של 255',
        ProductName: "שם המוצר חורג מהתו המרבי 255 אינץ'",
        ProductBarocde: 'ברקוד המוצר חורג מהתו המרבי 255',
        PaceofSale: 'קצב המכירה חורג מהתו המקסימלי של 15',
        ShelveLife: 'חיי מדף חורגים מהתו המרבי 15',
        questionnaire_name: 'שם השאלון חורג מהתו המקסימלי של 255',
        question_name: 'שם השאלה חורג מהתו המקסימלי של 255',
        FIELD_COUNT: 'ספירת השדות חורגת מהתו המקסימלי של 15',
        min_width: 'הרוחב המינימלי חורג מהתו המקסימלי 15',
        max_width: 'הרוחב המרבי חורג מהתו המרבי 15',
        min_height: 'הגובה המינימלי חורג מהתו המקסימלי 15',
        max_height: 'הגובה המרבי חורג מהתו המרבי 15',
        max_depth: 'העומק המרבי חורג מהתו המרבי 15',
        min_depth: 'העומק המינימלי חורג מהתו המקסימלי 15',
        saleDateCount: 'ספירת תאריכי המכירה חורגת מהתו המקסימלי של 15',
        option_text: 'טקסט האפשרות חורג מהתו המקסימלי של 255',
        Comment_text: 'טקסט התגובה חורג מהתו המקסימלי של 255',
        filter_name: 'טקסט שם המסנן חורג מהתו המקסימלי של 255',
    },
    validation: {
        min_qty: 'נדרש שדה כמות מינימלית',
        mvp_percentage: 'שדה אחוזי MVP נדרש',
        min_revenue: 'נדרש שדה הכנסה מינימלית',
        area: 'נדרש שטח',
        code: 'קוד נדרש',
        name: 'נדרש שם',
        address: 'כתובת נדרשת',
        location: 'נדרש מיקום',
        city: 'עיר נדרשת',
        Product_view_level: 'נדרשת רמת תצוגת מוצר',
        Sales_import_branch: 'נדרש קוד סניף ייבוא מכירות',
        Product_remove_quantity: 'נדרשת כמות הסרת המוצר',
        shelve_count: 'חריגה מהמספר המרבי',
        NumberInputValidation: 'חריגה מהמספר המרבי',
    },

    DELETE_HEIRARCHY: 'מחק היררכיה',
    SURTO_DELETE_HEIRARCHY: 'האם אתה בטוח שתמחק את היררכיה הזו?',
    ONCE_DATE_REQ: 'נדרש תאריך פעם אחת!',
    ADD_PRODUCT_WIDTH: 'הוסף רוחב מוצר',
    ADD_PRODUCT_HEIGHT: 'הוסף גובה המוצר',
    ADD_PRODUCT_DEPTH: 'הוסף עומק מוצר',
    version_no: 'גרסה ללא טקסט חורג מהתו המקסימלי של 255',
    PLEASE_SET_VALID_Depth_RANGE: 'נא להגדיר טווח עומק חוקי',
    PLEASE_SET_VALID_height_RANGE: 'נא להגדיר טווח גובה חוקי',
    PLEASE_SET_VALID_width_RANGE: 'נא להגדיר טווח רוחב חוקי',
    SIMULATOR_NOT_AVAILABLE: 'סימולטור לא זמין',
    SIMULATOR: 'סימולטור',
    UnabletoDropProductbecuse: 'לא ניתן לשחרר את המוצר בגלל',
    Product_is_Archived: 'המוצר נשמר בארכיון',
    Product_is_NewProduct: 'מוצר הוא מוצר חדש, סמן אותו כמוצר ישן',
    product_Didnt_sendto_dep: 'שלח למחלקה וסמן כמוצר ישן',
    Mark_as_old: 'סמן כישן',
    SURE_TO_MARK_AS_OLD: 'האם אתה בטוח שסימנת את המוצר הזה כישן?',
    SIM_PRINT_PDF: 'הדמיית הדפסת PDF',
    SIM_PRINT_PDF_FIELDWISE: 'הדמיית PDF הדפסה - מבחינת השטח',
    SIM_PRINT_EXCEL: 'הדמיית הדפס אקסל',
    SIM_PRINT_EXCEL_FIELDWISE: 'הדמיית אקסל הדפסה - מבחינה שדה',
    EXCEL_UPLOAD_MAXIMUM: 'ייבוא ​​מוצר אקסל',
    EXCEL_UPLOAD_MAXIMUM_CONFIRM:
        'חרגה מספירת הברקודים המקסימלית (5000). ייקח זמן מה לעדכן את פרטי המוצר. האם אתה בטוח שתמשיך?',
    EXCELDATA_UPLOADLIMIT_WARN: 'לא ניתן להעלות יותר מ-10,000 ברקודים',
    Disconnect: 'ניתוק סניף',
    EXPAND_DAYS_COUNT: 'הארכת ימי הבדיקה ',
    NO_SALES_DATA: 'אין נתוני מכירות',
    VMP_VERSION_LIST_SELECTED: 'לא נבחרו גרסאות',
    SELECTED_VER_NO_PRODUCTS: 'המוצר שחיפשת לא קיים בגרסאות שבחרת',

    this_action__will_remove_this_all_warning_only_for_this_snapshot:
        'פעולה זו תנקה את כל אזהרות המוצרים הערוכים מתמונת המצב הזו',
    Data_updated: 'מימד עודכן',
    DIMENSION_CHNGES_NOT_AFFECTED: 'שינויים בממדים אינם מושפעים',

    PRODUCT_IS_MISSING_DETAILS: 'מידות המוצר חסרות',
    PRODUCT_IS_MISSING_DETAILS_BRAND: 'מידות המוצר, חסר מותג',
    PRODUCT_IS_MISSING_DETAILS_BRAND_IMAGE: 'מידות המוצר, מותג, תמונה חסרה',
    PRODUCT_IS_MISSING_DETAILS_BRAND_HEIRARCHY: 'מותג מוצר, היררכיה חסרה',
    PRODUCT_IS_MISSING_DETAILS_IMAGE: 'מידות המוצר, חסרה תמונה',
    PRODUCT_IS_MISSING_DETAILS_HEIRARCHY: 'מידות המוצר, היררכיה חסרה',
    PRODUCT_IS_MISSING_BRAND: 'מותג המוצר חסר',
    PRODUCT_IS_MISSING_BRAND_IMAGE: 'מותג המוצר, חסרה תמונה',
    PRODUCT_IS_MISSING_BRAND_IMAGE_HEIRARCHY: 'חסר מותג מוצר, תדמית, היררכיה',
    PRODUCT_IS_MISSING_BRAND_HEIRARCHY: 'מותג מוצר, היררכיה חסרה',
    PRODUCT_IS_MISSING_IMAGE: 'תמונת המוצר חסרה',
    PRODUCT_IS_MISSING_IMAGE_HEIRARCHY: 'תמונת מוצר, היררכיה חסרה',
    PRODUCT_IS_MISSING_HEIRARCHY: 'היררכיה של המוצר חסרה',
    PRODUCT_IS_MISSING_DETAILS_BRAND_IMAGE_HEIRARCHY:
        'מידות המוצר, מותג, תדמית, היררכיה חסרים',

    FIELD_WISE_PRINT: 'הדפס חכם בשדה',
    CATEGORY_WISE_PRINT: 'הדפס חכם בקטגוריה',
    Excel_Export: {
        PRODUCT_BARCODE: 'ברקוד מוצר',
        PRODUCT_NAME: 'שם מוצר',
        hierarchyCompleteStatus: 'מצב שלם של היררכיה',
        Shelve_No: "מדף מס'",
        Field_No: "מס' שדה",
        planigo_prodinfo_export: 'ייצוא מידע על מוצר planigo',
        product_details: 'פרטי מוצר',
        Filtered_products: 'מוצרים מסוננים',
        All_products: 'מוצרים הופיעו בפלנוגרמות פעילות',
    },
    soldQty: 'נמכר כמות',
    NumberInputValidation: 'חריגה מהמספר המרבי',
    block_unitl_date: 'חסום עד התאריך',
    mvp_override: 'ביטול MVP',
    ISMVP_OR_ONTOP: 'isMVP / isOntop',
    usageStatus: 'מצב שימוש',
    CANNOT_CHANGE_OVERSAFTY_MORETHAN_FIELDHEIGHT:
        'לא ניתן לשנות את שולי בטיחות החפיפה יותר מגובה השדה',
    OVERLAP_PROD_AVAILABLE_REMOVE_TO_EDIT_SAFTY:
        'מוצרי חפיפה זמינים, הסר אותם כדי לערוך שולי בטיחות חפיפה',
    Product_department_not_matching: 'מחלקת המוצר אינה תואמת למחלקת הסימולציה',

    SPM_MESSAGE: 'מכירות למטר מחושבות מתאריך הפעלת הגירסה האחרונה ',
    SALECYCLE_MESSAGE: 'מחזור המכירה מחושב ברמת השרשרת',
    BASED_VERSION: 'גרסה מבוססת',

    RESET_AND_CURRENT: 'אפס והצג את הגרסה הנוכחית',
    VIEW_RELOGRAM: 'צפה ברלוגרמה',
    ARE_YOU_SURE_YOU_WANT_TO_RESET_AND_VIEW_THE_CURRENT_SIM:
        'האם אתה בטוח שברצונך לאפס ולהציג את הגרסה הנוכחית?',
    ARE_YOU_SURE_YOU_WANT_TO_VIEW_THE_RELOGRAM:
        'האם אתה בטוח שברצונך להציג את הרלוגרמה?',
    REVERTING_TO_RELOGRAM: 'חוזר לרלוגרמה',
    RESETTING_TO_CURRENT_VERSION: 'איפוס לפלנוגרמה',
    RESET_TO_CURRENT_VERSION: 'אפס לגרסה הנוכחית',
    THIS_STORE_NEEDS_TORESET: 'יש לאפס את החנות הזו לגרסה הנוכחית.',
    DO_YOU_WANTS_TO_CONTINUE: 'האם אתה רוצה להמשיך?',
    DO_YOU_WANT_TO_SAVE_AND_PRINT:
        'השינויים האחרונים שלך לא נשמרים. האם ברצונך להדפיס את זה לאחר השמירה המלאה?',
    SIMULATION_OF: 'סימולציה של',
    SIMULATION: 'סימולציה',
    SIMULATIONS: 'סימולציות',
    STACK_COUNT: 'ספירת ערימות',
    Job_Status: 'מצב עבודה',
    Canceled: 'מבוטל',
    Catelogue: 'קטלוג',
    Sale: 'מְכִירָה',
    VERIFY_TITLE: 'אופס, אין לך גישה לדף הזה',
    VERIFY_BODY_TEXT: 'אם אתה רוצה לגשת לדף זה, אמת את הבעלות שלך.',
    VERIFY_Btn: 'אמת את הבעלות שלך',
    CONFIRM_QUEUE: 'אשר את ביטול הנעילה של כרטיסיית העבודה בתור',
    CONFIRM_QUEUE_L: 'הזן סיסמת ביטול נעילה של כרטיסיית משרה בתור כדי להמשיך',
    password_limmit: 'הסיסמה חורגת מהתו המרבי של 255',
    DATE_VALIDATION_MSG: 'מתאריך חייב להיות מוקדם או שווה לתאריך עד',
    FROM_DATE_REQUIRED: 'מתאריך נדרש',
    TO_DATE_REQUIRED: 'עד היום נדרש',
    Job_ID: 'מזהה משרה',
    Queue_Jobs: 'יומן משרות בתור',
    Profit_Weight_Validation: 'משקל הרווח חורג מהתו המקסימלי 15',
    Sold_Qty_Validation: 'כמות הנמכרת עולה על התו המקסימלי 15',
    Sale_Weight_Validation: 'משקל המכירה חורג מהתו המקסימלי 15',
    MVP_Validation: 'ה-MVP חורג מהתו המקסימלי 15',
    Min_Revenue_Validation: 'ההכנסה המינימלית חורגת מהתו המקסימלי 15',
    min_qty_Validation: 'הכמות המינימלית חורגת מהתו המקסימלי 15',
    min_qty_require: 'נדרש שדה כמות מינימלית',
    mvp_percentage_require: 'שדה אחוזי MVP נדרש',
    min_revenue_require: 'נדרש שדה הכנסה מינימלית',
    sale_weight_percentage_require: 'נדרש שדה אחוז משקל מכירה',
    profit_weight_percentage_require: 'נדרש שדה אחוז משקל רווח',
    sold_qty_weight_percentage_require: 'נדרש שדה כמות נמכר',
    WEIGHT: 'מִשׁקָל',
    TOTAL_WEIGHT_CANNOT_BE_MORETHAN_100:
        'אחוז המשקל הכולל לא יכול להיות יותר מ-100%',

    archived: 'בארכיון',
    ELIGIBLE_STATUS: {
        eligible: 'זכאי',
        ineligible: 'לא זכאי',
    },
    THIS_VMP_NEEDS_TOREFRESH: 'ה-VMP הזה צריך לרענן',
    RESOLVED_PRODLIST: 'רשימת מוצרים נפתרה',
    REFRESH_VMP: 'רענן פלנוגרמה מאסטר',
    SURETO_REFRESHVMP: 'האם אתה בטוח שאתה מרענן את הפלנוגרמה הראשית?',
    SEND_TO_DEP_AND_REFRESH: 'שלח למחלקה ורענון',
    NEW_PROD_SENDTODEP_CONFIRM_REFRESH:
        'האם אתה בטוח שאתה שולח את המוצר הזה ל-Master Planogram ולרענן Planogram אב?',
    DELETED: 'נמחק',
    VMP_SUCCESSFULLY_REFRESHED: 'הפלנוגרמה הראשית עברה רענון מוצלח',
    mark_product_as_send_to_dep: 'המוצר נשלח למחלקה',
    data_missing: 'חסרים נתונים',
    ACTIONS: 'פעולות',
    REFRESH: 'לְרַעֲנֵן',
    USE_MINREV_IN_PRODLEVEL: 'השתמש בהכנסה מינימלית ברמת המוצר',
    removed: 'הוסר',
    MANDATORY: 'חובה',
    ELIGIBILITY_BASED_ON: 'זכאות מבוססת על,',
    IF_PRODS_MISSING_IN_RESOLVE:
        'אם חסרים מוצרים מסוימים, הם יופיעו בחלונית הבעיות.',
    MANUAL_FIXED_CANNOT_RESOLVED: 'לא ניתן לפתור את המוצרים שתוקנו ידנית כאן.',
    INOUT_STATUS: {
        inout: 'בפנים בחוץ',
        in: 'ב',
        out: 'הַחוּצָה',
    },
    ONLY_USAGESTATUS_NONE_PRODS:
        'המוצרים המוצגים במודל זה הם כאלה שאין להם שימוש וניתן לשלוח אותם למחלקות.',
    PRODUCT_DETAILS_NOT_FOUND: 'פרטי המוצר לא נמצאו',
    SUCCESSFULLY_SAVED: 'נשמר בהצלחה!',
    PRODUCTS_NOT_LOADED: 'מוצרים לא טעונים!',
    CANNOT_LOAD_PLANOGRAM_FLOOR_DETAILS: 'לא ניתן לטעון פרטי רצפת פלנוגרמה',
    INVALID_USERNAME_OR_PASSWORD: 'שם משתמש או סיסמה לא חוקיים',

    // transaltion codes from BE
    INV_MP_ID: 'מזהה פלנוגרמה אב לא חוקי!',
    MP_CAT_NOT_FOUND: 'Mp_Has_Category לא נמצא!',
    AISL_ALLOC_SLOT_CAT_NOT_FOUND: 'לא נמצאו קטגוריות שהוקצו חריץ להקצאת מעבר!',
    AISL_ALLOC_SLOT_NOT_FOUND: 'חריץ הקצאת מעבר לא נמצא!',
    AISL_ALLOC_SLOT_ID_NOT_FOUND: 'זיהוי חריץ הקצאת מעבר לא נמצא!',
    AISL_LST_NOT_FOUND: 'רשימת המעברים לא נמצאה!',
    AISL_ALLOC_NOT_FOUND: 'הקצאת מעבר לא נמצאה!',
    REQ_DT_MISS_CHK_AVAIL: 'חסרים נתונים נדרשים כדי לבדוק זמינות',
    INV_COMPL_STAT: 'סטטוס תאימות לא חוקי!',
    DT_RNG_NOT_FOUND: 'טווח תאריכים לא נמצא!',
    MP_NOT_FOUND: 'פלנוגרמה מאסטר לא נמצאה!',
    SYS_USER_NOT_AVAIL_TASK_CANNOT_CONT:
        'משתמש המערכת אינו זמין. המשימה הנוכחית לא יכולה להמשיך ללא משתמש מערכת.',
    INV_DEPT_ID: 'מזהה מחלקה לא חוקי!',
    INV_CAT_ID: 'מזהה קטגוריה לא חוקי!',
    DUPL_NAME_AVAIL: 'שם כפול זמין!',
    EMP_NAME: 'שם ריק!',
    ERR_WHILE_UPDT_DEPT_MD: 'אירעה שגיאה בעת עדכון המטא-נתונים של המחלקה.',
    INCORR_PW: 'סיסמא לא נכונה',
    ENT_PW_TO_DEL: 'הזן סיסמה כדי למחוק',
    NO_DATA_AVAIL_FOR_IMPL: 'אין נתונים זמינים ליישום!',
    WRNG_FD_CT_SEL: 'נבחרה ספירת שדות שגויה!',
    MNY_CONF_LYTS: 'יש הרבה פריסות לאשר',
    INV_OVERLAP_SLF_INFO: 'פרטי מדף חופפים לא חוקיים!',
    NO_AC_RES: 'אין לך גישה למשאב!',
    FD_DRW_OUT_OF_BOX: 'שדה ייצא מהקופסה!',
    PLG_SHLD_CONF_FIRST: 'פלנוגרם צריך לאשר קודם!',
    ONG_MP_PLG_IMPL_JOB_WAIT:
        'עבודה מתמשכת ביישום פלנוגרמה מאסטר מתרחשת, אנא המתן לכמה דקות!',
    PROD_NAME_NOT_FOUND: 'שם המוצר לא נמצא',
    BARC_EXISTS: 'ברקוד כבר קיים',
    NO_IMG_FOUND: 'לא נמצאה תמונה!',
    MAND_PROD_NOT_ONTOP: 'מוצר חובה לא יכול להיות מוצר OnTop',
    PROD_REQ_MVP_ONTOP: 'המוצר לא היה יכול להתקיים ללא MVP או OnTop',
    MAND_PROD_REQ_MVP: 'מוצר חובה לא יכול להיות קיים ללא דגל MVP',
    DEPT_CAT_HIER_MISMATCH: 'אי התאמה בהיררכיה של המחלקה והקטגוריה!',
    CAT_SUBCAT_HIER_MISMATCH: 'חוסר התאמה בהיררכיה של קטגוריות ותת-קטגוריות!',
    NO_PROD_HIER_FIX: 'לא נבחרו מוצרים לתיקון היררכיה!',
    INV_DATA: 'נתונים לא חוקיים',
    NO_PROD_STACK_MARK: 'אין מוצרים לסימון ניתנים לערמה!',
    STR_INFO_NOT_FOUND: 'לא ניתן למצוא מידע על החנות',
    ERR_SAVE_PROD: 'אירעה שגיאה בעת שמירת מוצרים',
    ERR_SAVE_CAT_STAT_LOG: 'אירעה שגיאה במהלך שמירת יומן מצב הקטלוג',
    ERR_SAVE_HIER_ISSUES: 'אירעה שגיאה במהלך שמירת בעיות היררכיה',
    ERR_SAVE_ISSUE_PROD: 'אירעה שגיאה בעת שמירת מוצר בעיה',
    ERR_VAL_HIER_IMP_CATALOG:
        'אירעה שגיאה בעת אימות נתוני היררכיה בעת ייבוא ​​נתוני קטלוג',
    ERR_SAVE_MSG_Q_JOB: 'אירעה שגיאה בעת שמירה של פרטי עבודה בתור הודעות חדש',
    ERR_SAVE_CAT_IMP_STAT_PROD:
        'אירעה שגיאה בעת שמירת יומן סטטוס ייבוא ​​קטלוג חדש - מוצר',
    ERR_SAVE_NEW_PROD: 'אירעה שגיאה במהלך שמירת מוצר חדש',
    ERR_SAVE_CAT_IMP_STAT_DEPT:
        'אירעה שגיאה בעת שמירת יומן סטטוס ייבוא ​​קטלוג חדש - מחלקה',
    ERR_SAVE_NEW_DEPT: 'אירעה שגיאה בעת שמירת מחלקה חדשה',
    ERR_SAVE_NEW_DEPT_APPR: 'אירעה שגיאה בעת שמירת מחלקה חדשה לאישור',
    ERR_SAVE_CAT_IMP_STAT_CAT:
        'אירעה שגיאה בעת שמירת יומן סטטוס ייבוא ​​קטלוג חדש - קטגוריה',
    ERR_SAVE_NEW_CAT: 'אירעה שגיאה בעת שמירת קטגוריה חדשה',
    ERR_SAVE_NEW_CAT_APPR: 'אירעה שגיאה בעת שמירת קטגוריה חדשה לאישור',
    ERR_SAVE_CAT_IMP_STAT_SUBCAT:
        'אירעה שגיאה במהלך שמירת יומן סטטוס ייבוא ​​קטלוג חדש - קטגוריית משנה',
    ERR_SAVE_NEW_SUBCAT: 'אירעה שגיאה בעת שמירת תת-קטגוריה חדשה',
    ERR_SAVE_NEW_SUBCAT_APPR: 'אירעה שגיאה בעת שמירת תת קטגוריה חדשה לאישור',
    ERR_SAVE_CAT_IMP_STAT_SUPP:
        'אירעה שגיאה בעת שמירת יומן סטטוס ייבוא ​​קטלוג חדש - ספק',
    ERR_SAVE_NEW_SUPP: 'אירעה שגיאה בעת שמירת ספק חדש',
    ERR_SAVE_NEW_SUPP_APPR: 'אירעה שגיאה בעת שמירת ספק חדש לאישור',
    ERR_SAVE_CAT_IMP_STAT_BRAND:
        'אירעה שגיאה בעת שמירת יומן סטטוס ייבוא ​​קטלוג חדש - מותג',
    ERR_SAVE_NEW_BRAND: 'אירעה שגיאה בעת שמירת מותג חדש.',
    ERR_SAVE_ERR_LOG: 'אירעה שגיאה במהלך שמירת יומן השגיאות',
    ERR_UPD_IMP_REF_CODE_DEPT:
        'אירעה שגיאה בעת עדכון קוד העזר לייבוא ​​של המחלקה',
    ERR_UPD_IMP_REF_CODE_CAT: 'אירעה שגיאה בעת עדכון קוד העזר לקטגוריה',
    NO_SUBCAT_APPR_WO_VAL_CAT: 'לא ניתן לאשר תת-קטגוריה ללא קטגוריה חוקית.',
    ERR_UPD_IMP_REF_CODE_SUBCAT:
        'אירעה שגיאה בעת עדכון קוד העזר לייבוא ​​של קטגוריית המשנה',
    ERR_APPRV_SUBCAT_DATA: 'אירעה שגיאה בעת אישור נתוני קטגוריית משנה',
    ERR_UPD_CAT_STAT_LOG_STAT: 'אירעה שגיאה בעת עדכון סטטוס יומן סטטוס קטלוג.',
    VAL_SUBCAT_APPR_NOT_FOUND: 'לא ניתן למצוא אישור נתונים חוקי של תת-קטגוריה.',
    INV_SUPP_DATA_CHK_AGAIN: 'נתוני ספק לא חוקיים זמינים. אנא בדוק שוב.',
    ERR_UPD_IMP_REF_CODE_SUPP: 'אירעה שגיאה בעת עדכון קוד העזר ליבוא של הספק',
    VAL_SUPP_NOT_FOUND: 'לא ניתן למצוא ספק תקף',
    ERR_APPRV_SUPP_DATA: 'אירעה שגיאה בעת אישור נתוני הספק',
    VAL_SUPP_DATA_APPR_NOT_FOUND: 'לא ניתן למצוא אישור נתוני ספק חוקי.',
    VAL_CAT_NOT_FOUND: 'לא ניתן למצוא קטגוריה חוקית.',
    INV_BRAND_DATA_CHK_AGAIN: 'זמינים נתוני מותג לא חוקיים. אנא בדוק שוב.',
    ERR_UPD_IMP_REF_CODE_BRAND:
        'אירעה שגיאה בעת עדכון קוד העזר לייבוא ​​של המותג',
    ERR_FIND_EXIST_BRAND: 'אירעה שגיאה בעת מציאת מותג קיים',
    ERR_APPRV_BRAND_DATA: 'אירעה שגיאה בעת אישור נתוני מותג',
    VAL_BRAND_DATA_APPR_NOT_FOUND: 'לא ניתן למצוא אישור תקף לנתוני מותג.',
    VAL_FILES_NOT_FOUND_IMP_TRY_AGAIN:
        'לא ניתן למצוא קבצים חוקיים לייבוא. בבקשה נסה שוב.',
    ERR_UPD_ORIG_REFS: 'אירעה שגיאה בעת עדכון ההפניות המקוריות',
    AI_IMG_IDS_INV: 'מזהי תמונה של AI לא חוקיים!',
    INV_FIELD: 'שדה לא חוקי!',
    CHAIN_NOT_FOUND: 'לא מצליח למצוא את השרשרת',
    INV_COMPL_ID: 'מזהה תאימות לא חוקי!',
    INV_COMPL: 'ציות לא חוקי!',
    INV_COMPL_IMG_ID: 'מזהה תמונת תאימות לא חוקי!',
    INV_COMPL_IMG: 'תמונת תאימות לא חוקית!',
    NO_CHAIN_BY_UUID: 'לא ניתן למצוא שרשרת לפי שרשרת Uuid',
    SUPP_NAME_EXISTS: 'שם הספק כבר קיים.',
    VAL_BRAND_UPD_NAME_CHK_AGAIN: 'מותג תקף זמין עם שם מעודכן. אנא בדוק שוב.',
    ERR_UPD_EXISTING_BRAND: 'אירעה שגיאה בעת עדכון המותג הקיים',
    ERR_SAVE_BRAND_CONFLICT_LOG: 'אירעה שגיאה במהלך שמירת יומן התנגשויות מותג.',
    INV_DATA_CHK_AGAIN: 'נתונים לא חוקיים זמינים. אנא בדוק שוב.',
    CANT_DELETE_REF: 'לא ניתן למחוק הפניה',
    CAT_NAME_EXISTS: 'שם הקטגוריה כבר קיים. [$]',
    SUBCAT_NAME_EXISTS: 'שם קטגוריית המשנה כבר קיים. [$]',
    ITEM_REFS_EXIST: 'הפניות לפריטים זמינות.',
    CANNOT_DEL_ALL_CAT_SUBCAT_REFS_EXIST:
        'לא ניתן למחוק את כל הקטגוריות. הפניות לקטגוריות משנה זמינות עבור קטגוריות מסוימות.',
    CAT_NAME_COLOR_EXISTS: 'שם הקטגוריה והצבע כבר קיימים. [$], [$]',
    DEPT_NAME_EXISTS: 'שם המחלקה כבר קיים.',
    VAL_CAT_UPD_NAME_CHK_AGAIN:
        'קטגוריה חוקית זמינה עבור השם המעודכן. אנא בדוק שוב.',
    ERR_UPD_EXISTING_CAT: 'אירעה שגיאה בעת עדכון הקטגוריה הקיימת',
    ERR_SAVE_CAT_CONFLICT_LOG:
        'אירעה שגיאה במהלך שמירת יומן התנגשויות קטגוריות.',
    VAL_SUBCAT_UPD_NAME_CHK_AGAIN:
        'קטגוריית משנה חוקית זמינה עם שם מעודכן. אנא בדוק שוב.',
    ERR_UPD_EXISTING_SUBCAT: 'אירעה שגיאה בעת עדכון תת-קטגוריה קיימת',
    ERR_SAVE_SUBCAT_CONFLICT_LOG:
        'אירעה שגיאה בעת שמירת יומן התנגשויות בקטגוריות המשנה.',
    SOMETHING_WRONG: 'משהו השתבש',
    ERR_SAVE_DEPT_CONFLICT_LOG: 'אירעה שגיאה בעת שמירת יומן התנגשות המחלקה.',
    SUPP_CODE_EXISTS: 'קוד הספק כבר קיים.',
    ERR_EDIT_SELECTED_SUPP: 'אירעה שגיאה בעת עריכת הספק הנבחר',
    SIM_SNAP_CPY_FAIL: 'תמונת מצב של סימולציה העתק שמירה נכשל',
    SIM_SNAP_ENT_NOT_FOUND: 'תמונת מצב של סימולציה ישות לא נמצאה',
    SIM_SNAP_MISSING: 'קוד מצב סימולציה חסר',
    NO_REC_IN_CON_DISCON: 'אין רשומות בעבודת ניתוק חיבור',
    INV_USER: 'משתמש שגוי!',
    ERR_UPD_PROD_MVP_ONTOP: 'אירעה שגיאה בעת עדכון המוצר MVP ו-OnTop.',
    INV_BRAND: 'מותג לא חוקי!',
    BRAND_RULE_NOT_EMPTY: 'מותג וכלל שניהם לא יכולים להיות ריקים!',
    DEPT_HAS_ALREADY_CREATED_MP: 'מחלקה זו כבר יצרה פלנוגרמה מאסטר!',
    SOMETHING_WRONG_MP_SAVE: 'משהו השתבש בשמירת מאסטר פלנוגרמה!',
    INV_RANK: 'דירוג לא חוקי!',
    INV_DISP_UNIT_SEL: 'בחירת יחידת תצוגה לא חוקית!',
    MP_FIELD_SAVE_ERR: 'שגיאת שמירת שדה פלנוגרמה מאסטר!',
    CAT_RULE_NOT_EMPTY: 'קטגוריה וכלל שניהם לא יכולים להיות ריקים!',
    MP_CAT_NOT_SAVED: 'קטגוריית MP לא נשמרה!',
    DEPT_NOT_FOUND: 'לא מוצא את המחלקה',
    VAL_DEPT_META_NOT_FOUND: 'לא ניתן למצוא מטא נתונים חוקיים של המחלקה.',
    ERR_UPD_DEPT_META: 'אירעה שגיאה בעת עדכון המטא נתונים של המחלקה.',
    PROD_NOT_FOUND: 'לא מצליח למצוא את המוצר',
    INV_SUBCAT: 'קטגוריית משנה לא חוקית!',
    SUBCAT_RULE_NOT_EMPTY: 'קטגוריית המשנה והכלל לא יכולים להיות ריקים!',
    SRV_SIDE_EXC: 'חריגה בצד השרת',
    PROD_ID_NOT_FOUND: 'לא ניתן למצוא את מזהה המוצר',
    INV_SIM: 'סימולציה לא חוקית!',
    UPD_CONN_DISCONN_JOB_FAIL: 'עדכון רשומת העבודה של ניתוק חיבור נכשל',
    TKBK_FAIL: 'החזרה נכשלה',
    SIM_SNAP_SAVE_ISSUE: 'תמונת מצב של סימולציה שמור בעיה!',
    WRNG_DEF_SIM_PROD_UPD: 'הגדרה שגויה עם עדכון מוצר סימולציה!',
    SNAP_ID_NOT_FOUND: 'מזהה תמונת מצב לא נמצא!',
    NO_DUP_SIM_CLIP_SAVE: 'לא ניתן לשמור נתונים כפולים של לוח סימולציה!',
    NOTIF_TMPL_NOT_FOUND: 'תבנית הודעה לא נמצאה.',
    UPD_FAIL_SHELF_CHG_LOG: 'העדכון נכשל ביומן השינויים של המדף!',
    FLD_LEFT_MAP_ERR: 'שדה נותר לא ממופה כהלכה!',
    FLD_LEFT_MAP_ERR_NO_REL: 'שדה נותר לא ממופה נכון, לא מוצא קשר!',
    LFT_FLD_DEL_FAIL_INV_ID:
        'לא ניתן למחוק שדה צד שמאל. אין לספק תעודה מזהה תקפה!',
    FLD_RIGHT_MAP_ERR: 'שדה נכון לא ממופה נכון!',
    FLD_RIGHT_MAP_ERR_NO_REL: 'שדה נכון לא ממופה נכון, לא מוצא קשר!',
    RGT_FLD_DEL_FAIL_INV_ID:
        'לא ניתן למחוק את השדה בצד ימין. אין לספק תעודה מזהה תקפה!',
    FLR_LAYOUT_NOT_FOUND: 'לא מצליח למצוא את פריסת הקומה',
    TASK_MS_COMM_FAIL: 'לא ניתן לתקשר עם מיקרו-שירות משימות [$]',
    ERR_SAVE_SUPP_GS1_IMP:
        'אירעה שגיאה בעת שמירת ספק חדש בעת ייבוא ​​מוצר מ-GS1.',
    ERR_SAVE_BRAND_GS1_IMP:
        'אירעה שגיאה בעת שמירת מותג חדש בעת ייבוא ​​מוצר מ-GS1',
    NEW_PROD_META_UUID_NOT_FOUND: 'לא ניתן למצוא מטא Uuid של מוצר חדש',
    NO_REPL_PROD_FOR_VAL: 'ללא מוצר החלפה עבור [$]',
    INV_SEL_DATE_RANGE: 'טווח תאריכים שנבחר לא חוקי',
    NEW_PROD_NOT_FOUND: 'לא מצליח למצוא מוצר חדש',
    CANT_CANCEL_ONGOING_NEW_PROD: 'לא ניתן לבטל מוצר חדש מתמשך',
    NEW_PROD_ID_NOT_FOUND: 'לא ניתן למצוא מזהה מוצר חדש',
    CANT_CANCEL_PROD_NOT_NEW_TEST:
        'לא ניתן לבטל את המוצר. המוצר החדש לא בדיקה חדשה',
    FLR_LAYOUT_BY_VER_NOT_FOUND: 'לא ניתן למצוא פריסת קומה לפי גרסת פריסת קומה',
    FLR_LAYOUT_BY_UUID_NOT_FOUND:
        'לא ניתן למצוא פריסת קומה לפי פריסת קומה Uuid',
    STORE_BY_UUID_NOT_FOUND: 'לא ניתן למצוא חנות לפי חנות Uuid',
    CHAIN_NOT_FOUND_FOR_STORE: 'לא ניתן למצוא שרשרת עבור הסניף [$]',
    NEW_PROD_DEPT_NOT_FOUND: 'לא ניתן למצוא מחלקת מוצרים חדשים',
    NEW_PROD_DEPT_ID_NOT_FOUND: 'לא ניתן למצוא מזהה מחלקת מוצר חדש',
    CANT_SAVE_NEW_PROD_TYPE_GRP_SGRP:
        'לא ניתן לשמור מוצר חדש. יש הרבה מוצרים חדשים. הסוג צריך להיות קבוצה או קבוצה נפרדת',
    CANT_SAVE_1_NEW_PROD_TYPE_NORM:
        'לא ניתן לשמור מוצר חדש. יש מוצר אחד חדש. הסוג צריך להיות רגיל',
    TYPE_NORM_GRP_SGRP_NOT_FOUND:
        'לא מצליח למצוא את הסוג (רגיל/קבוצה/קבוצה נפרדת)',
    NEW_PROD_BRAND_NOT_FOUND: 'לא ניתן למצוא מותג מוצר חדש',
    NEW_PROD_BRAND_ID_NOT_FOUND: 'לא ניתן למצוא מזהה מותג חדש של מוצר',
    INV_FACING_VAL: 'ערך נקוב לא חוקי',
    REPL_PROD_NOT_FOUND: 'לא ניתן למצוא מוצר חלופי',
    REPL_PROD_ID_NOT_FOUND: 'לא ניתן למצוא את מזהה המוצר החלופי',
    STRAT_NOT_FOUND_LIN_REPL_MAX_MIN_ATT:
        'לא מצליח למצוא אסטרטגיה (לינארית/החלפה/מקסימום פנים/מינימום מכירות/מקסימום פנים עם מינימום מכירות/מצורף)',
    SEL_OPT_NOT_FOUND_ALL_USED_SEL:
        'לא ניתן למצוא אפשרות בחירה (הכל/משומש/נבחר)',
    TEST_PERIOD_GT_0: 'תקופת המבחן לא צריכה להיות פחות או שווה 0',
    FACING_CNT_GT_0: 'הספירה הפונה לא צריכה להיות פחות או שווה ל-0',
    FACING_NOT_FOUND: 'לא ניתן למצוא פן',
    VMP_VER_NOT_FOUND: 'לא ניתן למצוא את גרסת VMP',
    VMP_VER_LIST_NOT_FOUND: 'לא ניתן למצוא רשימת גרסאות VMP',
    NOTHING_TO_SAVE: 'אין מה להציל',
    ERR_SAVE_NEW_PROD_BRAND_LOG:
        'אירעה שגיאה בעת שמירת יומן שינויי מותג חדש של מוצר.',
    BRAND_NAME_EXISTS: 'שם המותג כבר קיים.',
    ERR_UPD_EXIST_PROD: 'אירעה שגיאה בעת עדכון מוצר קיים',
    ERR_SAVE_PROD_CONFLICT_LOG: 'אירעה שגיאה במהלך שמירת יומן התנגשויות במוצר.',
    CANT_DEL_PROD_ACT_USAGES:
        'אינך יכול למחוק מוצר זה כאשר שימושים פעילים זמינים',
    ERR_DEL_PROD_FROM_SNAP: 'אירעה שגיאה בעת מחיקת מוצר מ-Snapshot.',
    ERR_DEL_PROD: 'אירעה שגיאה בעת מחיקת המוצר.',
    CANT_DEL_PROD: 'אתה לא יכול למחוק את המוצר הזה',
    NEW_PROD_META_ID_NOT_FOUND: 'לא ניתן למצוא מטה מזהה מוצר חדש',
    STRAT_LEVEL_NOT_FOUND: 'לא מצליח למצוא רמת אסטרטגיה',
    CATS_NOT_FOUND: 'לא ניתן למצוא קטגוריות',
    DEPTS_NOT_FOUND: 'לא מוצא את המחלקות',
    SUBCATS_NOT_FOUND: 'לא ניתן למצוא קטגוריות משנה',
    BRANDS_NOT_FOUND: 'לא מצליח למצוא מותגים',
    ERR_ARCH_PROD: 'אירעה שגיאה במהלך ארכיון המוצר.',
    ERR_GS1_INV_BARCODE:
        'לא יכול לקרוא מידע GS1 בגלל הברקוד הלא חוקי! (ברקוד: [$])',
    CANT_OVERRIDE_BRAND_GS1_VMP_REL:
        'לא ניתן לעקוף מותג קיים עם מותג GS1 בגלל יחסי VMP',
    CANT_OVERRIDE_BRAND_GS1_EMPTY: 'לא ניתן לעקוף את המותג בגלל שהמותג GS1 ריק',
    CUR_VER_NOT_AVAIL: 'הגרסה הנוכחית לא זמינה',
    UPD_STORE_NOT_AVAIL: 'עדכון חנות לא זמין',
    CUR_MP_NOT_FOUND: 'MP נוכחי לא נמצא',
    UPD_MP_NOT_FOUND: 'עדכון MP לא נמצא',
    PROD_ALREADY_EXISTS: 'המוצר כבר קיים',
    ERR_SAVE_NEW_NOOS_PROD: 'אירעה שגיאה בעת שמירת מוצר Noos חדש',
    ERR_SAVE_NEW_STORE_PROD: 'אירעה שגיאה בעת שמירת מוצר חדש בחנות',
    VAL_STORE_PROD_NOT_FOUND: 'לא ניתן למצוא מוצר תקף בחנות',
    ERR_EDIT_SEL_STORE_PROD: 'אירעה שגיאה בעת עריכת מוצר החנות שנבחר',
    INV_STORE_DET_CHK_AGAIN: 'פרטי חנות לא חוקיים. אנא בדוק שוב.',
    SYS_USER_NOT_AVAIL: 'משתמש המערכת אינו זמין',
    INV_MON: 'חודש לא חוקי',
    INV_YEAR: 'שנה לא חוקית',
    INV_KEY: 'מפתח לא חוקי',
    IV_NOT_FOUND: 'IV לא נמצא',
    STORE_ID_NOT_FOUND: 'לא ניתן למצוא את מזהה החנות',
    ALREADY_ATTENDED_COMPL: 'מישהו כבר השתתף בציות הזה!',
    INV_FIELD_ID: 'מזהה שדה לא חוקי!',
    BRAND_NOT_FOUND: 'לא מצליח למצוא מותג',
    ERR_UPD_DEPT_IMPORT_CHANGES:
        'אירעה שגיאה בעת עדכון מחלקת עם שינויים בייבוא.',
    SUPPLIER_NOT_FOUND: 'לא מצליח למצוא ספק',
    SIM_SNAP_REMOVE_FAIL: 'הסרת תמונת מצב של סימולציה נכשלה',
    SUBCAT_NOT_FOUND: 'לא ניתן למצוא קטגוריית משנה',
    IMG_UPLD_FAIL: 'העלאת התמונה נכשלה!',
    INV_ID: 'מזהה לא חוקי',
    INV_AUI_SUB_SET: 'הגדרות מנוי לא חוקיות של AUI!',
    AUI_ALRDY_CONV: 'AUI כבר הומר!',
    AUI_CONV_LMT_REACHED: 'הגעת למגבלת המרת AUI!',
    NO_PAR_LVL_AVAIL_TKBK: 'אין רמת הורה זמינה להחזרה!',
    NO_CONN_DISC_JOB_REC_UPD: 'אין חיבור נתק את רשומת העבודה לעדכון',
    FLD_NAME_ALRDY_IN_USE: 'שם השדה כבר בשימוש!',
    MISS_FLD_NAME: 'חסר שם שדה!',
    FAIL_TO_SAVE_FLD: 'שמירת השדה נכשלה',
    CANNOT_FIND_FLD: 'לא מצליח למצוא שדה',
    FLR_NAME_ALRDY_IN_USE: 'שם הקומה כבר בשימוש!',
    CANNOT_FIND_FLR_NAME: 'לא ניתן למצוא את שם הקומה',
    CANNOT_FIND_FLR: 'לא מצליח למצוא את הרצפה',
    CANNOT_FIND_IMG_NAME: 'לא ניתן למצוא את שם התמונה',
    PLS_ATTACH_AI_IMG: 'אנא צרף תמונות AI',
    MANDATORY_REMARK: 'הערה היא חובה!',
    DEPT_CANNOT_BE_NULL: 'המחלקה לא יכולה להיות בטל!',
    CAT_CANNOT_BE_NULL: 'קטגוריה לא יכולה להיות ריק!',
    DEPT_NAME_EMPTY_ADD_VALID: 'שם המחלקה ריק. נא הוסף שם חוקי.',
    DEPT_NAME_CANNOT_BE_NULL: 'שם המחלקה לא יכול להיות ריק!',
    INV_CHAIN_ID: 'מזהה שרשרת לא חוקי!',
    TAG_NAME_ALRDY_IN_USE: 'שם תג כבר בשימוש!',
    CANNOT_FIND_TAG_NAME: 'לא מצליח למצוא את שם התג',
    NO_DATA_AVAILABLE: 'אין נתונים זמינים!',
    SAVE_SIM_SNAPSHOT_BEFORE_IMPL: 'שמור תמונת מצב של סימולציה לפני היישום!',
    FLR_NOT_AVAIL_FOR_STORE: 'קומה לא זמינה לחנות!',
    STORE_MGR_NOT_AVAIL_FOR_STORE: 'מנהל החנות לא זמין לחנות!',
    STORE_MGR_FLR_NOT_AVAIL_FOR_STORE: 'מנהל חנות וקומה לא זמינים לחנות!',
    ACTIVE_PLG_CONTAIN_MULT_AISLES_CANNOT_PUSH:
        'פלנוגרמה פעילה מכילה עם מספר מעברים, לא יכולה לדחוף!',
    SIM_CHANGE_DATA_INVALID: 'שינויים בסימולציה נתונים לא חוקיים!',
    EMAIL_IMG_INFO_NOT_FOUND: `מידע דוא"ל או תמונה לא נמצא!`,
    MANY_DRAFT_VER_ACTIVE_LAYOUT_ONE_DRAFT_CONFIRM_VER:
        'יש הרבה גרסאות טיוטה. כאשר יש גרסת פריסה פעילה, רק יכולה להיות גרסת שרטוט אחת או לאשר גרסאות.',
    FLR_INFO_NOT_FOUND: 'מידע על הקומה לא נמצא!',
    INV_FLOOR_ID: 'מזהה קומה לא חוקי!',
    FIELD_INFO_NOT_FOUND: 'מידע שדה לא נמצא!',
    LAYOUT_STATUS_ACTIVE_INACTIVE_CANNOT_MODIFY:
        'סטטוס פריסה זה פעיל או לא פעיל. לכן לא ניתן לשנות',
    INV_FLOOR_LAYOUT_ID: 'מזהה פריסת קומה לא חוקי!',
    ALRDY_UPDATE_ONGOING: 'העדכון כבר ממשיך!',
    NO_STORE_INFO_FOUND: 'לא נמצא מידע על החנות!',
    ADD_FLOOR_TO_TARGET_STORE: 'נא להוסיף קומה לחנות היעד!',
    INV_FLOOR_AISLE_HAS_FIELD_ID: 'למעבר רצפה לא חוקי יש מזהה שדה!',
    DUPL_FIELD_NO_FOUND_IN_DEPT: 'שדה כפול לא נמצא במחלקה [$].',
    INV_PROD_INFO: 'מידע מוצר לא חוקי',
    DTO_MAPPING_FAILED: 'מיפוי Dto נכשל!',
    NO_CONFIG_FOUND_FOR_MEG_QUEUE: 'לא נמצאה תצורה עבור תור הודעות!',
    REMOVE_FIELD_DUP: 'הסתיימה הסרה של כפילויות שדות',
    NO_MEDIA_COLLECTION_FOUND: 'לא נמצא אוסף מדיה!',
    ERR_SAVE_DEPT_DATA: 'אירעה שגיאה במהלך שמירת נתוני המחלקה',
    CANNOT_FIND_TAG: 'לא מצליח למצוא את התג',
    INV_PLG_FIELD_ID: 'מזהה שדה פלנוגרמה לא חוקי',
    NO_ITEM_FOUND: 'לא נמצא פריט!',
    GS1_STATUS_CODE: 'קוד סטטוס GS1 [$]',
    GS1_EXCEPTION: 'חריג GS1!',
    CANNOT_FIND_PROD_INFO: 'לא ניתן למצוא מידע על המוצר',

    //translation codes from USER_MS
    SALE_IMPORT_BRANCH_CODE_EXISTS: 'קוד סניף ייבוא ​​מכירה כבר קיים!',
    REGION_NAME_OR_CODE_EXISTS: 'שם אזור או קוד כבר קיימים!',
    REGION_HAS_BRANCHES_CANNOT_DEL: 'באזור זה יש סניפים, לא ניתן למחוק!',
    GRP_USER_NOT_FOUND: 'משתמש הקבוצה לא נמצא!',
    USER_HAS_ROLE_CANNOT_DEL: 'למשתמש יש תפקיד, אין אפשרות למחוק',
    USER_NAME_ALREADY_EXISTS: 'שם המשתמש כבר קיים!',
    INV_USER_INFO: 'מידע משתמש לא חוקי!',
    INV_CHAIN_INFO: 'מידע שרשרת לא חוקי!',
    INV_REGION_INFO: 'מידע אזור לא חוקי!',
    INV_BRANCH_INFO: 'מידע סניף לא חוקי',
    DEPT_NAME_IN_USE: 'שם המחלקה כבר בשימוש!',
    GRP_NAME_ALREADY_EXISTS: 'שם הקבוצה כבר יש.',
    GRP_NOT_FOUND: 'הקבוצה לא נמצאה!',
    INV_REGION_LIST: 'רשימת האזורים אינה חוקית!',
    INV_STORE_LIST: 'רשימת החנויות אינה חוקית!',
    STORE_CHAIN_INFO_NOT_FOUND: 'מידע על חנות או רשת לא נמצא!',
    USER_NOT_FOUND: 'המשתמש לא נמצא!',
    DEVICE_TOKEN_NOT_FOUND: 'אסימון מכשיר לא נמצא!',
    NO_LOWER_RANK_USERS: 'אין משתמשים בדרג נמוך יותר!',
    PWD_NOT_MATCH_CONFIRM_PWD: 'סיסמה חדשה ואשר שהסיסמה לא תואמת!',
    USER_INFO_NOT_FOUND: 'מידע משתמש לא נמצא!',
    OLD_PWD_NOT_MATCH: 'הסיסמה הישנה לא תואמת!',
    UNAME_OR_PWD_INCORRECT: 'שם משתמש או סיסמא לא נכונים!',
    STORE_CODE_EXISTS: 'קוד החנות כבר קיים!',
    STORE_NAME_EXISTS: 'שם החנות כבר קיים!',

    //translation codes from NOTIFY_MS
    INV_MSG_SRC_INFO: 'מידע מקור הודעה לא חוקי!',
    DATA_NOT_FOUND: 'מידע לא נמצא',

    //translation codes from TASK_MS
    CANT_TRANSF_TO_DTO_WITH_ERR_MSG: 'לא ניתן להעביר ל-DTO - [$]',
    TASK_ALLOC_NOT_FOUND: 'הקצאת משימות לא נמצאה',
    TASK_ATTEND_DATA_NOT_FOUND: 'לא נמצאו נתוני נוכחות במשימה',
    TASK_ALLOC_DETAIL_ID_NOT_FOUND: 'זיהוי פרטי הקצאת משימה לא נמצא',
    ERR_OCCURED: 'אירעה שגיאה',
    ALLOC_DETAIL_ID_NOT_FOUND: 'זיהוי פרטי ההקצאה לא נמצא',
    DATA_CANNOT_TRANSF_TO_DTO: 'לא ניתן להעביר נתונים ל-DTO',
    FL_UUID_NOT_FOUND: 'פריסת קומה Uuid לא נמצא',
    NOT_VALID_SEARCH_TYPE: 'לא סוג חיפוש חוקי',
    NO_USERS_SELECTED_REGIONS: 'אין משתמשים עבור אזורים נבחרים!',
    NO_USERS_SELECTED_STORE: 'אין משתמשים לחנות נבחרת!',
    NO_USERS_SELECTED_CHAIN_LEVEL: 'אין משתמשים ברמת שרשרת נבחרת!',
    NO_TASK_SRC_INFO: 'אין מידע על מקור המשימות!',
    VAL_QNR_NOT_FOUND: 'לא ניתן למצוא שאלון תקף.',
    TASK_ALLOC_DETAILS_NOT_FOUND: 'לא ניתן למצוא פרטי הקצאת משימות.',
    TASK_NOT_FOUND: 'משימה לא נמצאה',
    INV_QNR_STATUS_CHK_AGAIN: 'סטטוס שאלה לא חוקי. אנא בדוק שוב.',
    INV_TASK_ATTEND_DETAILS: 'פרטי נוכחות במשימה לא חוקיים.',
    INV_ID_TASK_ATTEND_DETAILS: 'מזהה לא חוקי לפרטי נוכחות במשימה.',
    INV_TASK_STATUS_ADD_UPD:
        'סטטוס משימה לא חוקי בעת הוספה או עדכון של המשימה.',
    INV_TASK_ALLOC_DETAILS: 'פרטי הקצאת משימה לא חוקיים.',
    INV_TASK_STATUS: 'סטטוס משימה לא חוקי',
    TASK_ALLOC_DETAILS_NOT_FOUND_QNR: 'לא ניתן למצוא שאלון פרטי הקצאת משימות.',
    PENDING_SUB_TASKS_EXIST: 'יש משימה משנה ממתינה',
    NO_TASK_TO_APPROVE: 'לא נמצאה משימה לאישור!',
    TASK_REPEAT_DETAILS_NOT_FOUND: 'פרטי חזרה על משימה לא נמצאו!',
    INVALID_TASK_REPEAT_DATES:
        'תאריך התחלה או סיום חוזר על המשימה לא מסופק כהלכה!',
    NO_TASK_TITLE_PROVIDED: 'לא סופקה כותרת משימה!',
    NO_TASK_DESC_PROVIDED: 'לא מסופק תיאור משימה!',
    TASK_APPROVERS_NOT_FOUND: 'לא נמצאו מאשרי משימות!',
    DUP_ALLOC_USERS_VERIFY:
        'משתמשי ההקצאה הם כפולים. אנא אמת את משתמשי ההקצאה!',
    TASK_GRP_DETAILS_INVALID: 'פרטי קבוצת המשימות אינם חוקיים!',
    NO_TASK_RECEIVERS_FOUND: 'לא נמצאו מקבלי משימות',
    TASK_REQ_FEEDBACK_NOT_FOUND: 'לא נמצא משוב בקשת משימה!',
    INV_QNR_DETAILS: 'פרטי השאלון שסופקו אינם חוקיים!',
    VAL_QNR_DETAILS_NOT_FOUND: 'לא ניתן למצוא פרטי שאלון תקפים!',
    QNR_NEED_PUBLISHED: 'יש לפרסם שאלון לפני השימוש כדי ליצור משימות!',
    FUTURE_TASK_ALREADY_ATTENDED: 'איזו משימה עתידית כבר השתתפה',
    INV_TASK_DATES: 'תאריכי משימה לא חוקיים!',
    DATES_NOT_PROVIDED_CORRECTLY: 'תאריכים לא מסופקים בצורה נכונה!',
    INV_TASK_INFO: 'מידע משימה לא חוקי!',
    NO_TASK_INFO_FOUND: 'לא נמצא מידע על משימות!',
    INVALID_IMG_COUNT: 'ספירת תמונות לא חוקית',
    TASK_ALREADY_ALLOCATED: 'המשימה הזו כבר הוקצתה!',
    CANT_SAVE_COMMENT_NO_PERMISSION:
        'לא ניתן לשמור תגובה, כי אינך מנהל אזור או מקלט',
    NO_REGION_MGR_FOR_TASK: 'לא נמצא מנהל אזור למשימה זו',
    CANT_FIND_TASK_ALLOC_DETAIL: 'לא מוצא שום פרט הקצאת משימה!',
    NO_TASK_ALLOC_DETAIL_INFO: 'אין מידע על הקצאת משימה!',
    TASK_CAT_NAME_EMPTY: 'שם קטגוריית המשימה ריק!',
    TASK_CREATED_APPROVED: 'המשימה שיצרת אושרה.',
    INVALID_ACTION_QNR: 'שאלת פעולה לא חוקית!',
    ERR_OCCURED_CHG_QNR_STATUS: 'אירעה שגיאה בעת שינוי סטטוס של שאלון ספציפי',
    ERR_OCCURED_CREATING_NEW_QN: 'אירעה שגיאה בעת יצירת שאלה חדשה',
    VAL_QNR_NOT_FOUND_EDITED_QN:
        'לא ניתן למצוא שאלון תקף עבור שאלה ערוכה זו. אנא בדוק ונסה שוב',
    VAL_QNR_NOT_FOUND_EDIT_Q: 'לא ניתן למצוא שאלה חוקית. אנא בדוק ונסה שוב',
    ERR_OCCURED_CREATING_NEW_QNR: 'אירעה שגיאה בעת יצירת שאלון חדש',
    ERR_OCCURED_UPDATING_QN: 'אירעה שגיאה בעת עדכון השאלה',
    ERR_OCCURED_SAVE_QN_FEEDBACK: 'אירעה שגיאה בעת שמירת סוגי משוב על שאלות',
    INV_QN_FEEDBACK_TYPE_EDIT_Q: 'סוג משוב לא חוקי על שאלה בעת עריכת שאלה',
    CANT_EDIT_PUBLISHED_QNR:
        'לא ניתן לערוך את השאלון שפורסם כשהוא מוקצה למשימות',
    ERR_OCCURED_UPDATING_SPECIFIC_QNR: 'אירעה שגיאה בעת עדכון שאלון ספציפי',
    ERR_OCCURED_CHG_QNR_STATUS_SPECIFIC:
        'אירעה שגיאה בעת שינוי סטטוס של שאלון ספציפי',
    CANT_DELETE_QNR_WITH_REFERENCES:
        'אינך יכול למחוק את השאלון הזה מכיוון שקיימים הפניות עבור משימות',
    ERR_OCCURED_DELETING_SPECIFIC_QNR: 'אירעה שגיאה בעת מחיקת שאלון ספציפי',
    CANT_CHANGE_QNR_STATUS_PUBLISHED:
        'אינך יכול לשנות את סטטוס השאלון לפרסום ללא שאלות חוקיות.',
    NAV_NOT_AVAILABLE_BRANCH_QN: 'ניווטים לא זמינים עבור שאלות סניף.',
    ACT_OR_NAV_TYPES_NOT_AVAILABLE_BRANCH_QN:
        'סוגי פעולות או ניווטים אינם זמינים עבור שאלות סניף.',
    NAV_NOT_AVAILABLE_SOME_QN: 'ניווטים לא זמינים עבור חלק מהשאלות.',
    ACTION_QN_NOT_FOUND: 'שאלת פעולה לא נמצאה!',
    INVALID_ACTION_TYPE: 'סוג פעולה לא חוקי!',
    ERR_OCCURED_PUBLISHING_SPECIFIC_QNR: 'אירעה שגיאה בעת פרסום שאלון ספציפי',
    CANT_UNPUBLISH_QNR_WITH_REFERENCES:
        'אינך יכול לבטל את פרסום השאלון הזה מכיוון שקיימים הפניות עבור משימות',
    ERR_OCCURED_ADDING_TASK_ATTEND_LOG:
        'אירעה שגיאה בעת הוספת יומן נוכחות במשימה',
    INV_TASK_COMP_DATA_AVAIL:
        'זמינים נתוני השלמת משימה לא חוקיים. אנא בדוק ונסה שוב.',
    INV_TASK_COMP_OPT_DATA_AVAIL:
        'נתונים לא חוקיים של השלמת משימה זמינים. אנא בדוק ונסה שוב.',
    ERR_OCCURED_DELETING_MEDIA_FILES: 'אירעה שגיאה בעת מחיקת קבצי מדיה.',
    ERR_OCCURED_CHG_STATUS_TASK_ALLOC_DETAILS:
        'אירעה שגיאה בעת שינוי הסטטוס של פרטי הקצאת המשימות',
    TASK_ATTEND_LOG_NOT_FOUND: 'לא ניתן למצוא יומן נוכחות במשימה.',
    ERR_OCCURED_EDITING_TASK_COMP_DETAILS:
        'אירעה שגיאה בעת עריכת פרטי השלמת משימה',
    ERR_OCCURED_SAVING: 'אירעה שגיאה במהלך השמירה',
    NO_FEEDBACK_TYPE_FOUND: 'לא נמצא סוג משוב',
    NOT_CREATED_ANY_TASK_VERIFY_DATES:
        'לא יצר שום משימה! נא לאמת תאריכים שבחרת.',
    NO_USERS_IN_LIST: 'אין משתמש ברשימה',
    STORE_MGR_NOT_FOUND: 'מנהל החנות לא נמצא',
    ALLOCATOR_DOES_NOT_EXIST: 'מקצה לא קיים',
    SOME_RECEIVERS_NOW_ALLOCATORS: 'כמה מקבלי משימות הם כעת מקצי משימות!',
    TASK_ALREADY_ATTENDED: 'המשימה כבר השתתפה!',
    REQ_FEEDBACK_OPTION_NOT_FOUND: 'אפשרות המשוב המבוקשת לא נמצאה',
    REQ_FEEDBACK_NOT_FOUND: 'המשוב המבוקש לא נמצא',
    INV_TASK_ALLOC_DETAIL_INFO_TASK_APPROVED:
        'פרטי הקצאת משימה לא חוקיים או משימה כבר אושרה משימה!',
    DTO_MAP_FAILED: 'מפת Dto נכשלה [$]',
    NO_CONFIG_MSG_QUEUE: 'לא נמצאה תצורה עבור תור הודעות!',
    SALE_CYCLE_LOGIC: 'לוגיקה של מחזור מכירה',
    THREEDQTY: 'כמות תלת מימד',
    SALEQTY: 'כמות מכירה',
    DAYCOUNT: 'ספירת ימים',
    CAL_CHAIN: 'מחושב באמצעות נתוני שרשרת',
    EXCECUTED_SUCCESSFULLY: 'בוצע בהצלחה!',
    New_password_details_successfully_saved: 'פרטי הסיסמה החדשה נשמרו בהצלחה',
    COULD_NOT_THE_LOAD_IMAGE: 'לא ניתן היה לטעון את התמונה',
    SUCCESSFULLY_BRANCH_DATA: 'נתוני הסתעפות בהצלחה',
    ERROR_OCCORED_ON: 'אירעה שגיאה ב- ',
    _process: 'תהליך',
    successfully_branch_details_deleted: 'פרטי הסניף נמחקו בהצלחה',
    ERROR_OCCOURED_IN_DELETE_PROCESS: 'אירעה שגיאה בתהליך המחיקה',
    FROM_DATE_SHOULD_BE_PRIOR_TO_END_DATE:
        'תאריך התאריך צריך להיות לפני תאריך הסיום',
    REFERENCE_NOT_AVAILABLE: 'הפניה אינה זמינה',
    SUCCESSFULLY_APPROVED: 'אושר בהצלחה!',
    SUCCESSFULLY_SYNCED: 'מסונכרן בהצלחה!',
    ENTER_VALID_DATES: 'הזן תאריכי חיפוש חוקיים',
    SUCCESSFULLY_RESOLVED: '!סומן בהצלחה כפתורה',
    SUCCESSFULLY_UNRESOLVED: '!לא פתור בהצלחה כפתורה',
    Confirm_to_delete_a_region: 'אשר כדי למחוק אזור',
    ARE_YOU_SURE_TO_DELETE_THIS_REGION: 'האם אתה בטוח שתמחק את האזור הזה?',

    CONFIRM_TO_CHANGE_REFIONAL_MANAGER: 'אשר כדי להחליף מנהל אזור',
    CHANGE_REGIONAL_MANAGER_MESSAGE:
        'ניתן להשתמש במשתמש זה באזורים אחרים. האם אתה בטוח שתשנה את המשתמש הזה כמנהל אזור?',
    SUCCESSFULLY_REGIONAL_DATA_SAVED: 'נתוני האזור נשמרו בהצלחה',
    SUCCESSFULLY_REGIONAL_DATA_UPDATED: 'נתוני האזור עודכנו בהצלחה',
    SUCCESSFULLY_REGIONAL_DATA_DELETED: 'נתוני האזור נמחקו בהצלחה',

    country_required: 'נדרשת מדינה',
    code_required: 'קוד נדרש',
    entervalidcode_length: 'הקוד לא יכול להיות פחות מ-3 אותיות',
    entervalidname_length: 'השם הוא לא יכול להיות פחות מ-3 אותיות',

    DELETE_USER_DETAILS: 'פרטי המשתמש נמחקו בהצלחה',
    SAVE_USER_DETAILS: 'פרטי המשתמש נשמרו בהצלחה',
    UPDATE_USER_DETAILS: 'פרטי המשתמש עודכנו בהצלחה',

    DELETE_USERGROUP_DETAILS: 'קבוצת המשתמשים נמחקה בהצלחה',
    SAVE_USERGROUP_DETAILS: 'קבוצת המשתמשים נשמרה בהצלחה',
    UPDATE_USERGROUP_DETAILS: 'קבוצת המשתמשים עודכנה בהצלחה',

    SENSITIVITY_STATUS: { empty: 'ריק', half: 'חֲצִי', full: 'מלא' },
    SELECT_ACTUAL_STORE_STATE: 'בחר מצב חנות בפועל',
    ADD_SENSITIVE_VALUE: 'הוסף ערך רגיש',
    INVALID_SENSITIVE_VALUE: 'ערך רגיש לא חוקי',

    SAVED_DEPARTMENT_SUCCESSFULLY: 'המחלקה נשמרה בהצלחה',
    UPDATED_DEPARTMENT_SUCCESSFULLY: 'המחלקה עודכנה בהצלחה',
    CONFIRM_TO_DELETE: 'אשר כדי למחוק',
    CONFIRM_TO_DELETE_MSG: 'האם אתה בטוח להמשיך למחוק פריט זה?',
    CONFIRM_TO_DELETE_DUNIT: 'אשר למחיקת יחידת תצוגה',
    CONFIRM_TO_DELETE_DUNIT_MSG: 'האם אתה בטוח להסיר את יחידת התצוגה הזו?',
    DELETED: 'נמחק',
    CONFIRM_TO_DELETE_FLOOR: 'אשר כדי למחוק קומה',
    CONFIRM_TO_DELETE_FLOOR_MSG: 'האם אתה בטוח שתסיר את הרצפה הזו?',

    Converted_simulations_group_was_not_found_in_the_AUI:
        'הקבוצה של סימולציה שהומרה לא נמצאה ב-AUI.',
    INACTIVE: 'לֹא פָּעִיל',
    MERGE: 'לְמַזֵג',
    Print_QR: 'הדפס QR',
    Fit_To_Width: 'התאמה לרוחב',
    ZoomPan: 'זום-פאן',
    mark_stackable_onoff: 'ניתן לערום OFF/ON',

    aristo: 'Aristo',
    RESULTSCOUNT_LIMIT: 'ספירת התוצאות מוגבלת ל-3 מספרים',
    TYPE_FIRSTACTION_HERE: 'הקלד כאן את פריט הפעולה הראשון',
    TASK_IS_UPDATED: 'המשימה עודכנה!',
    TASK_APPROVED: 'המשימה אושרה!',

    CONFIRMED: 'אושר',
    USER_NAME_IS_REQUIRED: 'נדרש שם קבוצת משתמשים',
    ADD_ATLEASE_ONEUSER_REQUIRED: 'נדרש להוסיף משתמש אחד לפחות',
    CONFIRM_TO_DELETE: 'אשר כדי למחוק',
    AREYOUSURE_YOU_WANT_TO_DELETE: 'אתה בטוח שאתה רוצה למחוק?',
    THIS_WILL_DELETE_THE_VERSION: 'זה ימחק את הגרסה',
    DELETED_VERSION_SUCCESSFULLY: 'הגרסה נמחקה בהצלחה',
    MASTER_PLANOGRAM_DUPLICATED: 'Masterplanogram מוּעֲתָק',
    UPDATE_DEP_CHANGES_FIRST: 'עדכן תחילה שינויים במחלקה',
    Enter_a_Version_Name: 'הזן שם גרסה',
    CONFIRM_TO_RESTORE: 'אשר לשחזור',
    Successfully_disconnected: 'התנתק בהצלחה',
    CANCEL_PRODSTATE: 'בטל מוצר',
    PROFILE_INFO: 'מידע על הפרופיל',
    CHANGE_PROFILESETTING: 'שנה את הגדרות הפרופיל',
    PROFILE: 'פּרוֹפִיל',
    ENTER_TAG_NAME: 'הזן שם תג',
    CANNOT_EXPAND_PROD_ANOTHER_EXIST:
        'לא ניתן להרחיב את המוצר, מוצר אחר כבר קיים',
    CANNOT_CHANGEPOSITION_OF_MORETHANONE_SHELF:
        'לא ניתן לשנות מיקום של יותר ממדף אחד',
    NETWORK_ERROR: 'בעיה ברשת',
    GS1_CREDENTIAL_NOT_FOUND: 'אישורי GS1 לא נמצאו!',
    RESET_AND_CONTINUE: 'אפס והמשך',
    SELECTED_STORE_NEED_REST_BEFORE_SELECT_CURRENT_SIM:
        'יש לאפס את החנות שנבחרה לפני בחירת הדמיית הצפייה הנוכחית.',
    CANNOT_PROCEED_SELECTION_WITHA_RELOGRAM:
        'לא ניתן להמשיך בבחירה עם רילוגרמה!',
    USER_ROLL_INFORMATION_NOT_FOUND: 'מידע גליל משתמש לא נמצא!',
    IS_NOTIFICATION_ENABLE: 'האם התראה מופעלת',
    planograms_v2: 'גרסה 2 של פלנוגרמות',
    VERSION_NAME: 'שם גרסה',
    FIELDS: 'שדות',
    CHOSEN: 'נִבחָר',
    MAKE_REPORT: 'הכנת דו"ח',
    EXPORT_RESULT: 'ייצוא תוצאות',
    PDF: 'PDF',
    EXCELL: 'Excel',
    SHARE_VIA_EMAIL: 'שתף במייל',
    IMPLEMENTATION_REPORT: 'דו"ח יישום',
    OTHER_REPORTS: 'דו"חות אחרים',
    NON_USED_PRODUCTS: 'מוצרים שאינם בשימוש',
    PRODUCT_LIST_FOR_ORDER: 'רשימת מוצרים להזמנה',
    SEND_VIA_EMAIL: 'שלח במייל',
    SEND_TO_STORES: 'שלח לחנויות',
    ADD_ME_TO_CC: 'הוסף אותי ל-CC',
    Inactive: 'לֹא פָּעִיל',
    FIELD_LIST: 'רשימת שדות',
    SEARCH_FIELD: 'שדה חיפוש',
    IF_DELETE_REFERENCE_TASK_OR_THIS_ONLY:
        'אם מחק משימות עתידיות או משימה זו בלבד',
    REPEATED_TASK_ALERT: 'התראת משימות חוזרת',
    DO_YOU_WANT_TO_UPDATE_REFERENCE_TASK_OR_THIS_ONLY:
        'האם ברצונך לעדכן את כל המשימות או את המשימה הזו בלבד',
    IS_NOTIFICATION_ENABLE: 'האם התראה מופעלת',
    STATUS_OF_THE_SINGLE_TASK: 'סטטוס משימת הזמר',
    STATUS_DATE: 'תאריך סטטוס',
    USER: 'משתמש',
    BRANCH: 'סניף',
    Only_planograms: 'עדכון פלנוגרמה',
    No_planograms: 'משימות שהוקמו',
    selectuser: 'בחר משתמש',
    task_type: 'סוג משימה',
    connected_store_list:'רשימת חנויות מחוברות',
    //advance filter
    ADVANCED_FILTER: 'סינון מתקדם',
    CLEAR: 'נקה',
    FIELDS_WIDTH: 'רוחב פלנוגרמה',
    FIELDS_COUNT: 'מספר שדות',
    CONNECTED_STORES: 'חנויות מחוברות',
    CONTAINED_BLOCKED_ORDER_PRODUCTS: 'מכיל מוצרים חסומים לרכש',
    CONTAINED_BLOCKED_SALES_PRODUCTS: 'מכיל מוצרים חסומים למכר',
    CONTAINED_ARCHIVED_PRODUCTS: 'מכיל מוצרי ארכיון',
    START_TYPING: 'התחל להקליד',
    PLANOGRAM_THAT_CONTAIN: 'פלנוגרם שמכיל',
    NUM: 'מספר',
    RESULTS_V2:'תוצאות',
    DATA_LOADING:'טוען נתונים...',

    SET_CONNECTED_STORES_TO: 'אנא הגדר יעד עבור חנויות מחוברות',
    SET_CONNECTED_STORES_FROM: 'אנא הגדר מקור עבור חנויות מחוברות',
    SET_FIELDS_COUNT_TO: 'אנא הגדר יעד עבור מספר שדות',
    SET_FIELDS_COUNT_FROM: 'אנא הגדר מקור עבור מספר שדות',
    SET_FIELDS_WIDTH_TO: 'אנא הגדר יעד עבור רוחב שדות',
    SET_FIELDS_WIDTH_FROM: 'אנא הגדר מקור עבור רוחב שדות',
    PDF_SELECTED:'PDF נבחר, ייצוא Excel לא יפעל.',
    SELECT_BOTH_PDF_NON_USED_PRODUCT:"אנא בחר גם באפשרויות של Excel וגם באפשרויות לא בשימוש.",
    SELECT_REPORT_TYPE:'אנא בחר את סוג הדוח',
    dataPanel:"לוח נתונים",
    DATA_PANEL: {
        subcategory:"תת קטגוריה",
        brand:"מותג",
        allProduct:'כל המוצרים',
        product :"מוצר",
        supplier:"ספק נוכחי",
        subCat:"שם תת קטגוריה",
        tolerance:"סוֹבלָנוּת",
        rec: "מכר",
        allProducts:"כל המוצרים",
        simulatedOnly:"מדומה בלבד",
        notSimulated:"לא מדומה"
    },
    NON_USED_PRODUCTS:'מוצרים שאינם בשימוש',
    EXPORT_RESULT_IN_EXCEL:'תוצאה_יצוא_ב_אקסל',
    EXPORT_RESULT_IN_PDF:'ייצוא_תוצאה_ב-pdf',
    TOO_MANY_KEY_PRESS_ERROR:"יותר מדי לחיצות מקשים. אנא המתן.",
    LAST_MODIFIED:'שונה לאחרונה',
    DEPARTEMENT_ALREADY_EXIST_MSG: 'המחלקה כבר קיימת!',

    Attributes: 'מאפיינים',
    REGION_FIRST: 'אנא בחר אזור תחילה.',
    BRANCH_FIRST: 'אנא בחר סניף תחילה.',
    DEPARTMENT_FIRST: 'אנא בחר מחלקה תחילה.',
    SELECT_BRANCH:'בחר סניף',
    SELECT_DEPT:'בחר מחלקה',
    SELECT_VER:'בחר גרסה',
    Inactive: 'לא פעיל',
    Pending: 'ממתין',
    Active: 'פעיל',

    INVALID_EMAIL_FORMAT: 'פורמט אימייל לא חוקי',
    EMAIL_IS_REQUIRED: 'נדרש אימייל',
    FIELDS_NUMBER: 'מספר שדות',
    FILE_DOWNLOADED_SUCCESS_MESSAGE:'הקובץ הורד בהצלחה!',
    DEPARTMENT_IMPLEMENT: 'יישום המחלקה',

    CHATBOX_MANIPULATION: 'מָנִיפּוּלָצִיָה',
    CHATBOX_MESSAGE_TO_MANIPULATION: 'הודעה למניפולציה. . .',
    CHATBOX_AI_RESPONSE_RECEIVED: 'התקבלה תגובת AI',
    CHATBOX_SOMETHING_WENT_WRONG: 'משהו השתבש. אנא נסה שוב.',
    CHATBOX_WELCOME_MESSAGE: 'היי! אני מוכן לעזור עם מניפולציה של הפלנוגרמה. אנא ספק את ההוראה שלך.',
    SELL_PRICE: 'מחיר מכירה',
    POS_CODE: 'קוד קופה',

    Active_Pending: ' פעיל ממתין',
    ITEM_SELECTED: 'פריט שנבחר',
    ITEMS_SELECTED: 'פריטים שנבחרו',

    ERROR_CONNECTED_STORES: 'שגיאה ערך חנויות מחוברות מִן לא יכול להיות גדול מערך אֶל !',
    ERROR_FIELD_COUNT: 'שגיאה ספירת שדה מהערך לא יכולה להיות גדולה יותר מאשר לערך !',
    ERROR_FIELD_WIDTH: 'שגיאה רוחב שדה מהערך לא יכול להיות גדול יותר מאשר לערך !',
    UNRESOLVECONFIRM: "האם אתה בטוח שבטלת פתרון של פריטים נבחרים ?",
    ALL_COUNT_FILEE_RROR: "מספר כל השגיאות בעת הייבוא.",
    FILES_CORRUPTED_FILE_ERROR: "מספר הקבצים שנפגמו בעת הייבוא.",
    DATA_ERRORS_FILE_ERROR: "מספר קבצי שגיאת נתונים בעת הייבוא.",
    SAVE_ERRORS_FILE_ERROR: "מספר שגיאות בעת שמירה.",
    RESOLVE: 'לִפְתוֹר',
    SENT_DATE: 'תאריך שליחה ',
    DEP_CODE: 'קוד מחלקה',
    SENT_TO: 'נשלח ל:',
    LAST_SENT: 'נשלח לאחרונה',
    REGION_CODE: 'קוד אזור',
    REGION_NAME: 'אזור',
    AND:'ו',
    BY_STORE: 'לפי חנויות',
    BY_PLANOGRAM: 'לפי פלנוגרמות',
    DETAILED: 'מפורט',
    SENT_DATE_TO_VALIDATION : 'הזן תאריך מ..',
    SENT_DATE_FROM_VALIDATION : 'הזן תאריך עד'
}